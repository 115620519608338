import React from "react";
import LoginForm from "./components/LoginForm";
import Section from "../../layouts/Section";
import images from "../../data/images";
import SEO from "../../layouts/SEO";

const Authentication = () => {
  return (
    <Section id="login" isPage>
      <SEO title={"Login | Dolina Agro"} />
      <main className="flex max-md:flex-col w-full px-2 lg:px-20">
        <section className="w-full lg:w-1/3 xl:w-2/3 xl:p-20 p-8 bg-gray-100 flex overflow-hidden relative flex-col justify-end text-white">
          <img
            src={images.Jory}
            alt="auth background"
            className="object-cover absolute inset-0 w-full h-full rounded-xl"
          />
          <img
            src={images.logoWhite}
            alt="Dolina's system logo"
            className="object-contain w-[16rem] relative"
          />
          <h1 className="relative mt-8 text-4xl md:text-3xl font-black tracking-wider max-md:mt-10 max-md:max-w-full">
            Aroma of success
          </h1>
          <p className="relative mt-8 text-lg font-light tracking-wide max-md:mt-10 max-md:max-w-full">
            To enter your account, type the details in the following fields
          </p>
        </section>
        <LoginForm />
      </main>
    </Section>
  );
};

export default Authentication;

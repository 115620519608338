import React from "react";
import { Link } from "react-router-dom";

interface ContactInfoProps {
  address: string;
  mailbox: string;
  telephone: string;
  phone: string;
  email: string;
}
const ContactInfo: React.FC<ContactInfoProps> = ({
  address,
  mailbox,
  telephone,
  phone,
  email,
}) => {
  return (
    <div className="p-6 mt-16 rounded-xl gap-2 bg-neutral-100 w-full grid md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1">
      <Link
        to={`http://maps.google.com/?q=:${address}`}
        target="_blank"
        className="flex flex-col border-b border-b-gray-500  p-4"
      >
        <div className="font-bold text-gray-800">Our address</div>
        <span className="mt-2">{address}</span>
      </Link>
      <Link
        to={`tel:${telephone}`}
        className="flex flex-col border-b border-b-gray-500 p-4"
      >
        <div className="font-bold text-gray-800">Telephone</div>
        <span className="mt-2">{telephone}</span>
      </Link>
      <Link
        to={`mailto:${email}`}
        className="flex flex-col border-b border-b-gray-500 p-4"
      >
        <div className="font-bold text-gray-800">Email</div>
        <span className="mt-2">{email}</span>
      </Link>
      {/* <div className="flex flex-col w-full border-r border-r-gray-500 p-4">
        <div className="font-bold text-gray-800">Mailbox</div>
        <div className="mt-2">{mailbox}</div>
      </div> */}
      <Link to={`tel:${phone}`} className="flex flex-col w-full p-4">
        <div className="font-bold text-gray-800">Phone</div>
        <span className="mt-2">{phone}</span>
      </Link>
      <div className="flex flex-col w-full p-4">
        <span className="mt-4 font-bold">© Dolina Agro.</span>
      </div>
    </div>
  );
};

export default ContactInfo;

import React from "react";
import "./styles/tailwind.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Prices from "./pages/Prices";
import Authentication from "./features/authentication";
import ProtectedRoute from "./layouts/ProtectedRoute";
import Orders from "./features/orders";
import NewOrder from "./features/orders/NewOrder";
import Info from "./features/Profile/Info";
import store from "./store/store";
import { Provider } from "react-redux";
import "./styles/App.css";
import ViewOrder from "./features/orders/ViewOrder";
import NotFound from "./pages/NotFound";
import DuplicateOrder from "./features/orders/DuplicateOrder";
import ProductView from "./pages/ProductView";
import Policy from "./pages/Policy";
import Terms from "./pages/Terms";
import Avails from "./features/Avails";
import EditOrder from "./features/orders/EditOrder";
import PageContainer from "./layouts/PageContainer";
import Loading from "./components/ui/Loading";
import { AlertsContextProvider } from "./context/AlertContext";

const router = createBrowserRouter(
  [
    {
      path: "/",
      Component: PageContainer,
      children: [
        {
          path: "/",
          index: true,
          element: <Home />,
        },
        {
          path: "/AboutUs",
          element: <AboutUs />,
        },
        {
          path: "/Contact",
          element: <Contact />,
        },
        {
          path: "/Prices",
          element: <Prices />,
        },
        {
          path: "/Catalog",
          element: <Products />,
        },
        {
          path: "/Catalog/:Id?",
          element: <ProductView />,
        },
        {
          element: <ProtectedRoute />,
          path: "/Profile/*",
          children: [
            {
              path: "",
              element: <Info />,
            },
            {
              path: "Avails",
              element: <Avails />,
            },
            {
              path: "Orders",
              element: <Orders />,
            },
            {
              path: "Orders/:orderId?",
              element: <ViewOrder />,
            },
            {
              path: "Orders/Duplicate/:orderId?",
              element: <DuplicateOrder />,
            },
            {
              path: "Orders/Edit/:orderId?",
              element: <EditOrder />,
            },
            {
              path: "Orders/New",
              element: <NewOrder />,
            },
          ],
        },
        {
          path: "/Login",
          element: <Authentication />,
        },
        {
          path: "/Policy",
          element: <Policy />,
        },
        {
          path: "/Terms",
          element: <Terms />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ],
  { basename: "/" }
);

function App() {
  return (
    <Provider store={store}>
      <AlertsContextProvider>
        <RouterProvider router={router} fallbackElement={<Loading />} />
      </AlertsContextProvider>
    </Provider>
  );
}

export default App;

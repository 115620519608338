export const ORDER_SAVED = "ORDER_SAVED";
export const ORDER_CLEARED = "ORDER_CLEARED";

export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const SAVE_PRODUCTS = "SAVE_PRODUCTS";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";

export const NEW_ORDER_SAVED = "NEW_ORDER_SAVED";
export const NEW_ORDER_CLEARED = "NEW_ORDER_CLEARED";

export const ORDERS_CLEARED = "ORDERS_CLEARED";

export const TOGGLE_ERRORS = "TOGGLE_ERRORS";


import React from "react";
import { CgSandClock, CgSpinner } from "react-icons/cg";
import { FaCircleCheck } from "react-icons/fa6";
import { StatusEnum } from "../../../../utils/types";
import { MdCancel } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoIosArrowForward } from "react-icons/io";

const StatusSteps = ({ status }: { status?: StatusEnum }) => {
  const items = [
    {
      title: "Sent",
      indicator: StatusEnum.OrderSentByClient,
    },
    {
      title: "In Progress",
      indicator: StatusEnum.InProgress,
    },
    {
      title: "Shipped",
      indicator: StatusEnum.Shipped,
    },
    // {
    //   title: "Canceled",
    //   indicator: StatusEnum.Canceled,
    // },
  ];
  const isMobile = useMediaQuery({ maxWidth: "1024px" });

  return (
    <div className="flex max-lg:mx-0 mx-4 justify-center items-center">
      {status === StatusEnum.Canceled ? (
        <div className="w-[50%] max-lg:w-full bg-slate-50 p-4 rounded-xl flex flex-col justify-center items-center">
          <MdCancel className={"size-8 max-lg:size-4 text-red-600"} />
          <span className="mt-2">{"Order Cancelled"}</span>
        </div>
      ) : (
        <div className="max-md:w-full bg-slate-50 p-4 rounded-xl flex max-lg:flex-wrap items-center  text-sm font-medium text-center text-gray-500 sm:text-base">
          {items.map((item, index) => {
            const isLastItem = index === items.length - 1;
            return (
              <div
                key={index}
                className={`flex items-center text-primaryGreen ${
                  isLastItem ? "" : "max-md:w-full"
                }`}
              >
                <div className="flex gap-2 max-lg:gap-0 items-center justify-center">
                  {status !== undefined && item.indicator <= status ? (
                    <FaCircleCheck
                      className={"size-8 max-lg:size-4 text-primaryGreen"}
                    />
                  ) : index <= item.indicator ? (
                    <CgSandClock className="size-8 max-lg:w-3 max-lg:h-3" />
                  ) : // <CgSpinner className=" w-8 h-8 max-lg:w-4 max-lg:h-4" />
                  null}
                  <span className="ml-2">{item.title}</span>
                </div>
                {isLastItem ? null : (
                  <div
                    className={
                      "flex items-center justify-center max-lg:mx-2 mx-12"
                    }
                  >
                    {isMobile ? (
                      <IoIosArrowForward size={15} color={"#ccc"} />
                    ) : (
                      <HiArrowLongRight size={40} color={"#ccc"} />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* </div> */}
    </div>
  );
};

export default StatusSteps;

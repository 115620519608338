import React from "react";
import PageTitle from "../../../components/PageTitle";
import ContactForm from "./ContactForm";
import Icon from "../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "../../../components/Modal";

const PriceListModal = ({
  showModal,
  dismiss,
}: {
  showModal: boolean;
  dismiss: Function;
}) => {
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="lg:w-[80dvw] xl:w-[50dvw]"
    >
      <div className="flex justify-between px-4">
        <PageTitle
          title="Price List"
          subTitle="To get quote, please fill out the form:"
        />
        <Icon className="my-4" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} />
        </Icon>
      </div>
      <ContactForm callBack={() => dismiss()} />
    </Modal>
  );
};

export default PriceListModal;

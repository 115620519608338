import React from "react";
import Section from "../layouts/Section";
import SEO from "../layouts/SEO";

const Terms = () => {
  return (
    <Section id="Terms" isPage>
      <SEO title={"Terms | Dolina Agro"} />
      <h1>Terms</h1>
    </Section>
  );
};

export default Terms;

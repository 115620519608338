import React, { useEffect, useRef } from "react";
import { useAppDispatch } from "./useStore";
import AuthActions from "../modules/auth/actions";

const WithTokenRefresh = () => {
  const dispatch = useAppDispatch();
  const { handleRefreshToken } = AuthActions();
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    dispatch(handleRefreshToken());
  }, [dispatch]);

  return null;
};

export default WithTokenRefresh;

import React from "react";
import Section from "../layouts/Section";
import SEO from "../layouts/SEO";

const Policy = () => {
  return (
    <Section id="Policy" isPage>
      <SEO title={"Policy | Dolina Agro"} />
      <h1>Policy</h1>
    </Section>
  );
};

export default Policy;

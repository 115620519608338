import React, { useState } from "react";
import Input from "../../../components/ui/Inputs/Input";
import Button from "../../../components/Button";
import PageTitle from "../../../components/PageTitle";
import AuthActions from "../../../modules/auth/actions";
import AuthSelectors from "../../../modules/auth/selectors";
import { useAppDispatch } from "../../../hooks/useStore";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useAppDispatch();

  const { login } = AuthActions();
  const { isLoading } = AuthSelectors();
  const handleLogin = async (e: any) => {
    e.preventDefault();
    dispatch(login({ userName: username, password }));
  };
  return (
    <div className="w-full lg:w-2/3 xl:w-1/3 flex flex-col justify-center xl:p-6 md:px-0 ">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <PageTitle title="Sign in" />
          <h2 className="font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
            Sign in to your account
          </h2>
          <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
            <Input
              label={"Username"}
              type="text"
              placeholder={"username"}
              name={"username"}
              required={true}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              label={"Password"}
              type="password"
              placeholder={""}
              name={"password"}
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              text={"Sign in"}
              type={"submit"}
              loading={isLoading}
              className="w-full justify-center items-center !px-12 !py-3 !font-black text-white bg-primaryGreen !max-md:px-5"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

import React from "react";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import Button from "../../../../components/Button";
import { DatePicker, TimeRangePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { MdOutlineAdd } from "react-icons/md";
import { BsDownload } from "react-icons/bs";

const buttonStyle =
  "flex items-center gap-2 p-4 rounded-xl bg-neutral-50 cursor-pointer";
const OrdersFilters = ({
  hasSelected,
  handleDownloadOrders,
  searchTerm,
  handleSearch,
  loading,
  setDateRange,
}: {
  hasSelected: boolean;
  handleDownloadOrders: Function;
  searchTerm: string;
  handleSearch: Function;
  loading: boolean;
  setDateRange: Function;
}) => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setDateRange({
        from: dayjs(dateStrings[0]).format("DD/MM/YYYY"),
        to: dayjs(dateStrings[1]).format("DD/MM/YYYY"),
      });
    } else {
      setDateRange({ from: "", to: "" });
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];
  return (
    <div className="flex gap-4 justify-between text-base font-medium tracking-wide leading-6 max-md:flex-wrap my-8">
      <div className="flex w-1/3 max-md:w-full items-center gap-2 px-4 py-2 max-md:p-4 rounded-xl bg-neutral-50 text-slate-400">
        <span>
          <CiSearch />
        </span>
        <input
          type="text"
          placeholder={"Search by Mark, Destination, Order number..."}
          className={`flex-1 max-md:w-full bg-transparent outline-none ${
            searchTerm ? "!text-body" : "text-slate-400"
          }`}
          name={"searchTerm"}
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="flex gap-2 px-5 text-slate-500 max-md:flex-wrap max-md:px-0">
        <div className={`${buttonStyle} !p-0 max-md:w-full relative`}>
          {/* <CiCalendarDate />
          <div>{"April 11 - April 24"}</div> */}
          <RangePicker
            presets={rangePresets}
            onChange={onRangeChange}
            size={"large"}
            className="w-full !max-lg:h-fit"
          />
        </div>
        <Button
          text="Download"
          onClick={() => handleDownloadOrders()}
          variant={hasSelected ? "primary" : "disabled"}
          //   loading={loading}
          isReversed
          className={`${buttonStyle} text-nowrap shadow-sm h-full max-lg:h-fit`}
          icon={<BsDownload />}
        />
        <Button
          text="New Order"
          onClick={() => navigate("/Profile/Orders/New")}
          isReversed
          className={`${buttonStyle} text-nowrap shadow-sm h-full max-lg:h-fit`}
          icon={<MdOutlineAdd />}
        />
      </div>
    </div>
  );
};

export default OrdersFilters;

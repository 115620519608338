import { combineReducers } from "redux";
import commonReducer from "../modules/common/reducer";
import authReducer from "../modules/auth/reducer";
import ordersReducer from "../modules/orders/reducer";

/**
 * Root reducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducers = combineReducers({
  common: commonReducer,
  orders: ordersReducer,
  auth: authReducer,
});

export default rootReducers;

import { Navigate, useLocation, Outlet } from "react-router-dom";
import Section from "./Section";
import AuthSelectors from "../modules/auth/selectors";
import { useEffect } from "react";
import Event from "../utils/event";
import AuthActions from "../modules/auth/actions";
import { useAppDispatch } from "../hooks/useStore";

const ProtectedRoute = () => {
  const { isLoggedIn, token } = AuthSelectors();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { logout } = AuthActions();
  useEffect(() => {
    const handleUnauthorizedError = (text) => {
      dispatch(logout());
    };
    Event.on("unauthorizedError", handleUnauthorizedError);
    return () => {
      Event.removeListener("unauthorizedError", handleUnauthorizedError);
    };
  }, []);

  // TODO refresh token and check auth
  return (
    <Section id={location?.pathname?.split("/")[1] || ""} isPage>
      {!isLoggedIn || !token ? (
        <Navigate to="/Login" replace state={{ from: location }} />
      ) : (
        <Outlet />
      )}
    </Section>
  );
};

export default ProtectedRoute;

import BaseService from "../../services/BaseService";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { LoginResponse, RequestMethod } from "../../utils/types";

export const RefreshToken = async (
  accessToken: string
): Promise<{ token: string; expiresAt: string }> =>
  await BaseService(API_ENDPOINTS.REFRESH_TOKEN, RequestMethod.POST, {
    accessToken,
  });

export const LogIn = async (data: {
  email: string;
  password: string;
}): Promise<LoginResponse> =>
  await BaseService(API_ENDPOINTS.LOGIN, RequestMethod.POST, data);

export const GetUser = async () =>
  await BaseService(API_ENDPOINTS.GET_PROFILE, RequestMethod.GET);

export const DownloadPriceList = async (id: string) =>
  await BaseService(
    API_ENDPOINTS.GET_PRICE_LIST,
    RequestMethod.POST,
    {
      destinationId: id,
    },
    null,
    undefined,
    "blob",
    true
  );
export const DownloadExcel = async (orderIds: string[] | React.Key[]) =>
  await BaseService(
    API_ENDPOINTS.ORDER_EXPORT_EXCEL,
    RequestMethod.POST,
    {
      orderIds,
    },
    null,
    undefined,
    "blob",
    true
  );

import * as React from "react";

interface ButtonProps {
  text: string;
  highlightedText: string;
}

const SectionBadge: React.FC<ButtonProps> = ({ text, highlightedText }) => {
  const parts = text.split(highlightedText);

  return (
    <div className="justify-center w-fit px-8 capitalize text-base font-regular text-center border border-primaryGreen border-solid rounded-full">
      {parts.map((part, index) => (
        <h2 key={index}>
          {part}
          {index < parts.length - 1 && (
            <span className="font-semibold text-primaryGreen leading-[48px]">
              {highlightedText}
            </span>
          )}
        </h2>
      ))}
    </div>
  );
};

export default SectionBadge;

import { createContext } from "react";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { IoCloseOutline } from "react-icons/io5";
import ShowDetails from "../components/Alert/ShowDetails";
import Dialog from "../components/Alert/Dialog";
export const AlertsContext = createContext();
const extractErrors = (error) => {
  let errorMessages = [];
  // Check if errors are an object with fields
  if (error?.errors) {
    for (const [field, messages] of Object.entries(error?.errors)) {
      errorMessages.push(...messages);
    }
  }
  // Check if data itself is a string error message
  if (typeof error === "string") {
    errorMessages.push(error);
  }
  // Return all errors as a single string
  return errorMessages.join(" ");
};
export const AlertsContextProvider = ({ children }) => {
  const showSuccess = (text) => {
    toast.success(text, {
      // duration: 9000,
      duration: 3000,
      style: {
        // direction: "rtl",
        zIndex: 99999,
        minWidth: "100%",
        backgroundColor: "#00A344",
        fontSize: "18px",
        color: "white",
        fontFamily: "'Bricolage Grotesque', sans-serif",
        fontWeight: "bold",
        borderRadius: "0px 0px 13px 13px",
      },
    });
  };

  const showToastPromise = (callback, loadingTitle) => {
    toast.promise(
      callback,
      {
        loading: loadingTitle,
      },
      {
        style: {
          minWidth: "250px",
          fontFamily: "var(--app-primary-Font)",
          fontSize: 14,
          fontWeight: "bold",
        },
        error: {
          duration: 0.1,
        },
        success: {
          duration: 0.1,
        },
      }
    );
  };
  const showWarn = (text, type, backgroundColor = "#F7941E") => {
    toast.success(text, {
      duration: 3000,
      style: {
        minWidth: "50%",
        backgroundColor: backgroundColor,
        fontSize: "16px",
        color: "white",
        fontFamily: "'Bricolage Grotesque', sans-serif",
        fontWeight: "bold",
        borderRadius: "1rem",
      },
      type: type,
      icon: "",
    });
  };
  const showError = (text) => {
    // TODO
    toast.error(JSON.stringify(text), {
      icon: "",
      duration: 3000,
      style: {
        minWidth: "100%",
        zIndex: 99999,
        backgroundColor: "var(--app-red-color)",
        fontSize: "18px",
        color: "white",
        fontFamily: "'Bricolage Grotesque', sans-serif",
        fontWeight: "bold",
        borderRadius: "0px 0px 13px 13px",
      },
    });
  };

  const toastSuccess = (text) => {
    toast.success(text, {
      duration: 3000,
      style: {
        fontFamily: "'Bricolage Grotesque', sans-serif",
        fontWeight: "bold",
        fontSize: "15px",
      },
    });
  };

  const toastError = (text) => {
    // const message = typeof text === "string" ? text : JSON.stringify(text);
    const message = extractErrors(text);
    toast.error(message, {
      duration: 3000,
      style: {
        fontFamily: "'Bricolage Grotesque', sans-serif",
        fontWeight: "bold",
        fontSize: "15px",
      },
    });
  };

  const toastPromise = (callback, successText, errorText) => {
    toast.promise(callback, {
      type: "loading",
      loading: "Downloading...",
      success: <b className="toast-text">{successText}</b>,
      error: <b className="toast-text">{errorText}</b>,
    });
  };

  return (
    <AlertsContext.Provider
      value={{
        showError,
        showSuccess,
        showWarn,
        toastSuccess,
        toastError,
        toastPromise,
        showToastPromise,
      }}
    >
      {children}
      <Toaster containerStyle={{ top: 10, left: 0, width: "100%" }}>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                <div
                  style={{
                    // direction: "rtl",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span> {message}</span>
                  {t.type === "showDetail" ? <ShowDetails /> : null}
                  {/* TODO */}
                  {t.type === "Dialog" ? <Dialog /> : null}
                </div>
                {t.type !== "loading" && (
                  <div onClick={() => toast.dismiss(t.id)}>
                    <IoCloseOutline size={20} />
                  </div>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </AlertsContext.Provider>
  );
};

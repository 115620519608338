import { Select, Tooltip } from "antd";
import React from "react";

type SelectProps = {
  defaultValue?: string | object;
  className?: string;
  value?: string | object;
  placeholder: string;
  options: any[];
  onChange?: (value: any, option: any | any[]) => void;
  disabled?: boolean;
  labelInValue?: boolean;
  hintText?: string;
};
const ToolTipComp: React.FC<{
  disabledText?: string;
  children: React.ReactNode;
}> = ({ disabledText, children }) => {
  return disabledText ? (
    <Tooltip title={disabledText}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
const SelectSearch = ({
  defaultValue,
  value,
  className,
  placeholder,
  options,
  disabled,
  onChange,
  hintText,
  labelInValue = false,
}: SelectProps) => {
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => {
    try {
      return !!input
        ? (option?.label ?? "").toLowerCase()?.includes(input.toLowerCase())
        : false;
    } catch (e) {
      console.log(e);
    }
    return false;
  };
  return (
    <div
      className={
        className
          ? className
          : "text-lg min-w-[12rem] max-md:w-full rounded-lg border border-gray-300"
      }
    >
      <ToolTipComp disabledText={hintText}>
        <Select
          showSearch
          placeholder={placeholder}
          popupMatchSelectWidth={false}
          // optionFilterProp={"children"}
          labelInValue={labelInValue}
          // onChange={(label: string, value: any) => {
          //   console.log("select Change: ", { label, value });
          //   onChange(value);
          // }}
          onChange={onChange}
          filterOption={filterOption}
          options={options}
          allowClear
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          variant={"borderless"}
          size={"large"}
        />
      </ToolTipComp>
    </div>
  );
};

export default SelectSearch;

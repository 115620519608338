import { GenerateGuid } from "../../utils/get-guid";
import { ValidateNewOrder } from "./service";
import * as Actions from "./types";

const OrderActions = (isNew) => {
  const toggleErrors = (visible) => {
    return async (dispatch) => {
      dispatch({ type: Actions.TOGGLE_ERRORS, payload: visible });
    };
  };
  const addEmptyProduct = (emptyProduct) => {
    return async (dispatch) => {
      dispatch({
        type: Actions.ADD_NEW_PRODUCT,
        payload: { isNew, emptyProduct },
      });
    };
  };
  const deleteProduct = (key, cb = () => {}) => {
    return async (dispatch) => {
      dispatch({ type: Actions.REMOVE_PRODUCT, payload: { key, isNew } });
      cb && cb();
    };
  };
  const addProduct = (product, cb = () => {}) => {
    return async (dispatch) => {
      dispatch({ type: Actions.ADD_PRODUCT, payload: { product, isNew } });
      cb && cb();
    };
  };
  const saveProducts = (products) => {
    return async (dispatch) => {
      dispatch({ type: Actions.SAVE_PRODUCTS, payload: { products, isNew } });
    };
  };

  const saveOrder = (order, cb = () => {}) => {
    return async (dispatch) => {
      dispatch({
        type: isNew ? Actions.NEW_ORDER_SAVED : Actions.ORDER_SAVED,
        payload: order,
      });
      cb && cb();
    };
  };

  const clearOrder = (cb = () => {}) => {
    return async (dispatch) => {
      dispatch({
        type: isNew ? Actions.NEW_ORDER_CLEARED : Actions.ORDER_CLEARED,
        payload: {},
      });
      cb && cb();
    };
  };
  const clearOrderArchive = (cb = () => {}) => {
    return async (dispatch) => {
      dispatch({
        type: Actions.ORDERS_CLEARED,
        payload: {},
      });
      cb && cb();
    };
  };
  const validateNewOrder = (order, cb = (isValid) => {}) => {
    return async (dispatch) => {
      await ValidateNewOrder(order)
        .then((response) => {
          const products =
            response?.products?.map((product) => ({
              ...product,
              product: {
                label: product?.name,
                value: product?.id,
                key: product?.id,
              },
              packing: {
                ...product?.packing,
                label: product?.packing?.amount,
                value: product?.packing?.id,
                key: product?.packing?.id,
              },
              truck: {
                label: product?.truck?.name,
                value: product?.truck?.id,
                key: product?.truck?.id,
              },
              mark: {
                label: product?.mark?.name,
                value: product?.mark?.id,
                key: product?.mark?.id,
              },
              propertiesLs: product?.properties?.reduce(
                (acc, { name, propertyValueId }) => {
                  acc[name] = propertyValueId;
                  return acc;
                },
                {}
              ),
              key: GenerateGuid(),
            })) || [];
          const validatedOrder = {
            shipmentId: response?.shipment?.id,
            shipment: {
              label: response?.shipment?.shippingDate,
              value: response?.shipment?.id,
            },
            destination: {
              label: response?.destination?.name,
              value: response?.destination?.id,
              usingTruck: response?.destination?.usingTruck,
            },
            destinationId: response?.destination?.id,
            notes: response?.notes,
            products: products,
          };
          console.log({ validatedOrder });
          dispatch({
            type: Actions.NEW_ORDER_SAVED,
            payload: validatedOrder,
          });
          cb && cb(true);
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: Actions.NEW_ORDER_CLEARED });
          cb && cb(false);
        });
    };
  };
  return {
    validateNewOrder,
    saveOrder,
    clearOrder,
    clearOrderArchive,
    addEmptyProduct,
    deleteProduct,
    saveProducts,
    addProduct,
    toggleErrors,
  };
};

export default OrderActions;

import React from "react";
import Section from "../layouts/Section";
import SectionBadge from "../components/SectionBadge";
import SectionTitle from "../components/SectionTitle";
import images from "../data/images";
import SEO from "../layouts/SEO";

const AboutUs: React.FC = () => {
  return (
    <Section
      id="AboutUs"
      className="flex justify-center items-center flex-col w-full "
      isPage
    >
      <SEO title={"About Us | Dolina Agro"} />
      <div className="flex flex-wrap w-full max-md:flex-col max-md:gap-0 space-y-4">
        <div className="flex flex-col xl:w-6/12 lg:w-8/12 w-full max-md:ml-0">
          <SectionBadge text="About Us?" highlightedText="Us?" />
          <SectionTitle
            title="Working with Us you will experience the "
            highlighted="aroma of success"
          />
          <p className="text-lg font-light mt-8 pr-4">
            The Dolina Company has been active in the flower market since
            1994.More than 120 types of decorative greenery, more than 80 kinds
            of roses, 90 varieties of gerbera and such plants as gypsophila,
            limonium, solidago, lisianthus are part of our growing Israeli
            assortment. Highly qualified specialists accompany our flowers from
            cutting to packing. On top of that, our branches in South America
            and Africa provide delivery of roses from dozens of plantations in
            Ecuador, Columbia, Kenya and Ethiopia. Our company believes in using
            individual approach to every customer. Being professionals in this
            business, we offer our clients the most successful terms of
            partnership.
          </p>
        </div>
        <div className="flex gap-4 xl:w-6/12 lg:w-4/12 w-full">
          <img
            loading="lazy"
            src={images.dolina_img24}
            alt={"alt"}
            className={"object-cover grow w-1/2 rounded-2xl"}
          />
          <div className="flex gap-4 flex-col w-1/2">
            <div className="flex gap-4">
              <img
                loading="lazy"
                src={images.dolina_img4}
                alt={"alt"}
                className={"object-cover grow w-1/2 rounded-2xl"}
              />
              <img
                loading="lazy"
                src={images.dolina_img20}
                alt={"alt"}
                className={"object-cover grow w-1/2 rounded-2xl"}
              />
            </div>
            <img
              loading="lazy"
              src={images.dolina_img11}
              alt={"alt"}
              className={"object-cover grow w-full rounded-2xl"}
            />
          </div>
        </div>
      </div>
      <Section className="flex gap-4 max-md:flex-col max-md:gap-0 w-full box">
        <div className="flex  gap-4 flex-col w-6/12 max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  bg-gradient-to-bl from-transparent via-secondaryGreen/15 to-transparent">
          <img
            src={images.VisionIcon}
            alt={"vision"}
            className="w-10 aspect-square object-cover"
          />
          <h2 className="text-2xl font-bold">{"Vision"}</h2>
          <p className="text-base font-light leading-5 capitalize text-gray-800">
            {
              "To be the leading global provider of premium floral products, renowned for our unmatched quality, innovation, and personalized service, enriching lives with the beauty of nature."
            }
          </p>
        </div>
        <div className="flex gap-4 flex-col w-6/12 max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  bg-gradient-to-bl from-transparent via-secondaryGreen/15 to-transparent">
          <img
            src={images.MissionIcon}
            alt={"Mission"}
            className="w-10 aspect-square object-cover"
          />
          <h2 className="text-2xl font-bold">{"Mission"}</h2>
          <p className="text-base font-light leading-5 capitalize text-gray-800">
            {
              "At The Dolina Company, our mission is to cultivate and deliver the finest blooms from around the world, exceeding customer expectations at every step. With a commitment to excellence, integrity, and sustainability, we strive to build lasting partnerships, inspire creativity, and spread joy through the language of flowers."
            }
          </p>
        </div>
      </Section>
      <Section className="flex gap-4 justify-center items-center flex-col w-full mb-4 ">
        <SectionBadge text="Services" highlightedText="Services" />
        <div className="flex gap-4 max-md:flex-col max-md:gap-0 w-full flex-wrap">
          <div className="flex gap-2 grow w-3/12 flex-col max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  border border-primaryGreen">
            <h2 className="text-xl font-bold">{"Floral Product Selection"}</h2>
            <p className="text-sm font-light leading-5 capitalize text-gray-800">
              {
                "Explore our extensive range of premium floral products, including over 120 varieties of decorative greenery, 80 kinds of roses, and 90 varieties of gerber, sourced from our thriving Israeli assortment and premium plantations in South America and Africa."
              }
            </p>
          </div>
          <div className="flex gap-2 grow w-3/12 flex-col max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  border border-primaryGreen">
            <h2 className="text-xl font-bold">{"Quality Assurance"}</h2>
            <p className="text-sm font-light leading-5 capitalize text-gray-800">
              {
                "Rest assured that each bloom we offer undergoes meticulous quality control measures, from cultivation to packaging, ensuring that only the freshest and finest flowers make it to your doorstep."
              }
            </p>
          </div>
          <div className="flex gap-2 grow w-3/12 flex-col max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  border border-primaryGreen">
            <h2 className="text-xl font-bold">{"Global Delivery"}</h2>
            <p className="text-sm font-light leading-5 capitalize text-gray-800">
              {
                "Benefit from our global network and enjoy seamless delivery of our premium floral products to your doorstep, wherever you are. With branches in South America and Africa, we offer convenient access to the best blooms from around the world."
              }
            </p>
          </div>
          <div className="flex gap-2 grow w-3/12 flex-col max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  border border-primaryGreen">
            <h2 className="text-xl font-bold">{"Personalized Service"}</h2>
            <p className="text-sm font-light leading-5 capitalize text-gray-800">
              {
                "Experience our commitment to personalized service, tailored to meet your unique needs and preferences. Whether you're a floral enthusiast, a professional florist, or an event planner, our dedicated team is here to assist you every step of the way, providing expert advice, creative solutions, and exceptional customer care."
              }
            </p>
          </div>
          <div className="flex gap-2 grow w-3/12 flex-col max-md:ml-0 max-md:w-full p-8 rounded-lg max-md:mt-6  border border-primaryGreen">
            <h2 className="text-xl font-bold">{"Partnership Opportunities"}</h2>
            <p className="text-sm font-light leading-5 capitalize text-gray-800">
              {
                "Discover the benefits of partnering with The Dolina Company. Whether you're seeking a reliable supplier for your floral business or looking to collaborate on special projects and events, we offer flexible partnership terms and unparalleled support to help you succeed."
              }
            </p>
          </div>
        </div>
      </Section>
      {/* <Section className="flex justify-center items-center flex-col w-full mb-4">
        <SectionBadge text="Our Certificates" highlightedText="Certificates" />
        <SectionTitle
          title="Blossom with Confidence: Why"
          highlighted="Dolina?"
        />
        <div className="flex flex-col rounded-xl bg-neutral-50 mt-4">
          <div className="flex py-8 justify-center">
            <img
              loading="lazy"
              src={
                "https://cdn.builder.io/api/v1/image/assets/TEMP/04b19f282f79cb6cf050817eada4360aecc4eb15c91020ecade4fc2d534f2e32?apiKey=74642dce5e844322a47c44a2b4216260&"
              }
              alt={"Certificate 1"}
              className="w-1/4"
            />
            <img
              loading="lazy"
              src={
                "https://cdn.builder.io/api/v1/image/assets/TEMP/04b19f282f79cb6cf050817eada4360aecc4eb15c91020ecade4fc2d534f2e32?apiKey=74642dce5e844322a47c44a2b4216260&"
              }
              alt={"Certificate 2"}
              className="w-1/4"
            />
            <img
              loading="lazy"
              src={
                "https://cdn.builder.io/api/v1/image/assets/TEMP/04b19f282f79cb6cf050817eada4360aecc4eb15c91020ecade4fc2d534f2e32?apiKey=74642dce5e844322a47c44a2b4216260&"
              }
              alt={"Certificate 3"}
              className="w-1/4"
            />
          </div>
        </div>
      </Section> */}
    </Section>
  );
};

export default AboutUs;

import React from "react";

const ProductAvailable = ({ isAvailable }: { isAvailable: boolean }) => {
  return isAvailable ? (
    <small
      className={
        "bg-green-100 text-primaryGreen border-green-400 font-bold py-1 px-3 rounded-full"
      }
    >
      {/* <FaCheckCircle /> */}
      {"Available"}
    </small>
  ) : (
    <small
      className={
        "bg-red-100 text-red-600 border-red-300 font-bold py-1 px-3 rounded-full"
      }
    >
      {/* <IoMdCloseCircle /> */}
      {"Not Available"}
    </small>
  );
};

export default ProductAvailable;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductViewType } from "../utils/types";
import images from "../data/images";
import { GetProductById } from "../modules/orders/service";
import Icon from "../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import Section from "../layouts/Section";
import ImagesSlider from "./Home/Slider";
import SEO from "../layouts/SEO";
import Button from "../components/Button";
import PriceListModal from "./Contact/Components/PricelistModal";

const ProductView = () => {
  const navigate = useNavigate();
  const { Id } = useParams();
  // const location = useLocation();
  // const { state } = location;
  // console.log({ state });
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState<ProductViewType | undefined>(
    undefined
  );
  useEffect(() => {
    const getProduct = async () => {
      if (Id) {
        await GetProductById(Id)
          .then((data) => setProduct(data))
          .finally(() => setIsLoading(false));
      }
    };
    getProduct();
  }, [Id]);
  const productImages = product?.images.map((image) => image.url);
  return (
    <Section id={product?.name} isPage>
      <SEO
        title={`${product?.name} | Product`}
        description={`${product?.description}`}
        name={"Dolina Agro"}
        type="website"
      />
      <div className="mb-8 w-full max-md:max-w-full">
        {isLoading ? (
          <div className="animate-pulse flex gap-4  m-4">
            <div className="flex-shrink-0 bg-gray-350 w-6/12  aspect-square rounded-xl">
              <span className="block "></span>
            </div>

            <div className="ms-4 mt-2 w-6/12">
              <ul className="mt-8 space-y-3">
                <li className="w-6/12 h-4 bg-gray-200 rounded-full"></li>
                <li className="w-full h-8 bg-gray-200 rounded-full"></li>
              </ul>
              <ul className="mt-8 space-y-3">
                <li className="w-6/12 h-4 bg-gray-200 rounded-full"></li>
                <li className="w-full h-8 bg-gray-200 rounded-full"></li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col md:w-4/12 lg:w-5/12 w-6/12 max-md:ml-0 max-md:w-full">
              {productImages && productImages.length ? (
                <ImagesSlider
                  images={productImages}
                  className=" w-full rounded-lg max-md:mt-8 max-md:max-w-full aspect-square"
                />
              ) : (
                <img
                  src={images.DefaultImg}
                  alt={product?.name}
                  className=" w-full rounded-lg max-md:mt-8 max-md:max-w-full aspect-square"
                  loading="lazy"
                />
              )}
            </div>
            <div className="flex flex-col pl-5 md:w-8/12 lg:w-7/12 w-6/12 max-md:ml-0 max-md:w-full pt-8">
              <div className="flex justify-between">
                <h1 className="text-3xl  font-black tracking-wider leading-10 text-neutral-700 max-md:max-w-full">
                  {product?.name}
                </h1>
                <Icon onClick={() => navigate(-1)}>
                  {/* <Icon onClick={() => navigate("/Catalog", { state })}> */}
                  <IoCloseOutline color={"#000"} />
                </Icon>
              </div>
              <p className="mt-8 text-lg leading-7 text-gray-800 max-md:max-w-full">
                {product?.description}
              </p>
              {product?.properties?.length ? (
                <div className="flex flex-col gap-5 px-1.5 mt-4">
                  <h3 className="font-bold text-xl">{"Properties"}</h3>
                  {product?.properties.map((property) => (
                    <div key={property.id}>
                      <h6 className="font-bold my-2">{property.name}</h6>
                      <div className="flex gap-4 flex-wrap">
                        {property.values.map((value) => (
                          <span
                            className="bg-neutral-100 px-4 py-1 max-md:flex-1 text-center text-nowrap rounded-full"
                            key={value?.id}
                          >
                            {value?.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              {product?.packings?.length ? (
                <div className="gap-4 mt-8 border border-neutral-100 rounded-xl p-4">
                  <div className="flex gap-4 mb-2">
                    <div className="w-6/12 gap-2 font-bold mb-2 pb-2 border-b border-b-primaryGreen">
                      <h6>Properties</h6>
                    </div>
                    <div className="w-6/12 gap-2 font-bold mb-2 pb-2 border-b border-b-primaryGreen">
                      <h6>Packings</h6>
                    </div>
                  </div>
                  {/* data */}
                  {product?.packings?.map((packing, index) => (
                    <div className="flex gap-2 mb-2" key={index}>
                      {/* Properties */}
                      <div className="w-1/2 border-b border-b-gray-100 pb-2">
                        <span>{packing?.properties?.join(", ") || "Any"}</span>
                      </div>
                      {/* Properties end */}
                      {/* Packings */}
                      <div className="w-1/2 border-b border-b-gray-100 pb-2">
                        {packing?.packings?.map((item, index) => (
                          <span key={index} className="ml-1">
                            {item.amount}
                            <small className="font-bold">
                              {`(${item.weight}KG)${
                                index !== packing?.packings?.length - 1
                                  ? ","
                                  : ""
                              }`}
                            </small>
                          </span>
                        ))}
                      </div>
                      {/* Packings end */}
                    </div>
                  ))}
                  {/* data end */}
                </div>
              ) : null}
              <div className="w-full mt-4">
                <Button
                  text={"Price List"}
                  onClick={() => setModalVisible(true)}
                  // onClick={() => navigate("/Contact")}
                  className=" !px-12 !py-3 !max-md:px-5 rounded-lg"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <PriceListModal
        showModal={modalVisible}
        dismiss={() => setModalVisible(false)}
      />
    </Section>
  );
};

export default ProductView;

const Storage = () => {
  const getToken = () => {
    return localStorage.getItem("token");
  };

  const storeToken = (token) => {
    localStorage.setItem("token", token);
  };

  const getUser = () => {
    const userJSON = localStorage.getItem("user");
    return userJSON ? JSON.parse(userJSON) : null;
  };

  const storeUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  const cleanStorage = () => {
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };
  const saveToLocalStorage = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("persistentState", serializedState);
    } catch (e) {
      console.warn(e);
    }
  };
  const loadFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem("persistentState");
      if (serializedState === null) return undefined;
      return JSON.parse(serializedState);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  };
  return {
    getToken,
    getUser,
    storeToken,
    storeUser,
    cleanStorage,
    saveToLocalStorage,
    loadFromLocalStorage,
  };
};
const storage = Storage();
export default storage;

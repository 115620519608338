import React from "react";
import images from "../../data/images";
import Button from "../../components/Button";
import Section from "../../layouts/Section";
import { useNavigateToTop } from "../../hooks/useNavigateToTop";

const SuccessMessage = () => {
  const navigateToTop = useNavigateToTop();

  return (
    <Section className="flex flex-col justify-center items-center p-12 w-full text-center bg-emerald-100 rounded-[40px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <img
        src={images.fillersIcon}
        alt="Fillers Icon"
        className="w-20 aspect-square"
      />
      <h2 className="mt-8 text-5xl font-semibold capitalize w-9/12 max-md:max-w-full max-md:text-4xl">
        {"Working with us you will experience the aroma of success"}
      </h2>
      <Button
        text="Get Started"
        onClick={() => navigateToTop("/Contact")}
        className={"mt-8"}
      />
    </Section>
  );
};

export default SuccessMessage;

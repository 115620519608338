import { GenerateGuid } from "../../utils/get-guid";
import * as Actions from "./types";

const initialState = {
  order: undefined,
  newOrder: undefined,
  showErrors: false,
};
const MapProducts = (products) => {
  const ID = GenerateGuid();
  const mappedData =
    products?.map((item) => ({
      key: item?.key || ID,
      id: item?.product?.value,
      truckId: item?.truck?.value,
      markId: item?.mark?.value,
      productId: item?.product?.value,
      packingValueId: item?.packing?.value,
      disabled: item?.disabled,
      boxesCount: item?.boxesCount,
      properties: item?.propertiesLs
        ? Object.values(item?.propertiesLs || [])
        : item?.properties?.map((property) => property.propertyValueId)
        ? Object.values(item?.propertiesLs || [])
        : [],
      // properties: item?.propertiesLs
      //   ? Object.values(item?.propertiesLs || [])
      //   : [],
      errors: item?.errors,
    })) || [];
  return mappedData;
};
const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_NEW_PRODUCT: {
      const { isNew, emptyProduct } = action.payload;
      const oldProducts = isNew
        ? state?.newOrder?.products
        : state?.order?.products;
      // const ID = GenerateGuid();
      // const newProduct = {
      //   key: ID,
      //   product: undefined,
      //   mark: undefined,
      //   truck: undefined,
      //   packing: undefined,
      //   boxesCount: undefined,
      //   properties: undefined,
      // };
      const newProducts = oldProducts
        ? [...oldProducts, emptyProduct]
        : [emptyProduct];
      return isNew
        ? {
            ...state,
            newOrder: {
              ...state.newOrder,
              products: newProducts,
            },
            showErrors: false,
          }
        : {
            ...state,
            order: {
              ...state.order,
              products: newProducts,
            },
            showErrors: false,
          };
    }
    case Actions.REMOVE_PRODUCT: {
      const { key, isNew } = action.payload;
      const oldProducts = isNew
        ? state?.newOrder?.products
        : state?.order?.products;
      const newData = oldProducts?.filter((item) => item.key !== key);
      const mappedData = MapProducts(newData);
      return isNew
        ? {
            ...state,
            newOrder: {
              ...state.newOrder,
              products: newData,
              mappedProducts: mappedData,
            },
            showErrors: false,
          }
        : {
            ...state,
            order: {
              ...state.order,
              products: newData,
              mappedProducts: mappedData,
            },
            showErrors: false,
          };
    }
    case Actions.ADD_PRODUCT: {
      const { product, isNew } = action.payload;
      const oldProducts = isNew
        ? state?.newOrder?.products
        : state?.order?.products;
      const newProduct = {
        ...product,
        // id: product?.name,
        properties:
          (product?.propertiesLs &&
            Object.entries(product?.propertiesLs)?.map(([key, value]) => {
              return { name: key, propertyValueId: value };
            })) ||
          [],
        // properties: Object.values(product?.propertiesLs || []),
      };

      const newProducts = oldProducts ? [...oldProducts] : [];
      const index = newProducts.findIndex(
        (item) => newProduct.key === item.key
      );
      const item = newProducts[index];
      newProducts.splice(index, 1, {
        ...item,
        ...newProduct,
      });
      const mappedData = MapProducts(newProducts);
      return isNew
        ? {
            ...state,
            showErrors: false,
            newOrder: {
              ...state.newOrder,
              products: newProducts,
              mappedProducts: mappedData,
            },
          }
        : {
            ...state,
            showErrors: false,
            order: {
              ...state.order,
              products: newProducts,
              mappedProducts: mappedData,
            },
          };
    }
    case Actions.SAVE_PRODUCTS: {
      const { isNew, products } = action.payload;
      const mappedData = MapProducts(products);
      return isNew
        ? {
            ...state,
            newOrder: {
              ...state.newOrder,
              products: products,
              mappedProducts: mappedData,
            },
          }
        : {
            ...state,
            order: {
              ...state.order,
              products: products,
              mappedProducts: mappedData,
            },
          };
    }
    case Actions.ORDER_SAVED: {
      const { destination, products } = action.payload;
      const isUsingTruck = destination?.usingTruck;
      const mappedData = MapProducts(products);
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload,
          products: products?.map((item) => ({
            ...item,
            truck: isUsingTruck ? item?.truck : null,
          })),
          mappedProducts: mappedData?.map((item) => ({
            ...item,
            truckId: isUsingTruck ? item?.truckId : null,
          })),
        },
      };
    }
    case Actions.ORDER_CLEARED: {
      return {
        ...state,
        order: undefined,
        showErrors: false,
      };
    }
    case Actions.NEW_ORDER_SAVED: {
      const { destination, products } = action.payload;
      const isUsingTruck = destination?.usingTruck;
      const mappedData = MapProducts(products);
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          ...action.payload,
          products: products?.map((item) => ({
            ...item,
            truck: isUsingTruck ? item?.truck : null,
          })),
          mappedProducts: mappedData?.map((item) => ({
            ...item,
            truckId: isUsingTruck ? item?.truckId : null,
          })),
        },
      };
    }
    case Actions.NEW_ORDER_CLEARED: {
      return {
        ...state,
        newOrder: undefined,
        showErrors: false,
      };
    }
    case Actions.ORDERS_CLEARED: {
      return {
        ...state,
        newOrder: undefined,
        order: undefined,
        showErrors: false,
      };
    }
    case Actions.TOGGLE_ERRORS: {
      const isVisible = action.payload;
      return {
        ...state,
        showErrors: isVisible,
      };
    }
    default:
      return state;
  }
};

export default ordersReducer;

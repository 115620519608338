import React, { useState } from "react";
import {
  ProductSelectionType,
  ProductType,
  SelectionType,
  TruckType,
} from "../../../../utils/types";
import { Table } from "antd";
import Button from "../../../../components/Button";
import { IoIosAddCircleOutline } from "react-icons/io";
import RemoveProduct from "./RemoveProduct";
import EmptyState from "../../../../components/ui/EmptyState";
import images from "../../../../data/images";
import EditProductRow from "./EditProductRow";
import EditProductCell from "./EditProductCell";
import OrderSelectors from "../../../../modules/orders/selectors";
import { useAppDispatch } from "../../../../hooks/useStore";
import OrderActions from "../../../../modules/orders/actions";
import { GenerateGuid } from "../../../../utils/get-guid";
import { useMediaQuery } from "react-responsive";

const EditOrderForm = ({
  products,
  marks,
  trucks,
  dataRows,
  isNew = false,
  loadDraft,
  startNew,
  loading,
}: {
  products?: ProductSelectionType[];
  marks: SelectionType[];
  trucks: TruckType[];
  dataRows?: ProductType[];
  isNew?: boolean;
  loadDraft?: Function;
  startNew?: Function;
  loading?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    addEmptyProduct,
    deleteProduct,
    addProduct,
    clearOrder,
    toggleErrors,
  } = OrderActions(isNew);
  const { orderSelector, orderValidSelector, errorsSelector } =
    OrderSelectors(isNew);
  const [dataSource, setDataSource] = useState<ProductType[] | undefined>(
    dataRows
  );
  const handleAdd = () => {
    const ID = GenerateGuid();
    const newData: ProductType = {
      key: ID,
      product: undefined,
      mark: undefined,
      truck: undefined,
      packing: undefined,
      boxesCount: undefined,
      properties: undefined,
    };
    setDataSource(dataSource ? [...dataSource, newData] : [newData]);
    dispatch(addEmptyProduct(newData));
  };
  const handleDelete = (key?: string) => {
    const newData = dataSource?.filter((item) => item.key !== key);
    setDataSource(newData);
    dispatch(deleteProduct(key));
    // saveChanges(newData);
  };
  const handleSave = (row: ProductType) => {
    const newData = dataSource ? [...dataSource] : [];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    dispatch(addProduct(row));
  };
  const destination = orderSelector?.destination;
  const isUsingTruck = orderSelector?.destination?.usingTruck;
  const columns = [
    {
      title: "",
      dataIndex: "remove",
      width: "3%",
      key: "x",
      render: (_: any, record: ProductType) =>
        record.disabled ? null : (
          <RemoveProduct handleRemove={() => handleDelete(record.key)} />
        ),
    },
    {
      title: "Mark",
      dataIndex: "mark",
      // width: "15%",
      editable: true,
      required: true,
    },
    {
      title: "Truck",
      dataIndex: "truck",
      // width: "15%",
      editable: true,
      required: isUsingTruck,
    },
    {
      title: "Product",
      dataIndex: "product",
      // width: "15%",
      editable: true,
      required: true,
    },
    {
      title: "Properties",
      dataIndex: "properties",
      editable: true,
      // width: "25%",
      // required: true,
    },
    {
      title: "Packings",
      dataIndex: "packing",
      // width: "15%",
      editable: true,
      required: true,
    },
    {
      title: "Boxes",
      dataIndex: "boxesCount",
      editable: true,
      required: true,
      // width: "7%",
    },
    {
      title: "Total Units",
      width: "5%",
      dataIndex: "total",
      editable: true,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ProductType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        trucks,
        marks,
        products,
        handleSave,
        isUsingTruck,
        destination,
        showError: errorsSelector,
        required: col.required,
      }),
    };
  });

  const components = {
    body: {
      // row: ({ ...props }) => {
      //   return (
      //     <EditProductRow
      //       index={GenerateGuid()}
      //       // loadedData={record}
      //       {...props}
      //     />
      //   );
      // },
      row: EditProductRow,
      cell: EditProductCell,
    },
  };
  const continueOrdering = () => {
    dispatch(toggleErrors(false));
    loadDraft && loadDraft();
    setDataSource(orderSelector?.products);
  };
  const startNewOrder = () => {
    dispatch(clearOrder(() => handleAdd()));
    startNew && startNew();
  };
  const isMobile = useMediaQuery({ maxWidth: "1150px" });
  return (
    <div className="gap-4 my-8">
      <Table
        rowClassName={"editable-row"}
        sticky={isMobile ? false : { offsetHeader: 165 }}
        columns={mergedColumns}
        components={components}
        scroll={{ x: true }}
        size="small"
        // rowKey="key"
        loading={loading}
        rowKey={(record) => `[p]-${record?.key}`}
        dataSource={dataSource}
        pagination={false}
        className="table-top-align text-nowrap"
        showHeader={dataSource?.length ? true : false}
        locale={{
          emptyText: (
            <EmptyState
              image={images.EmptyFiles}
              title={
                <h2 className="text-center text-body text-xl font-semibold leading-loose pb-2">
                  Lets Create
                </h2>
              }
              description={
                <p className="text-center  text-base font-normal leading-relaxed pb-4">
                  Hit the green buttons down below to Create an order
                </p>
              }
              footer={
                <div className="flex mt-4 gap-4 max-md:gap-2 justify-center">
                  {orderValidSelector ? (
                    <Button
                      text={"LOAD DRAFT DATA"}
                      variant="secondary"
                      loading={loading}
                      onClick={continueOrdering}
                      className="!px-12 !py-3 !max-md:px-5 rounded-lg max-md:w-8/12"
                    />
                  ) : null}
                  <Button
                    text={"Start New"}
                    // disabled={isLoading}
                    // variant={
                    //   orderValidSelector
                    //     ? "primary"
                    //     : "secondary"
                    // }
                    onClick={startNewOrder}
                    className="!px-12 !py-3 !max-md:px-5 rounded-lg max-md:w-4/12"
                  />
                </div>
              }
            />
          ),
        }}
        footer={
          dataSource?.length
            ? () => (
                <Button
                  className="border-dashed rounded-lg"
                  isReversed
                  onClick={handleAdd}
                  variant="secondary"
                  text={"Add Product ..."}
                  icon={<IoIosAddCircleOutline size={20} />}
                />
              )
            : undefined
        }
      />
    </div>
  );
};

export default EditOrderForm;

import { useState, useEffect } from "react";
import {
  GetDestinations,
  GetMarks,
  GetProductsSelection,
  GetShipments,
  GetTrucks,
} from "../../../modules/orders/service";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import {
  DestinationType,
  ProductSelectionType,
  SelectionType,
  TruckType,
} from "../../../utils/types";
import { DownloadPriceList } from "../../../modules/auth/service";

type DatasetType = {
  products: ProductSelectionType[];
  destinations: DestinationType[];
  shipments: SelectionType[];
  marks: SelectionType[];
  trucks: TruckType[];
};
const useDataset = () => {
  const { toastError, toastPromise } = useAlertsContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [priceList, setPriceList] = useState<{
    downloading: boolean;
    destination?: DestinationType;
  }>({
    downloading: false,
    destination: undefined,
  });
  const [dataset, setDataset] = useState<DatasetType>({
    products: [],
    destinations: [],
    shipments: [],
    marks: [],
    trucks: [],
  });

  const FetchDataset = () => {
    setFetchingData(true);
    Promise.allSettled([
      GetProductsSelection(),
      GetDestinations(),
      GetMarks(),
      GetTrucks(),
      GetShipments(),
    ])
      .then(
        ([
          productsResult,
          destinationsResult,
          marksResult,
          trucksResult,
          shipmentsResult,
        ]) => {
          setDataset((prevDataset) => ({
            products:
              productsResult.status === "fulfilled"
                ? productsResult.value
                : prevDataset.products,
            destinations:
              destinationsResult.status === "fulfilled"
                ? destinationsResult.value
                : prevDataset.destinations,
            shipments:
              shipmentsResult.status === "fulfilled"
                ? shipmentsResult.value
                : prevDataset.shipments,
            marks:
              marksResult.status === "fulfilled"
                ? marksResult.value
                : prevDataset.marks,
            trucks:
              trucksResult.status === "fulfilled"
                ? trucksResult.value
                : prevDataset.trucks,
          }));
          //   if (productsResult.status === "rejected") {
          //     toastError(productsResult.reason?.data);
          //   }
          //   if (destinationsResult.status === "rejected") {
          //     toastError(destinationsResult.reason?.data);
          //   }
          //   if (marksResult.status === "rejected") {
          //     toastError(marksResult.reason?.data);
          //   }
          //   if (trucksResult.status === "rejected") {
          //     toastError(trucksResult.reason?.data);
          //   }
          //   if (shipmentsResult.status === "rejected") {
          //     toastError(shipmentsResult.reason?.data);
          //   }
        }
      )
      .catch((error) => {
        toastError(error?.data);
      })
      .finally(() => {
        setFetchingData(false);
      });
  };
  useEffect(() => {
    FetchDataset();
  }, []);
  const getPriceList = async (destination: DestinationType) => {
    setPriceList({ downloading: true, destination });
    const promise = DownloadPriceList(destination.id)
      .then((response) => {
        let fileName = "";
        const disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
          }
        }
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() =>
        setPriceList({ downloading: false, destination: undefined })
      );
    toastPromise(
      promise,
      `${destination.name} downloaded successfully.`,
      `Error occurred during downloading ${destination.name}.`
    );
  };
  return {
    fetchingData,
    dataset,
    priceList,
    getPriceList,
  };
};

export default useDataset;

import React from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import { LinkToTop } from "../../Link";

interface NavItemProps {
  text: string;
  to: string;
  onClick?: any;
}
const NavItem: React.FC<NavItemProps> = ({
  text,
  to,
  onClick = () => {},
  ...props
}) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <LinkToTop
      to={to}
      className={`flex flex-col pt-3 whitespace-nowrap transition-all duration-300 ease-out cursor-pointer hover:text-body ${
        isActive ? "font-bold text-body" : "px-2 pt-2.5"
      }`}
      onClick={onClick}
      {...props}
    >
      <div className="navText">{text}</div>
      {/* {isActive && <div className="mt-4 h-0.5 bg-primaryGreen rounded-md" />} */}
      <div
        className={`mt-4 h-0.5 bg-${
          isActive ? "primaryGreen" : "transparent"
        } rounded-md`}
      />
    </LinkToTop>
  );
};

export default NavItem;

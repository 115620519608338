import * as React from "react";
import Section from "../../layouts/Section";
import SectionBadge from "../../components/SectionBadge";
import { FaArrowRightLong } from "react-icons/fa6";
import images from "../../data/images";
import SectionTitle from "../../components/SectionTitle";
import { LinkToTop } from "../../components/Link";

// interface StatCardProps {
//   percentage: string;
//   mark: string;
//   title: string;
//   description: string;
// }

// const StatCard: React.FC<StatCardProps> = ({
//   percentage,
//   mark,
//   title,
//   description,
// }) => (
//   <div className="flex flex-col grow px-8 pt-8 pb-5 w-full bg-emerald-100 hover:bg-emerald-200 transition duration-300 ease-in-out rounded-[40px] max-md:px-5 max-md:mt-3">
//     <div className="text-5xl font-extrabold leading-[56px] max-md:text-4xl max-md:leading-[52px]">
//       {percentage}
//       <span className="text-primaryGreen">{mark}</span>
//     </div>
//     <div className="mt-6 text-xl font-semibold leading-6">{title}</div>
//     <div className="mt-6 text-base font-light leading-6 capitalize text-gray-800">
//       {description}
//     </div>
//   </div>
// );

const About: React.FC = () => {
  // const statCardsData = [
  //   {
  //     percentage: "95",
  //     mark: "%",
  //     title: "Clients Satisfaction",
  //     description:
  //       "There are many variation of passages of lorem ipsum available, but the majority have",
  //   },
  //   {
  //     percentage: "7K",
  //     mark: "+",
  //     title: "Total Clients",
  //     description:
  //       "There are many variation of passages of lorem ipsum available, but the majority have",
  //   },
  //   {
  //     percentage: "1.5K",
  //     mark: "+",
  //     title: "Product",
  //     description:
  //       "There are many variation of passages of lorem ipsum available, but the majority have",
  //   },
  //   {
  //     percentage: "124",
  //     mark: "+",
  //     title: "Farmers in the farms",
  //     description:
  //       "There are many variation of passages of lorem ipsum available, but the majority have",
  //   },
  // ];

  return (
    <Section id="about" className="flex relative flex-col max-md:mt-8 box">
      <div className="justify-between w-full max-md:mt-10">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 max-md:mt-2">
              <SectionBadge text="About Us?" highlightedText="Us?" />
              <SectionTitle
                title="Harvesting happiness, one bloom "
                highlighted="at a time"
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 pb-2 max-md:mt-2">
              <p className="text-lg font-light text-gray-800">
                Dolina Company has been active in the flower market since 1994.
                More than 120 types of decorative greenery, gypsophila,
                limonium, solidago, lisianthus are part of our growing Israeli
                assortment. Highly qualified specialists accompany our flowers
                from cutting to packing. On top of that, our branch in South
                Africa provide delivery of roses from dozens of plantations in
                Kenya. Our company believes in using individual approach to
                every customer. Being professionals in this business, we offer
                our clients the most successful terms of partnership.
              </p>
              <LinkToTop
                to="AboutUs"
                className="flex items-center gap-3 self-start py-2 mt-2 text-base font-medium text-center text-primaryGreen rounded-[80px]"
              >
                <span>Read More</span>
                <FaArrowRightLong />
              </LinkToTop>
            </div>
          </div>
        </div>
      </div>
      <figure className="flex flex-col justify-center mt-6 w-full bg-white rounded-[40px]">
        <img
          loading="lazy"
          src={images.dolina_img15}
          alt="Farmer"
          className="w-full object-cover aspect-[2.5] rounded-3xl"
        />
      </figure>
      {/* <section className="flex-wrap gap-y-2 justify-between content-start mt-6 w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {statCardsData.map((card, index) => (
            <div
              key={index}
              className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full"
            >
              <StatCard
                percentage={card.percentage}
                title={card.title}
                mark={card.mark}
                description={card.description}
              />
            </div>
          ))}
        </div>
      </section> */}
    </Section>
  );
};

export default About;

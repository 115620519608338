import React from "react";
import ViewFooter from "./ViewFooter";
import EditFooter from "./EditFooter";
import DuplicateFooter from "./DuplicateFooter";

type ViewFooterProps = {
  notes?: string;
  totalBoxAmount?: number;
  duplicate: Function;
  editOrder: Function;
  downloadExcel: Function;
};

type EditFooterProps = {
  notes?: string;
  totalBoxAmount?: number;
  onCancel: Function;
  onSubmit: Function;
  loading: boolean;
  isValid?: boolean;
};
type DuplicateFooterProps = {
  notes?: string;
  totalBoxAmount?: number;
  onNotesChange: Function;
  onCancel: Function;
  onSubmit: Function;
  loading: boolean;
  isValid?: boolean;
};
type Props = {
  type: "edit" | "view" | "duplicate";
} & (ViewFooterProps | EditFooterProps | DuplicateFooterProps);
const Footer = (props: Props) => {
  const { type, ...rest } = props;

  if (type === "edit") {
    const editProps = rest as EditFooterProps;
    return <EditFooter {...editProps} />;
  } else if (type === "duplicate") {
    const viewProps = rest as DuplicateFooterProps;
    return <DuplicateFooter {...viewProps} />;
  } else if (type === "view") {
    const viewProps = rest as ViewFooterProps;
    return <ViewFooter {...viewProps} />;
  } else {
    return <></>;
  }
};

export default Footer;

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../components/ui/Buttons/Icon";
import { PiUserBold } from "react-icons/pi";
import { LuClipboardList } from "react-icons/lu";
import { RxExit } from "react-icons/rx";
import AuthActions from "../../../modules/auth/actions";
import { useAppDispatch } from "../../../hooks/useStore";
import { TfiLayoutListThumb } from "react-icons/tfi";
import OrderSelectors from "../../../modules/orders/selectors";
import { IoBookmark } from "react-icons/io5";
import Button from "../../../components/Button";
import { FaArrowRightLong } from "react-icons/fa6";
interface MenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  text: string;
  icon?: React.ReactNode;
  isActive?: boolean;
  to?: string;
}
const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  text,
  isActive = false,
  onClick = () => {},
  to = "",
}) => {
  const borderColor = isActive
    ? "!border-primaryGreen !border-b-2"
    : "border-neutral-700";
  const textColor = isActive
    ? "text-primaryGreen font-bold"
    : "text-neutral-700";
  const Element = to ? Link : "div";
  return (
    <Element
      to={to}
      onClick={onClick}
      className={`flex cursor-pointer pr-6 justify-center items-center p-2 whitespace-nowrap ${
        to ? "border-b border-b-gray-350" : ""
      }   ${borderColor}`}
    >
      <div className="flex">
        <Icon className={`bg-transparent ${textColor}`}>{icon}</Icon>
        <span
          className={`my-auto font-medium tracking-wide leading-6 ${textColor}`}
        >
          {text}
        </span>
      </div>
    </Element>
  );
};

const ProfileTabs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { logout } = AuthActions();
  const { orderValidSelector } = OrderSelectors(true);
  const handleLogout = () => {
    dispatch(logout());
  };
  const handleOrdering = () => {
    navigate("/Profile/Orders/New");
  };
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col">
      {orderValidSelector ? (
        <div className="flex max-md:flex-col  items-center justify-between p-2 px-8 gap-4 max-md:gap-2 max-md:rounded-3xl max-md:p-4 rounded-full my-8 bg-neutral-50">
          <div className="flex items-center gap-4">
            <IoBookmark />
            <h1 className="font-bold">You have uncompleted order</h1>
          </div>
          <Button
            text={"Continue"}
            onClick={handleOrdering}
            className="rounded-full"
            icon={<FaArrowRightLong />}
          />
        </div>
      ) : null}

      <div className="flex justify-between w-full max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-wrap gap-4">
          <MenuItem
            icon={<PiUserBold />}
            to="/Profile"
            text="Account Information"
            isActive={pathname === "/Profile"}
          />
          <MenuItem
            icon={<LuClipboardList />}
            to="/Profile/Orders"
            text="Orders"
            isActive={pathname.includes("/Profile/Orders")}
          />
          <MenuItem
            icon={<TfiLayoutListThumb />}
            to="/Profile/Avails"
            text="Avails"
            isActive={pathname.includes("/Profile/Avails")}
          />
        </div>
        <MenuItem icon={<RxExit />} text="Sign out" onClick={handleLogout} />
      </div>
    </div>
  );
};

export default ProfileTabs;

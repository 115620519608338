import React from "react";
import PageTitle from "../../../components/PageTitle";
import Icon from "../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import Button from "../../../components/Button";
import { TbSend } from "react-icons/tb";
import { OrderType } from "../../../utils/types";
import ProductsTable from "./Products/ProductsTable";
import Modal from "../../../components/Modal";

const SubmitModal = ({
  showModal,
  dismiss,
  submit,
  loading,
  orderView,
}: {
  showModal: boolean;
  dismiss: Function;
  submit: Function;
  loading: boolean;
  orderView?: OrderType;
}) => {
  const totalBoxAmount = orderView?.products?.reduce(
    (total, item) => total + (item?.boxesCount || 0),
    0
  );
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-full lg:w-[90dvw] xl:w-[70dvw]"
    >
      {/*header*/}
      <div className="flex justify-between px-4">
        <PageTitle title={"Order Preview"} />
        <Icon className="my-4" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} />
        </Icon>
      </div>
      {/*body*/}
      <div className="relative px-6 max-lg:px-4 max-md:px-0 flex-auto overflow-auto">
        <div className=" my-4 text-lg leading-relaxed border border-gray-350 h-full p-2 rounded-xl">
          {/* <ListView products={orderView?.products} /> */}
          {/* Header */}
          <div className="flex max-lg:flex-col-reverse justify-between items-end max-lg:items-start my-4 border-b border-b-gray-350 pb-2">
            <h1 className="text-xl font-bold max-lg:mt-4">Products</h1>
            <div className="flex flex-wrap gap-4 max-md:gap-x-2 items-end justify-end max-md:justify-start">
              <h1 className="text-lg font-bold max-md:w-full">
                Shipping Details
              </h1>
              <div className="flex flex-col max-md:w-[40%]">
                <span className="text-gray-400 pl-2 text-xs font-bold">
                  {"Destination"}
                </span>
                <span className="text-lg px-2 min-w-[12rem] max-md:w-full">
                  {orderView?.destination?.name}
                </span>
              </div>
              <div className="flex flex-col max-md:w-[40%]">
                <span className="text-gray-400 pl-2 text-xs font-bold">
                  {"Shipping Date"}
                </span>
                <span className="text-lg px-2 min-w-[12rem] max-md:w-full">
                  {orderView?.shipment?.shippingDate}
                  {/* {dayjs(orderView?.shipment?.shippingDate).format(
                          "DD/MM/YYYY"
                        )} */}
                </span>
              </div>
            </div>
          </div>

          {/* Products */}
          <ProductsTable products={orderView?.products} isPreview />

          {/* Footer */}
          <div className="flex gap-4 my-8 max-md:flex-col">
            <div className="w-2/3 max-md:w-full bg-neutral-50 rounded-lg p-4 ">
              <small className="">{orderView?.notes}</small>
            </div>
            <div className="w-1/3 max-md:w-full pt-2 border-t border-t-neutral-300">
              <div className="flex justify-between text-lg font-bold px-2">
                <span>{"Total"}</span>
                <span>{`${totalBoxAmount} boxes`}</span>
              </div>
            </div>
          </div>
        </div>
        {/* <p className="my-4 text-lg leading-relaxed border border-gray-350 h-full p-2 rounded-xl">
                <iframe
                  title={"pdf file"}
                  src={
                    orderView ||
                    "https://pdfobject.com/pdf/sample.pdf#page=1&zoom=auto&pagemode=none&search=&phrase=false"
                  }
                  width="100%"
                  height="100%"
                  frameBorder="0"
                />
              </p> */}
      </div>
      {/*footer*/}
      <div className="flex flex-wrap gap-4 max-md:gap-2 mt-4 justify-center text-lg font-bold px-2">
        <Button
          className="rounded-lg"
          isReversed
          onClick={() => submit()}
          text={"Submit"}
          loading={loading}
          icon={<TbSend />}
        />
        <Button
          className="rounded-lg"
          isReversed
          disabled={loading}
          onClick={() => dismiss()}
          variant={"secondary"}
          text={"Cancel"}
          // icon={<IoCloseOutline />}
        />
      </div>
    </Modal>
  );
};

export default SubmitModal;

import React from "react";
import Button from "../../../components/Button";
import images from "../../../data/images";
import Modal from "../../../components/Modal";

const OrderMessage = ({
  showModal,
  dismiss,
}: {
  showModal: boolean;
  dismiss: Function;
}) => {
  return (
    <Modal showModal={showModal} dismiss={dismiss}>
      {/*body*/}
      <div className="relative p-6 flex flex-col gap-8 items-center">
        <img src={images.infoMessage} alt={"Info message"} className="w-24" />
        <h1 className="text-lg text-center font-bold text-red-700">
          for greens Amsterdam please choose AMS Greens
        </h1>
      </div>
      {/*footer*/}
      <div className="flex flex-wrap gap-4 max-md:gap-2 mt-4 justify-center text-lg font-bold px-2">
        <Button
          className="w-[50%] rounded-lg"
          isReversed
          onClick={() => dismiss()}
          variant={"secondary"}
          text={"Close"}
          // icon={<IoCloseOutline />}
        />
      </div>
    </Modal>
  );
};

export default OrderMessage;

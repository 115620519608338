import React from "react";
import Section from "../../layouts/Section";
import images from "../../data/images";

export const HeroSection = () => {
  return (
    <Section
      id="heroSection"
      className="flex flex-col justify-center items-center px-16 py-1 mt-8 w-full text-6xl font-semibold text-center max-md:px-5 max-md:text-4xl min-h-fit"
    >
      {/* <a
        href="http://www.ultraflo.biz/en/"
        target="_blank"
        rel="noreferrer"
        className="flex flex-col justify-center items-center mb-12 w-[50%]"
      >
        <h1 className="text-4xl">
          {
            "UltraFlo -  Our Partner in Kenya - Export for fresh cut Roses from Kenya"
          }
        </h1>
        <img
          loading="lazy"
          src={images.UltraFlo}
          alt="Company logo"
          className="object-contain  my-5"
        />
        <h3 className="text-3xl">{"Export Flowers from Israel"}</h3>
      </a> */}
      <div className="flex flex-col justify-center items-center">
        <h1 className="flex gap-2 items-center justify-center capitalize max-md:flex-wrap md:flex-wrap">
          <span className=" self-center my-auto">Blooming</span>
          <img
            loading="lazy"
            src={images.heroSection}
            alt="Company logo"
            className="object-cover rounded-full h-[4rem] md:h-20 w-[80%] xl:w-[20rem]  mx-5"
          />
          <span className="my-auto max-md:flex-wrap">
            Excellence: Your&nbsp;
          </span>
        </h1>
        <p className="self-center capitalize mt-4">
          {"Premier "}
          <span className="text-green-600">{"Flower Partner"}</span>
          {" Since 1994"}
        </p>
        <p className="mt-4 text-lg font-light text-gray-800 lg:w-[70%] sm:w-100">
          Explore our diverse selection of top-quality Israeli fresh cut flowers
          and greens. Experience our personalized service and partnership
          excellence today.
        </p>
      </div>
    </Section>
  );
};

import React from "react";
import Icon from "../Buttons/Icon";
import { PiUserBold } from "react-icons/pi";
import { LuClipboardList } from "react-icons/lu";
import { LinkToTop } from "../../Link";
import { useLocation } from "react-router-dom";

const CTA = () => {
  const { pathname } = useLocation();
  const excludedRoutes = ["/profile", "profile"];
  const lowerCasePathname = pathname.toLowerCase();
  const isExcluded = excludedRoutes?.some((route) =>
    lowerCasePathname.includes(route.toLowerCase())
  );
  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [visible, setVisible] = React.useState(true);
  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  return !isExcluded ? (
    <section
      className={`flex flex-col px-7 pt-3.5 pb-3.5 mx-auto mt-0 transition-all duration-300 ${
        visible ? "opacity-100" : "opacity-0 -translate-y-full"
      }
     bg-white rounded-3xl rounded-t-none bg-blend-normal shadow-[0px_3px_4px_rgba(0,0,0,0.1)]`}
    >
      <div className="flex gap-4">
        <LinkToTop to="/Profile" className="flex gap-2">
          <Icon>
            <PiUserBold />
          </Icon>
          <div className="my-auto font-medium tracking-tighter leading-4 text-neutral-700">
            My Account
          </div>
        </LinkToTop>
        <LinkToTop
          to="/Profile/Orders/New"
          className="flex gap-2 font-medium tracking-tighter leading-4 text-neutral-700"
        >
          <Icon dot>
            <LuClipboardList />
          </Icon>
          <div className="my-auto">New Order</div>
        </LinkToTop>
      </div>
    </section>
  ) : null;
};

export default CTA;

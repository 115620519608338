import React, { useState } from "react";
import { IoDuplicateOutline } from "react-icons/io5";
import { RiEyeLine } from "react-icons/ri";
import { FaRegFileExcel } from "react-icons/fa6";
import Icon from "../../../../components/ui/Buttons/Icon";
import { useNavigateToTop } from "../../../../hooks/useNavigateToTop";
import { Tooltip } from "antd";
import { DownloadExcel } from "../../../../modules/auth/service";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import { CgSpinner } from "react-icons/cg";

const OrdersActions = ({ id }: { id: string }) => {
  const navigateToTop = useNavigateToTop();
  const [loading, setLoading] = useState(false);
  const { toastError, toastPromise } = useAlertsContext();
  const exportExcel = async () => {
    setLoading(true);
    // ajax request after empty completing
    const promise = DownloadExcel([id])
      .then((response) => {
        let fileName = "";
        const disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
          }
        }
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
            // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        toastError(e?.data);
      })
      .finally(() => setLoading(false));
    toastPromise(
      promise,
      `Files exported successfully.`,
      `Error occurred during exported files.`
    );
  };

  return (
    <div className="flex gap-2">
      <Tooltip title="Duplicate Order">
        <div>
          <Icon
            onClick={(e) => {
              e?.stopPropagation();
              navigateToTop(`Duplicate/${id}`);
            }}
          >
            <IoDuplicateOutline />
          </Icon>
        </div>
      </Tooltip>
      <Tooltip title="Download Excel File">
        <div>
          <Icon
            onClick={(e) => {
              e?.stopPropagation();
              exportExcel();
            }}
          >
            {loading ? (
              <CgSpinner className="animate-spin w-5 h-5" />
            ) : (
              <FaRegFileExcel />
            )}
          </Icon>
        </div>
      </Tooltip>
      <Tooltip title="View Order's Details">
        <div>
          <Icon
            onClick={(e) => {
              e?.stopPropagation();
              navigateToTop(`${id}`);
            }}
          >
            <RiEyeLine />
          </Icon>
        </div>
      </Tooltip>
    </div>
  );
};

export default OrdersActions;

import React from "react";
import { CatalogType } from "../../../../utils/types";
import CatalogRow from "./CatalogRow";
import CatalogTab from "./CatalogTab";

interface TabProps extends CatalogType {
  variant?: "tab" | "row";
  isActive?: boolean;
  onSelect?: (id: string) => void;
}
const CatalogItem: React.FC<TabProps> = ({ variant = "row", ...rest }) => {
  if (variant === "row") {
    return <CatalogRow {...rest} />;
  } else if (variant === "tab") {
    return <CatalogTab {...rest} />;
  }
  return <CatalogRow {...rest} />;
};

export default CatalogItem;

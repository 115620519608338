import React from "react";
import Section from "../../layouts/Section";
import ContactForm from "./Components/ContactForm";
import ContactInfo from "./Components/ContactInfo";
import PageTitle from "../../components/PageTitle";
import SEO from "../../layouts/SEO";

const Contact: React.FC = () => {
  return (
    <Section id="Contact" isPage>
      <SEO title={"Contact | Dolina Agro"} />
      <div className="flex flex-col py-px max-w-full">
        <header className="flex w-full ">
          <PageTitle
            title="Contact Us"
            subTitle="To contact us, please fill out the form:"
          />
          {/* <p className="mt-8 text-sm tracking-wide leading-8 text-gray-800 max-md:max-w-full">
            {"To contact us, please fill out the form:"}
          </p> */}
        </header>
        <main>
          <ContactForm />
          <ContactInfo
            address="10, Bareket, Industrial Park Caesarea, Israel, 3088900"
            mailbox="2615, Netanya, 42120, Israel"
            telephone="+972-4-6881241"
            phone="+972-54-4526049"
            email="flowers@dolina-agro.co.il"
          />
        </main>
      </div>
    </Section>
  );
};

export default Contact;

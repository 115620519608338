import React, { useEffect } from "react";
import { useDisableBodyScroll } from "../hooks/useDisableBodyScroll";
import { useMediaQuery } from "react-responsive";

const Modal = ({
  showModal,
  dismiss,
  children,
  className,
}: {
  showModal: boolean;
  dismiss: Function;
  children: React.ReactNode;
  className?: string;
}) => {
  useDisableBodyScroll(showModal);
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dismiss();
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  return (
    <div
      className={`transition-colors inset-0 justify-center  ${
        showModal ? "visible bg-black/20" : "invisible"
      } ${
        isMobile ? "items-end" : "items-center"
      } flex overflow-hidden fixed inset-0 z-40 outline-none focus:outline-none`}
    >
      {/*content*/}
      <div
        className={`transition-all  ${
          isMobile
            ? `rounded-t-lg transform ${
                showModal ? "translate-y-0" : "translate-y-full"
              }`
            : `rounded-lg transform ${
                showModal ? "scale-100 opacity-100" : "scale-125 opacity-0"
              }`
        } overflow-y-auto overflow-x-hidden border-0 relative flex flex-col bg-white outline-none focus:outline-none max-h-[90dvh] lg:max-w-[70dvw] max-md:max-w-full max-md:max-h-[90dvh] p-8  max-md:p-4 ${className}`}
      >
        {children}
      </div>
    </div>
    // {/* <div className="opacity-25 fixed inset-0 z-40 bg-black" /> */}
  );
};

export default Modal;

import { useAppSelector } from "../../hooks/useStore";

const AuthSelectors = () => {
  const isLoading = useAppSelector((state) => state.auth?.isLoading);
  const isLoggedIn = useAppSelector((state) => state.auth?.isLoggedIn);
  const user = useAppSelector((state) => state.auth?.user);
  const token = useAppSelector((state) => state.auth?.jwt);
  const expiresAt = useAppSelector((state) => state.auth?.expiresAt);
  const state = useAppSelector((state) => state);
  return {
    isLoading,
    isLoggedIn,
    user,
    token,
    state,
    expiresAt,
  };
};

export default AuthSelectors;

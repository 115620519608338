import * as Actions from "./types";

const initialState = {
  user: {},
  jwt: null,
  expiresAt: null,
  isLoading: false,
  error: null,
  isLoggedIn: false,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        jwt: action.payload?.token,
        expiresAt: action.payload?.expiresAt,
        isLoading: false,
        error: null,
        isLoggedIn: true,
      };
    case Actions.SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.SIGN_IN_SUCCESS:
      return {
        ...state,
        jwt: action.payload?.jwt,
        expiresAt: action.payload?.expiresAt,
        isLoading: false,
        error: null,
        isLoggedIn: true,
      };
    case Actions.SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoggedIn: false,
      };
    case Actions.GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: null,
      };
    case Actions.GET_USER_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;

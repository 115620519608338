import React from "react";
import images from "../../data/images";

const EmptyState = ({
  title,
  image,
  description,
  footer,
}: {
  title?: React.ReactNode | string;
  image?: string;
  description?: React.ReactNode | string;
  footer?: React.ReactNode | string;
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 pt-24">
      <img src={image || images.Empty} alt={"Empty Icon"} className="w-40" />
      <div>
        {title ? title : null}
        {description ? description : null}
        {footer ? footer : null}
      </div>
    </div>
  );
};

export default EmptyState;

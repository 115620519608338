import { GetProp, TableProps } from "antd";

export interface LoginResponse {
  id: string;
  fullName: string;
  jwt: string;
  verificationRequstId: string;
  expiresAt: string;
  refreshTokenExpiresAt: string;
}
export interface InputFieldProps {
  label?: string;
  placeholder: string;
  name: string;
  required?: boolean;
  textArea?: boolean;
  type?: string;
  className?: string;
  component?: React.ReactNode;
  value?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  isReadOnly?: boolean;
  removeInputStyle?: boolean;
}
export type EnumsType = {
  text: string;
  value: number | string;
};
export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}
// export type ShippingType = EnumsType[];
export type labelInValueType = {
  key?: string;
  value?: string;
  label?: string | number;
};
export type SelectionType = {
  id: string;
  name: string;
  isActive?: boolean;
};
export type TruckType = {
  id: string;
  value: string;
};
export interface ProductSelectionType extends SelectionType {
  isPackedByProduct: boolean;
  isPricedByProduct: boolean;
  isInventoryByProduct: boolean;
  isCostByProduct: boolean;
  hasProperties: boolean;
}
export interface PackingsType extends SelectionType {
  amount: number;
  weight: number;
  boxId: string;
}
export interface DestinationType extends SelectionType {
  usingTruck: boolean;
}
export type CatalogType = {
  id: string;
  image?: string;
  icon?: any;
  title: string;
  products?: Product[];
};

export type Product = {
  id: string | number;
  name: string;
  image: string;
  description: string;
};
export interface ProductViewType extends Product {
  images: {
    url: string;
    isPrimary: string;
  }[];
  properties: {
    id: string;
    name: string;
    values: {
      id: string;
      name: string;
    }[];
  }[];
  packings: {
    properties: string[];
    packings: { amount: number; weight: number }[];
  }[];
}

export type ColumnsType<T> = TableProps<T>["columns"];
export type TablePaginationConfig = Exclude<
  GetProp<TableProps, "pagination">,
  boolean
>;
export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  sorter?: Parameters<GetProp<TableProps, "onChange">>[2];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}
export enum StatusEnum {
  OrderSentByClient,
  InProgress,
  AllSentToFarms,
  Shipped,
  Canceled,
}
export interface AvailsDataType {
  id: string;
  name: string;
  isAvailable: boolean;
  pricings: {
    isAvailable: boolean;
    properties: string[];
    price: number;
  }[];
}

export interface OrdersDataType {
  id: string;
  number: number;
  addedDate: string;
  boxesCount: number;
  pdf: string;
  status: number;
  shippingDate: string;
  destination: string;
}

export interface PackingType {
  id?: string;
  amount?: number;
  boxId?: string;
  isActive?: boolean;
}
export interface PropertyType {
  name: string;
  propertyId: string;
  propertyValueId: string;
  propertyValueName: string;
  isActive?: boolean;
}
export interface ProductFormType {
  key?: string;
  product?: labelInValueType;
  boxesCount?: number;
  mark?: labelInValueType;
  truck?: labelInValueType;
  packing?: PackingType;
  properties?: any[];
  // properties?: PropertyType[];
  propertiesLs?: string[];
  disabled?: boolean;
  isActive?: boolean;
  [key: string]: any;
}
export interface ProductType {
  key?: string;
  id?: string;
  name?: string;
  boxesCount?: number;
  mark?: SelectionType;
  truck?: SelectionType;
  packing?: PackingType;
  properties?: any[];
  // properties?: PropertyType[];
  propertiesLs?: string[];
  disabled?: boolean;
  isActive?: boolean;
  [key: string]: any;
}
export interface OrderValidation {
  products: string;
  productsRows: string;
  shipmentId: string;
  destinationId: string;
}
export interface OrderProductType {
  id?: string;
  truckId?: string;
  markId?: string;
  productId?: string;
  packingValueId?: string;
  boxesCount?: number;
  properties?: string[];
  disabled?: string;
}

export interface OrderViewType {
  destination?: string;
  shippingDate?: string;
  notes?: string;
  products?: ProductType[];
}
export interface EditOrderType {
  orderId?: string;
  products?: OrderProductType[];
}
export interface NewOrderType {
  shipmentId?: string;
  destination?: string;
  notes?: string;
  products?: OrderProductType[];
}
export interface OrderType {
  id?: string;
  number?: number;
  addedDate?: string;
  notes?: string;
  status?: StatusEnum;
  destination?: {
    id: string;
    name: string;
    isActive: boolean;
    usingTruck: boolean;
  };
  shipment?: {
    id: string;
    shippingDate: string;
    status: number;
    isActive: boolean;
  };
  products?: ProductType[];
}

export interface AllDTFilter {
  start: number;
  length: number;
  customSearch?: {
    from?: string;
    to?: string;
    searchTerm?: string;
  };
  columns?: {
    data?: string;
    name?: string;
    searchable?: boolean;
    orderable?: boolean;
  }[];
  order?: { column: number; dir: string }[];
}
export interface Contact {
  name: string;
  subject: string;
  company: string;
  country: string;
  email: string;
  phoneNumber: string;
  info: string;
}

export interface ContactFormValues {
  fullName: string;
  company: string;
  email: string;
  telephone: string;
  country: string;
  subject: string;
  message: string;
  [key: string]: string | undefined;
}

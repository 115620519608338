import React from "react";
import Button from "../../../../components/Button";
import { IoDuplicateOutline } from "react-icons/io5";
import { FaRegFileExcel } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";

type Props = {
  notes?: string;
  totalBoxAmount?: string | number;
  duplicate: Function;
  editOrder: Function;
  downloadExcel: Function;
};
const ViewFooter = ({
  notes,
  totalBoxAmount,
  duplicate,
  editOrder,
  downloadExcel,
}: Props) => {
  return (
    <div className="flex gap-4 my-8 max-md:flex-col">
      <div className="w-2/3 max-md:w-full bg-neutral-50 rounded-lg p-4 ">
        <h1 className="">{notes}</h1>
      </div>
      <div className="w-1/3 max-md:w-full pt-2 border-t border-t-neutral-300">
        <div className="flex justify-between text-lg font-bold px-2">
          <span>{"Total"}</span>
          <span>{`${totalBoxAmount} boxes`}</span>
        </div>
        <div className="flex flex-wrap max-md:gap-2 mt-4 justify-between text-lg font-bold px-2">
          <Button
            className="w-full rounded-lg"
            isReversed
            onClick={() => duplicate()}
            text={"Duplicate"}
            icon={<IoDuplicateOutline />}
          />
          <Button
            className="w-[48%] rounded-lg"
            isReversed
            onClick={() => editOrder()}
            variant="secondary"
            text={"Edit"}
            icon={<FiEdit />}
          />
          <Button
            className="w-[48%] rounded-lg"
            isReversed
            onClick={() => downloadExcel()}
            variant="secondary"
            text={"Excel"}
            icon={<FaRegFileExcel />}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewFooter;

import React, { useEffect } from "react";
import ProfileTabs from "./components/ProfileTabs";
import PageTitle from "../../components/PageTitle";
import AuthSelectors from "../../modules/auth/selectors";
import Button from "../../components/Button";
import { FaRegFileExcel } from "react-icons/fa6";
import { useAppDispatch } from "../../hooks/useStore";
import AuthActions from "../../modules/auth/actions";
import useDataset from "../orders/hooks/useDataset";
import SEO from "../../layouts/SEO";
import { useMediaQuery } from "react-responsive";

const InfoItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div className="flex flex-col grow text-base tracking-wide leading-4 whitespace-nowrap max-md:mt-10 border-b border-b-gray-350 pb-2">
    <div className="text-neutral-700">{label}</div>
    <div className={`mt-4 ${!value ? "text-gray-400" : " font-bold"}`}>
      {value || "not assign"}
    </div>
  </div>
);

const Info = () => {
  const dispatch = useAppDispatch();
  const { handleUser } = AuthActions();
  const { isLoading } = AuthSelectors();

  const { user } = AuthSelectors();
  const { country, city, email, fullName, phoneNumber, address } = user;

  const { dataset, priceList, getPriceList } = useDataset();
  const { destinations, marks } = dataset;

  useEffect(() => {
    dispatch(handleUser());
  }, []);
  const isMobile = useMediaQuery({ maxWidth: "1150px" });

  return (
    <>
      <SEO title={"Profile | Dolina Agro"} />
      <ProfileTabs />
      <div className="px-4 mt-8 max-md:mt-10">
        <PageTitle title="Account Information" />
      </div>
      {isLoading ? (
        <></>
      ) : (
        <>
          {/* <div className="flex justify-end mt-8 w-full rounded-lg  max-md:max-w-full">
            <Button
              className="rounded-lg"
              isReversed
              onClick={() => getPriceList()}
              text={"Download Price List"}
              icon={<FaRegFileExcel />}
              loading={downloading}
            />
          </div> */}
          <div className="flex flex-col justify-center p-5 mt-8 w-full rounded-lg bg-neutral-100 max-md:max-w-full">
            <div className="py-5 pr-20 pl-10 rounded-xl bg-neutral-100 max-md:px-5 max-md:max-w-full">
              <div className="flex gap-4 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full gap-4">
                  <InfoItem label="Name" value={fullName} />
                  <InfoItem label="Country" value={country} />
                </div>
                <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full gap-4">
                  <InfoItem label="Telephone" value={phoneNumber} />
                  <InfoItem label="City" value={city} />
                </div>
                <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full gap-4">
                  <InfoItem label="Email" value={email} />
                  <InfoItem label="Address" value={address} />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-8 max-md:mt-10">
            <PageTitle title="Marks" />
            <div className="flex px-6 gap-4 flex-wrap">
              {marks.map((value) => (
                <span
                  className="bg-neutral-100 px-4 py-1 rounded-full"
                  key={value.id}
                >
                  {value.name}
                </span>
              ))}
            </div>
          </div>
          <div className="px-4 mt-8 max-md:mt-10">
            <PageTitle title="Destinations" />
            <div className="flex flex-col  px-6 gap-4">
              {destinations.map((value) => (
                <div
                  key={value.id}
                  className="p-2 flex items-center justify-between border-b border-b-neutral-100"
                >
                  <span className="font-bold">{value.name}</span>
                  <Button
                    className="rounded-lg"
                    isReversed
                    onClick={() => getPriceList(value)}
                    text={isMobile ? "" : "Price List"}
                    icon={<FaRegFileExcel />}
                    loading={
                      priceList.downloading &&
                      priceList.destination?.id === value.id
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Info;

import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { IoCloseOutline } from "react-icons/io5";
import Icon from "../../components/ui/Buttons/Icon";
import { useNavigate } from "react-router-dom";
import useNewOrder from "./hooks/useNewOrder";
import {
  DestinationType,
  OrderProductType,
  SelectionType,
} from "../../utils/types";
import SelectSearch from "../../components/ui/Inputs/SelectSearch";
import EditOrderForm from "./components/Products/EditOrderForm";
import SubmitModal from "./components/SubmitModal";
import Footer from "./containers/footer";
import Header from "./containers/header";
import useDataset from "./hooks/useDataset";
import OrderMessage from "./components/NewOrderModal";
import { useAppDispatch } from "../../hooks/useStore";
import OrderActions from "../../modules/orders/actions";
import OrderSelectors from "../../modules/orders/selectors";
import SEO from "../../layouts/SEO";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import ConfirmNavigation from "../../hooks/useConfirmNavigation";

const NewOrder = () => {
  const navigate = useNavigate();
  const {
    isSubmitting,
    viewOrder,
    modalVisible,
    setModalVisible,
    submitOrder,
    orderView,
  } = useNewOrder(true);
  const { dataset, fetchingData } = useDataset();
  const dispatch = useAppDispatch();
  const { saveOrder, validateNewOrder } = OrderActions(true);
  const { orderSelector, orderSubmit } = OrderSelectors(true);
  const { toastError } = useAlertsContext();
  const { products, destinations, shipments, marks, trucks } = dataset;
  const [showMessage, setShowMessage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const shipmentsOptions = shipments.map((options: SelectionType) => ({
    label: options?.name,
    value: options?.id,
  }));
  const destinationsOptions = destinations.map((options: DestinationType) => ({
    label: options?.name,
    value: options?.id,
    usingTruck: options?.usingTruck,
  }));
  const handleChange = (fieldName: string, value: string | object) => {
    dispatch(
      saveOrder({
        ...orderSelector,
        [fieldName]: value,
      })
    );
  };
  const [showPrompt, setShowPrompt] = useState(true);
  const [newOrder, setNewOrder] = useState<any>(null);
  const [showCTA, setShowCTA] = useState(false);
  const startNewOrder = () => {
    setNewOrder(null);
    setShowCTA(true);
  };
  const handleLoadDraftOrder = () => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(
        validateNewOrder(orderSubmit, (isValid: boolean) => {
          if (isValid) {
            setNewOrder(orderSelector);
            setShowCTA(true);
          } else {
            toastError(
              "Error during validating draft order,\n please start new one"
            );
          }
          setIsLoading(false);
        })
      );
    }, 1000);
  };
  const totalBoxAmount = orderSelector?.products?.reduce(
    (total: number, item: OrderProductType) => total + (item?.boxesCount || 0),
    0
  );

  return (
    <>
      <SEO title={"New Order | Dolina Agro"} />
      {/* <ProfileTabs /> */}
      <div className="flex justify-between sticky top-[5.5rem] bg-white z-10">
        <PageTitle title={"New Order"} />
        <Icon className="my-4" onClick={() => navigate("/Profile/Orders")}>
          <IoCloseOutline color={"#000"} />
        </Icon>
      </div>
      {fetchingData ? (
        <div className="animate-pulse flex gap-4">
          <div className="ms-4 mt-2 w-full">
            <ul className="mt-8 space-y-3">
              <li className="w-6/12 h-4 bg-gray-200 rounded-full"></li>
              <li className="w-full h-8 bg-gray-200 rounded-full"></li>
              <li className="w-full h-8 bg-gray-200 rounded-full"></li>
              <li className="w-full h-8 bg-gray-200 rounded-full"></li>
            </ul>
          </div>
        </div>
      ) : (
        <>
          {showCTA ? (
            <Header
              type={"edit"}
              shippingInput={
                <SelectSearch
                  value={newOrder?.shipmentId}
                  placeholder="Select a Shipment"
                  onChange={(value: string) => {
                    setNewOrder({ ...newOrder, shipmentId: value });
                    handleChange("shipmentId", value);
                  }}
                  options={shipmentsOptions}
                />
              }
              destinationInput={
                <SelectSearch
                  value={newOrder?.destination?.value}
                  placeholder={"Select a Destination"}
                  labelInValue={true}
                  onChange={(_: any, option: any) => {
                    const { label, usingTruck, value } = option || {};
                    setNewOrder({
                      ...newOrder,
                      destination: { label, usingTruck, value },
                    });
                    handleChange("destination", { label, usingTruck, value });
                  }}
                  options={destinationsOptions}
                />
              }
            />
          ) : null}
          <EditOrderForm
            marks={marks}
            trucks={trucks}
            products={products}
            isNew={true}
            loading={isLoading}
            loadDraft={handleLoadDraftOrder}
            startNew={startNewOrder}
          />
          {showCTA ? (
            <Footer
              type={"duplicate"}
              notes={newOrder?.notes}
              totalBoxAmount={totalBoxAmount}
              loading={isSubmitting || isLoading}
              // isValid={isValid}
              onCancel={() => navigate(-1)}
              onSubmit={() => viewOrder()}
              onNotesChange={
                (value: string) => {
                  setNewOrder({ ...newOrder, notes: value });
                  handleChange("notes", value);
                }
                //  setOrder({ ...order, notes: value })
              }
            />
          ) : null}

          {/* Modal */}
          <SubmitModal
            showModal={modalVisible}
            loading={isSubmitting}
            dismiss={() => {
              setShowPrompt(true);
              setModalVisible(false);
            }}
            submit={() => {
              setShowPrompt(false);
              submitOrder();
            }}
            orderView={orderView}
          />
        </>
      )}
      <OrderMessage
        showModal={showMessage}
        dismiss={() => setShowMessage(false)}
      />
      <ConfirmNavigation isBlocked={showPrompt} />
    </>
  );
};

export default NewOrder;

import React from "react";
import { CatalogType } from "../../../../utils/types";
import Icon from "../../../../components/ui/Buttons/Icon";

interface CatalogTabProps extends CatalogType {
  isActive?: boolean;
  onSelect?: (id: string) => void;
}
const CatalogTab = ({
  id,
  image,
  title,
  isActive,
  onSelect,
}: CatalogTabProps) => {
  return (
    <div
      onClick={() => onSelect && onSelect(id)}
      //   to={`/Catalog/${id}`}
      key={id}
      className={`flex cursor-pointer p-2 items-center flex-nowrap gap-2 rounded-lg ${
        isActive ? "border-2 border-primaryGreen" : ""
      }  flex-1`}
    >
      <Icon>
        <img
          loading="lazy"
          src={image}
          className="w-full object-contain"
          alt={title}
        />
      </Icon>
      <span className={`text-sm ${isActive ? "font-semibold" : ""}`}>
        {title}
      </span>
    </div>
  );
};

export default CatalogTab;

import * as React from "react";
import { CgSpinner } from "react-icons/cg";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  text: string;
  icon?: React.ReactNode;
  loading?: boolean;
  isReversed?: boolean;
  variant?: "primary" | "secondary" | "disabled";
}
const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  icon,
  variant = "primary",
  loading,
  isReversed,
  ...rest
}) => {
  let buttonClassName =
    "transition-all duration-300 ease-out flex gap-2 items-center justify-center self-center px-5 py-3 my-2 text-base font-medium rounded-full";

  switch (variant) {
    case "primary":
      buttonClassName +=
        " text-white bg-primaryGreen border border-primaryGreen hover:bg-transparent hover:text-primaryGreen";
      break;
    case "secondary":
      buttonClassName +=
        " text-primaryGreen border border-primaryGreen bg-transparent hover:bg-primaryGreen/30  hover:border-transparent";
      break;
    case "disabled":
      buttonClassName +=
        " text-neutral-300 bg-neutral-100 border border-neutral-200 cursor-not-allowed";
      break;
    default:
      break;
  }
  const classes = `${buttonClassName} ${rest?.className} ${
    isReversed ? "flex-row-reverse" : "flex"
  }`;
  return (
    <button
      {...rest}
      className={classes}
      onClick={onClick}
      disabled={variant === "disabled" || loading}
    >
      {loading ? (
        <CgSpinner className="animate-spin w-5 h-5" />
      ) : (
        <>
          {/* <span className={icon ? "max-md:hidden" : ""}>{text}</span> */}
          <span>{text}</span>
          {/* <span className="max-md:hidden">{text}</span> */}
          {icon && <span>{icon}</span>}
        </>
      )}
    </button>
  );
};

export default Button;

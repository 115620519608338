import logoPrimary from "../assets/images/LogoPrimary.webp";
import logoWhite from "../assets/images/LogoWhite.webp";
import LogoBadge from "../assets/images/Logobadge.webp";
import heroSection from "../assets/images/hero-section.webp";
import intro_card1 from "../assets/images/card1.webp";
import intro_card2 from "../assets/images/card2.webp";
import intro_card3 from "../assets/images/card3.webp";
import farmer from "../assets/images/farmer.webp";
import plant1 from "../assets/images/plant1.webp";
import plant2 from "../assets/images/plant2.webp";
import slider from "../assets/images/slider.webp";
import contactus from "../assets/images/contactus.webp";
import about1 from "../assets/images/about_farmer.webp";
import about2 from "../assets/images/about_forest.webp";
import about3 from "../assets/images/about_plant.webp";
import about4 from "../assets/images/about_land_tool.webp";
import auth from "../assets/images/auth.webp";
import flowerIcon from "../assets/icons/flower.svg";
import plantIcon from "../assets/icons/plant.svg";
import blossomIcon from "../assets/icons/blossom.svg";
import fillersIcon from "../assets/icons/fillers.svg";
import gerberaIcon from "../assets/icons/gerbera.svg";
import floweringPlantsIcon from "../assets/icons/flowering_plants.svg";
import decorativeGreeneryIcon from "../assets/icons/decorative_greenery.svg";
import MissionIcon from "../assets/icons/MissionIcon.svg";
import VisionIcon from "../assets/icons/VisionIcon.svg";
import Empty from "../assets/icons/Empty.svg";
import EmptyFiles from "../assets/icons/Empty_files.svg";
import infoMessage from "../assets/icons/message.svg";
import DefaultImg from "../assets/images/defaultImg.webp";
import Jory from "../assets/images/jory.webp";
import UltraFlo from "../assets/images/ultra_flow.png";
import dolina_img1 from "../assets/images/dolina_img1.webp";
import dolina_img2 from "../assets/images/dolina_img2.webp";
import dolina_img3 from "../assets/images/dolina_img3.webp";
import dolina_img4 from "../assets/images/dolina_img4.webp";
import dolina_img5 from "../assets/images/dolina_img5.webp";
import dolina_img6 from "../assets/images/dolina_img6.webp";
import dolina_img7 from "../assets/images/dolina_img7.webp";
import dolina_img8 from "../assets/images/dolina_img8.webp";
import dolina_img9 from "../assets/images/dolina_img9.webp";
import dolina_img10 from "../assets/images/dolina_img10.webp";
import dolina_img11 from "../assets/images/dolina_img11.webp";
import dolina_img12 from "../assets/images/dolina_img12.webp";
import dolina_img13 from "../assets/images/dolina_img13.webp";
import dolina_img14 from "../assets/images/dolina_img14.webp";
import dolina_img15 from "../assets/images/dolina_img15.webp";
import dolina_img16 from "../assets/images/dolina_img16.webp";
import dolina_img17 from "../assets/images/dolina_img17.webp";
import dolina_img18 from "../assets/images/dolina_img18.webp";
import dolina_img19 from "../assets/images/dolina_img19.webp";
import dolina_img20 from "../assets/images/dolina_img20.webp";
import dolina_img21 from "../assets/images/dolina_img21.webp";
import dolina_img22 from "../assets/images/dolina_img22.webp";
import dolina_img23 from "../assets/images/dolina_img23.webp";
import dolina_img24 from "../assets/images/dolina_img24.webp";
import dolina_img25 from "../assets/images/dolina_img25.webp";
import dolina_img26 from "../assets/images/dolina_img26.webp";
import dolina_img27 from "../assets/images/dolina_img27.webp";
import dolina_img28 from "../assets/images/dolina_img28.webp";
const images = {
  UltraFlo,
  infoMessage,
  Jory,
  DefaultImg,
  Empty,
  EmptyFiles,
  heroSection,
  intro_card1,
  intro_card2,
  intro_card3,
  flowerIcon,
  plantIcon,
  farmer,
  plant1,
  plant2,
  blossomIcon,
  fillersIcon,
  gerberaIcon,
  floweringPlantsIcon,
  decorativeGreeneryIcon,
  slider,
  contactus,
  logoPrimary,
  logoWhite,
  LogoBadge,
  about1,
  about2,
  about3,
  about4,
  auth,
  MissionIcon,
  VisionIcon,
  dolina_img1,
  dolina_img2,
  dolina_img3,
  dolina_img4,
  dolina_img5,
  dolina_img6,
  dolina_img7,
  dolina_img8,
  dolina_img9,
  dolina_img10,
  dolina_img11,
  dolina_img12,
  dolina_img13,
  dolina_img14,
  dolina_img15,
  dolina_img16,
  dolina_img17,
  dolina_img18,
  dolina_img19,
  dolina_img20,
  dolina_img21,
  dolina_img22,
  dolina_img23,
  dolina_img24,
  dolina_img25,
  dolina_img26,
  dolina_img27,
  dolina_img28,
};
export default images;

import React, { useContext, useEffect, useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import { GetPackings } from "../../../../modules/orders/service";
import { PackingsType } from "../../../../utils/types";
import SelectSearch from "../../../../components/ui/Inputs/SelectSearch";
import { EditableContext } from "./EditProductRow";
import { Form } from "antd";

const PackingsInput = ({
  onChange,
  disabled,
  isPackedByProduct,
  isHasProperties,
}: {
  onChange: () => Promise<void>;
  disabled?: boolean;
  isPackedByProduct?: boolean;
  isHasProperties?: boolean;
}) => {
  const { toastError } = useAlertsContext();
  const [packings, setPackings] = useState<PackingsType[]>([]);
  const form = useContext(EditableContext)!;
  const properties = Form.useWatch("propertiesLs", form);
  const lengthOfProperties =
    (properties && Object.keys(properties)?.length) || -1;
  const packing = Form.useWatch("packing", form);
  const productId = Form.useWatch("product", form);
  const propertyValuesArray: string[] = properties
    ? (Object.values(properties)?.filter(
        (value) => value !== undefined
      ) as string[])
    : [];
  const PropertiesLength = form.getFieldValue("PropertiesLength");

  const FetchPackings = async () => {
    setPackings([]);
    const isProductAvailable =
      (productId?.value &&
        lengthOfProperties === PropertiesLength &&
        properties &&
        isHasProperties &&
        propertyValuesArray?.length === lengthOfProperties) ||
      (productId?.value && !isHasProperties);
    if (isProductAvailable) {
      await GetPackings(productId?.value, propertyValuesArray)
        .then((data) => {
          setPackings(data);
        })
        .catch((e) => {
          toastError(e?.data);
        });
    }
  };
  useEffect(() => {
    FetchPackings();
  }, [productId, properties]);

  const options =
    packings?.map((options: PackingsType) => {
      return { label: options?.amount, value: options?.id };
    }) || [];

  return (
    <SelectSearch
      className="text-lg max-w-[8rem] rounded-lg border border-gray-300"
      placeholder={"Select a packing"}
      onChange={onChange}
      labelInValue={true}
      value={packing?.value}
      disabled={
        disabled ||
        !productId ||
        (!isPackedByProduct && !propertyValuesArray?.length)
      }
      // defaultValue={undefined}
      options={options}
    />
  );
};

export default PackingsInput;

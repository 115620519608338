import React from "react";
import Section from "../layouts/Section";
import ArrowIcon from "../components/ui/Buttons/ArrowIcon";
import { GrHome } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import Icon from "../components/ui/Buttons/Icon";
import { LinkToTop } from "../components/Link";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import SEO from "../layouts/SEO";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Section id="notfound" className="flex items-center justify-center" isPage>
      <SEO title={"Not Found | Dolina Agro"} />
      <div className="flex flex-col">
        {/* <!-- Notes --> */}
        {/* <span className="text-center font-bold my-10 opacity-30">
            MDI (npm i @mdi/font) reuqired for all icons

            <hr className="my-4" />

            <a href="https://egoistdeveloper.github.io/twcss-to-sass-playground/" target="_blank" className="text-blue-600">
                Convetert to SASS
            </a>
        </span> */}

        {/* <!-- Error Container --> */}
        <div className="flex flex-col items-center">
          <div className="text-primaryGreen font-bold text-7xl">404</div>

          <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
            This page does not exist
          </div>

          <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            The page you are looking for could not be found.
          </div>
          <Button
            text={"Go Back"}
            onClick={() => navigate(-1)}
            className="justify-center items-center !px-12 !py-3 mt-8 !font-black text-white bg-primaryGreen !max-md:px-5"
          />
        </div>

        {/* <!-- Continue With --> */}
        <div className="flex flex-col mt-24">
          <div className="text-gray-400 font-bold uppercase">Continue With</div>

          <div className="flex flex-col items-stretch mt-5">
            {/* <!-- Nav Item #1 --> */}
            <LinkToTop
              to="/"
              replace={true}
              className="flex flex-row group px-4 py-8
                    border-t hover:cursor-pointer
                    transition-all duration-200 delay-100"
            >
              {/* <!-- Nav Icon --> */}
              <Icon>
                <GrHome />
              </Icon>

              {/* <!-- Text --> */}
              <div className="grow flex flex-col pl-5">
                <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  Home Page
                </div>

                <div
                  className="font-semibold text-sm md:text-md lg:text-lg
                            text-gray-400 group-hover:text-gray-500
                            transition-all duration-200 delay-100"
                >
                  Everything starts here
                </div>
              </div>

              {/* <!-- Chevron --> */}
              <ArrowIcon />
            </LinkToTop>

            {/* <!-- Nav Item #3 --> */}
            <LinkToTop
              to="/Contact"
              replace={true}
              className="flex flex-row group px-4 py-8
                    border-t hover:cursor-pointer
                    transition-all duration-200 delay-100"
            >
              {/* <!-- Nav Icon --> */}
              <Icon>
                <FiPhoneCall />
              </Icon>

              {/* <!-- Text --> */}
              <div className="grow flex flex-col pl-5">
                <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  Contact
                </div>

                <div
                  className="font-semibold text-sm md:text-md lg:text-lg
                            text-gray-400 group-hover:text-gray-500
                            transition-all duration-200 delay-100"
                >
                  Contact us for your questions
                </div>
              </div>

              {/* <!-- Chevron --> */}
              <ArrowIcon />
            </LinkToTop>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default NotFound;

import { useEffect, useCallback } from "react";
import { BlockerFunction, useBlocker } from "react-router-dom";
import UnsavedChanges from "../features/orders/components/UnsavedChanges";

const title = "Are you sure you want to leave this page?";
const subTitle =
  "Maybe you have unsaved changes that will be lost if you leave this page";

const handleRouteChange = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = title;
  return title;
};

type Props = {
  isBlocked: boolean;
};

/**
 * Component provides a reusable way to handle confirmation of leaving a page
 * without saving changes and displays a modal for the user to confirm their decision.
 *
 * @param isBlocked - boolean flag to determine if navigation should be blocked
 *
 * @returns - Modal Component
 */

const ConfirmNavigation = ({ isBlocked }: Props) => {
  // Allow the submission navigation to the same route to go through
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isBlocked && currentLocation.pathname !== nextLocation.pathname,
    [isBlocked]
  );
  const blocker = useBlocker(shouldBlock);
  const isBlockedState = blocker.state === "blocked";
  const isProceedingState = blocker.state === "proceeding";

  useEffect(() => {
    if (isBlockedState && !isBlocked) {
      window.removeEventListener("beforeunload", handleRouteChange);
      blocker.reset?.();
    } else {
      window.addEventListener("beforeunload", handleRouteChange);
    }

    return () => {
      window.removeEventListener("beforeunload", handleRouteChange);
    };
  }, [blocker, isBlocked]);

  const handleSubmit = () => blocker.proceed?.();
  const handleCancel = () => blocker.reset?.();
  return (
    <UnsavedChanges
      showModal={isBlockedState || isProceedingState}
      title={title}
      subTitle={subTitle}
      dismiss={handleCancel}
      confirm={handleSubmit}
    />
  );
};

export default ConfirmNavigation;

import React, { useContext } from "react";
import {
  ProductSelectionType,
  ProductType,
  SelectionType,
  TruckType,
} from "../../../../utils/types";
import { EditableContext } from "./EditProductRow";
import { Form, InputNumber, Tooltip } from "antd";
import SelectSearch from "../../../../components/ui/Inputs/SelectSearch";
import PropertiesInputs from "./PropertiesInputs";
import PackingsInput from "./PackingsInput";
import ProductInput from "./ProductInput";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  destination: any;
  isUsingTruck?: boolean;
  showError?: boolean;
  required?: boolean;
  editable: boolean;
  dataIndex: string;
  title: any;
  record: ProductType;
  index: number;
  children: React.ReactNode;
  handleSave: (record: ProductType) => void;
  handleRemove: (key: string) => void;
  marks: SelectionType[];
  products: ProductSelectionType[];
  trucks: TruckType[];
}
const EditProductCell: React.FC<EditableCellProps> = ({
  editable,
  dataIndex,
  title,
  record,
  index,
  children,
  handleSave,
  marks,
  trucks,
  products,
  required,
  isUsingTruck,
  destination,
  showError,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;
  const boxesCount = Form.useWatch("boxesCount", form);
  const packing = Form.useWatch("packing", form);
  const packingVal = Number(packing?.label || 0);
  const totalUnits = packingVal * boxesCount || 0;
  const productId = Form.useWatch("product", form);
  const selectedProduct = products?.find(
    (product) => product.id === productId?.value
  );
  const checkForm = async () => {
    try {
      await form.validateFields();
    } catch (errInfo: any) {
      // console.log({ errors: errInfo?.errorFields?.length, key: record.key });
    }
  };
  if (showError) {
    checkForm();
  }
  const save = async () => {
    try {
      const values = await form.validateFields({ validateOnly: true });
      // const values = await form.validateFields();
      // console.log("save", { ...values });
      handleSave({ ...record, ...values, errors: 0 });
    } catch (errInfo: any) {
      handleSave({ ...record, errors: errInfo?.errorFields?.length });
    }
  };
  const onProductChange = (value: any) => {
    form.setFieldsValue({
      product: value,
      properties: undefined,
      propertiesLs: undefined,
      packing: undefined,
    });
    save();
  };
  const onPropertiesChange = () => {
    form.setFieldsValue({ packing: undefined });
    save();
  };
  let inputNode, initialValue;
  switch (dataIndex) {
    case "mark":
      const marksOptions = marks.map((options: SelectionType) => ({
        label: options?.name,
        value: options?.id,
        key: options?.id,
      }));
      initialValue = record?.mark;
      inputNode = (
        <SelectSearch
          placeholder={"Select a mark"}
          disabled={record?.disabled}
          onChange={save}
          labelInValue={true}
          value={record?.mark?.id}
          // defaultValue={initialValue}
          options={marksOptions}
        />
      );
      break;
    case "truck":
      const trucksOptions = trucks.map((options: TruckType) => ({
        label: options?.value,
        value: options?.id,
        key: options?.id,
      }));
      setTimeout(() => {
        if (!isUsingTruck) {
          form.setFieldsValue({ truck: undefined });
        }
      }, 1000);
      initialValue = record?.truck;
      const disabledText = !destination?.value
        ? "Choose a destination"
        : destination?.value && !isUsingTruck
        ? "Destination is not using truck"
        : "";
      inputNode = (
        <SelectSearch
          placeholder={"Select a truck"}
          disabled={record?.disabled || !isUsingTruck}
          onChange={save}
          labelInValue={true}
          hintText={disabledText}
          options={trucksOptions}
        />
      );
      break;
    case "product":
      const productsOptions = products.map((options: ProductSelectionType) => ({
        label: options?.name,
        value: options?.id,
        key: options?.id,
      }));
      initialValue = record?.product;
      inputNode = (
        <ProductInput
          disabled={record?.disabled}
          onChange={onProductChange}
          options={productsOptions}
          isHasProperties={selectedProduct?.hasProperties}
        />
      );
      break;
    case "properties":
      initialValue = record?.properties;
      inputNode = (
        <PropertiesInputs
          disabled={record?.disabled}
          onChange={onPropertiesChange}
          isHasProperties={selectedProduct?.hasProperties}
        />
      );
      break;
    case "packing":
      initialValue = record?.packing;
      inputNode = (
        <PackingsInput
          disabled={record?.disabled}
          onChange={save}
          isPackedByProduct={selectedProduct?.isPackedByProduct}
          isHasProperties={selectedProduct?.hasProperties}
        />
      );
      break;
    case "boxesCount":
      initialValue = record?.boxesCount;
      inputNode = (
        <InputNumber
          className="h-[2.5rem] place-content-center"
          disabled={record?.disabled}
          min={1}
          onChange={save}
        />
      );
      break;
    case "total":
      inputNode = <span className="font-bold">{totalUnits}</span>;
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editable ? (
        <Form.Item
          name={dataIndex}
          initialValue={initialValue}
          rules={[
            {
              required,
              message: `${title} required!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        <div>{children}</div>
      )}
    </td>
  );
};
export default EditProductCell;

import * as React from "react";
import Section from "../../layouts/Section";
import Button from "../../components/Button";
import images from "../../data/images";
import { useNavigateToTop } from "../../hooks/useNavigateToTop";
import { Link } from "react-router-dom";

interface ImageCardProps {
  imageSrc?: string;
  title?: string;
  description?: string;
}

const ImageCard: React.FC<ImageCardProps> = ({
  imageSrc,
  title,
  description,
}) => {
  return (
    <div className="flex flex-col grow w-full bg-green-600 rounded-lg max-md:mt-6 justify-between">
      <img
        src={imageSrc}
        alt={title}
        className="w-full object-cover rounded-lg"
      />
      <div className="flex flex-col px-5 py-2.5 mt-3.5">
        <h2 className="text-2xl font-bold text-white">{title}</h2>
        <p className="mt-4 text-base font-light leading-5 text-gray-100 capitalize">
          {description}
        </p>
      </div>
    </div>
  );
};

interface IconCardProps {
  iconSrc?: string;
  iconItem?: React.ReactNode;
  title?: string;
  description?: string;
  ButtonItem?: React.ReactNode;
}

const IconCard: React.FC<IconCardProps> = ({
  iconSrc,
  iconItem,
  title,
  description,
  ButtonItem,
}) => {
  return (
    <div className="flex flex-col grow justify-center w-full text-center rounded-lg max-md:mt-6  bg-gradient-to-bl from-transparent via-secondaryGreen/15 to-transparent">
      <div className="flex flex-col justify-center px-5 py-2.5">
        {iconItem ? iconItem : null}
        {iconSrc ? (
          <img src={iconSrc} alt={title} className="self-center" />
        ) : null}
        <h2 className="mt-4 text-2xl font-bold whitespace-pre-line">{title}</h2>
        <p className="mt-4 text-base font-light leading-5 capitalize text-gray-800">
          {description}
        </p>
        {ButtonItem ? ButtonItem : null}
      </div>
    </div>
  );
};

function Intro() {
  const navigateToTop = useNavigateToTop();
  const cardData = [
    {
      iconSrc: undefined,
      imageSrc: images.dolina_img18,
      title: "Growing with LOVE",
      // description:
      //   "There are many variation of passages of lorem ipsum available, but the majority have suffered alteration in some forms, by injected humamour, or randomised words which dont’ look",
    },
    {
      imageSrc: undefined,
      iconItem: (
        <Link
          to={"http://www.ultraflo.biz/en/"}
          target="_blank"
          referrerPolicy="no-referrer"
          className="flex justify-center items-center"
        >
          <img
            src={images.UltraFlo}
            alt={"ULTRA FLO"}
            className="self-center"
          />
        </Link>
      ),
      title: "Our Partner in Kenya \n Export of fresh cut Roses from Kenya",
      description: "",
      ButtonItem: (
        <Link
          to={"http://www.ultraflo.biz/en/"}
          target="_blank"
          referrerPolicy="no-referrer"
          className="flex justify-center items-center"
        >
          <Button
            text={"Visit"}
            // onClick={() => navigateToTop("http://www.ultraflo.biz/en/")}
            className={"mt-4 w-[30%] md:w-[50%]"}
          />
        </Link>
      ),
    },
    // {
    //   imageSrc: undefined,
    //   iconSrc: images.plantIcon,
    //   title: "Natural Farming",
    //   description:
    //     "There are many variation of passages of lorem ipsum available, but the majority have suffered alteration in some forms, by injected humamour, or randomised words which dont' look",
    // },
    {
      iconSrc: undefined,
      imageSrc: images.dolina_img3,
    },
    {
      iconSrc: undefined,
      imageSrc: images.dolina_img12,
    },
    {
      imageSrc: undefined,
      iconSrc: images.flowerIcon,
      title: "Packing with CARE",
      // description:
      //   "There are many variation of passages of lorem ipsum available, but the majority have suffered alteration in some forms, by injected humamour, or randomised words which dont’ look",
      ButtonItem: (
        <Button
          text={"Show More"}
          onClick={() => navigateToTop("/Catalog")}
          className={"mt-4"}
        />
      ),
    },
  ];
  return (
    <Section id="intro" className="w-full box">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full">
          <ImageCard
            imageSrc={cardData[0].imageSrc}
            title={cardData[0].title}
            description={cardData[0].description}
          />
        </div>
        <div className="flex flex-col ml-5 w-2/3 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-6">
            <div className="max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <IconCard {...cardData[1]} />
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <img
                    src={cardData[2].imageSrc}
                    alt={cardData[2].title}
                    className="grow w-full aspect-[1.3] max-md:mt-6 rounded-lg object-cover"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex gap-5 max-md:flex-col-reverse max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <img
                    src={cardData[3].imageSrc}
                    alt={cardData[3].title}
                    className="grow w-full aspect-[1.3] max-md:mt-6 rounded-lg object-cover"
                  />
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <IconCard {...cardData[4]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Intro;

import BaseService from "../../services/BaseService";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import {
  AllDTFilter,
  DestinationType,
  EditOrderType,
  NewOrderType,
  OrderType,
  PackingsType,
  ProductSelectionType,
  RequestMethod,
  SelectionType,
  TruckType,
} from "../../utils/types";

export const GetAvails = async (requestBody: AllDTFilter) =>
  await BaseService(API_ENDPOINTS.GET_AVAILS, RequestMethod.POST, requestBody);

export const GetOrderView = async (data: NewOrderType): Promise<OrderType> =>
  await BaseService(API_ENDPOINTS.GET_ORDER_VIEW, RequestMethod.POST, data);

export const CreateOrderPDF = async (data: NewOrderType) =>
  await BaseService(API_ENDPOINTS.CREATE_ORDER_PDF, RequestMethod.POST, data);

export const CreateOrder = async (data: NewOrderType) =>
  await BaseService(API_ENDPOINTS.CREATE_ORDER, RequestMethod.POST, data);

export const EditOrder = async (data: EditOrderType) =>
  await BaseService(API_ENDPOINTS.EDIT_ORDER, RequestMethod.POST, data);

export const GetOrders = async (requestBody: AllDTFilter) =>
  await BaseService(API_ENDPOINTS.GET_ORDERS, RequestMethod.POST, requestBody);

export const GetOrderById = async (id: string): Promise<OrderType> =>
  await BaseService(
    `${API_ENDPOINTS.GET_ORDER_BY_ID}/${id}`,
    RequestMethod.GET
  );
export const ValidateNewOrder = async (
  data: NewOrderType
): Promise<OrderType> =>
  await BaseService(API_ENDPOINTS.VALIDATE_ORDER, RequestMethod.POST, data);

export const GetShipments = async (): Promise<SelectionType[]> =>
  await BaseService(API_ENDPOINTS.GET_SHIPMENTS, RequestMethod.POST, {});

export const GetTrucks = async (): Promise<TruckType[]> =>
  await BaseService(API_ENDPOINTS.GET_TRUCKS, RequestMethod.POST, {});

export const GetProductById = async (id: string) =>
  await BaseService(
    `${API_ENDPOINTS.GET_PRODUCT_BY_ID}/${id}`,
    RequestMethod.GET
  );

export const GetProductsSelection = async (): Promise<ProductSelectionType[]> =>
  await BaseService(
    API_ENDPOINTS.GET_PRODUCTS_SELECTION,
    RequestMethod.POST,
    {}
  );

export const GetPackings = async (
  productId: string,
  properties: string[]
): Promise<PackingsType[]> =>
  await BaseService(API_ENDPOINTS.GET_PACKINGS, RequestMethod.POST, {
    productId,
    properties,
  });
export const IsProductAvailable = async (
  productId: string,
  properties: string[]
): Promise<boolean> =>
  await BaseService(API_ENDPOINTS.IS_PRODUCT_AVAILABLE, RequestMethod.POST, {
    productId,
    properties,
  });

export const GetDestinations = async (): Promise<DestinationType[]> =>
  await BaseService(API_ENDPOINTS.GET_DESTINATIONS, RequestMethod.POST, {});

export const GetMarks = async (): Promise<SelectionType[]> =>
  await BaseService(API_ENDPOINTS.GET_MARKS, RequestMethod.POST, {});

export const DownloadAvailsExcel = async (
  searchTerm: string,
  destinationId?: string
) =>
  await BaseService(
    API_ENDPOINTS.AVAILS_EXPORT_EXCEL,
    RequestMethod.POST,
    {
      searchTerm,
      destinationId,
    },
    null,
    undefined,
    "blob",
    true
  );

import { Popconfirm } from "antd";
import React, { useRef } from "react";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { IoMdRemoveCircleOutline } from "react-icons/io";

const RemoveProduct = ({ handleRemove }: { handleRemove: () => void }) => {
  const inputRef = useRef(null);

  return (
    <Popconfirm
      title="Row Delete"
      description="Are you sure to delete this row?"
      icon={<FaRegCircleQuestion className="text-red-600 mr-2 text-lg" />}
      onConfirm={handleRemove}
      okText="Delete"
      cancelText="No"
    >
      <div ref={inputRef} className="mt-4">
        <IoMdRemoveCircleOutline
          size={20}
          className="text-red-600 cursor-pointer"
          // onClick={removeRow}
        />
      </div>
    </Popconfirm>
  );
};

export default RemoveProduct;

import React from "react";
import Section from "../../layouts/Section";
import PricesForm from "./Components/PricesForm";
import PageTitle from "../../components/PageTitle";

const Prices: React.FC = () => {
  return (
    <Section id="Contact" isPage>
      <div className="flex flex-col py-px max-w-full">
        <header className="flex w-full ">
          <PageTitle
            title="Prices"
            subTitle="To receive a price list, please fill out the form:"
          />
        </header>
        <main>
          <PricesForm />
        </main>
      </div>
    </Section>
  );
};

export default Prices;

import React, { useEffect, useState } from "react";
import Section from "../layouts/Section";
import SectionBadge from "../components/SectionBadge";
import ArrowIcon from "../components/ui/Buttons/ArrowIcon";
import { GetProducts } from "../modules/common/service";
import { Product } from "../utils/types";
import images from "../data/images";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import SEO from "../layouts/SEO";

const PAGE_SIZE = 48;

const Products = () => {
  // const location = useLocation();
  // const { state } = location;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const viewProduct = (id: string) => {
    navigate(id, {
      state: {
        currentPage,
        loadingMore,
        products,
      },
    });
    window.scrollTo(0, 0);
  };
  const handleLoadMore = () => {
    if (loadingMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setLoadingMore(true);
      FetchProducts(nextPage);
    }
  };
  const FetchProducts = async (page = 1) => {
    const filters = {
      start: page ? (page - 1) * PAGE_SIZE : 0,
      length: PAGE_SIZE,
    };
    await GetProducts(filters)
      .then((response) => {
        setProducts((prevState) =>
          page === 1
            ? response?.data
            : [...prevState, ...(response?.data || [])]
        );
        setLoadingMore(response?.data?.length === PAGE_SIZE);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FetchProducts();
    return () => {
      setProducts([]);
    };
  }, []);

  // const greens = {
  //   image:
  //     "https://cdn.builder.io/api/v1/image/assets/TEMP/9dbc5ea4589dc779f5705267d3449ebfc8433c63afeb5d200a003cdb0e324391?apiKey=74642dce5e844322a47c44a2b4216260&",
  //   name: "Basil - Базилик",
  //   description:
  //     "a plant that is very popular among culinary experts and is synonymous with Mediterranean cuisine. When it comes to basil, it is impossible not to imagine the aroma of Pesto sauce, which will instantly transport you to the shores of the Mediterranean Sea. Its warm, spicy flavor goes well with a variety of soups, casseroles and pastas, and it is an important ingredient for cooking tomatoes, eggplant and zucchini. Just one basil leaf in a fresh salad is enough and the dish acquires a unique, memorable taste.",
  // };
  return (
    <Section id="Catalog" isPage>
      <SEO title={"Catalog | Dolina Agro"} />
      <SectionBadge text="Our Catalog" highlightedText="Catalog" />
      <div className="flex flex-wrap gap-4 max-md:gap-2 ">
        {isLoading
          ? Array(8)
              .fill({})
              ?.map((_, index) => (
                <div
                  className="animate-pulse w-[20%] max-md:w-[38%] h-[15rem] m-4"
                  key={index}
                >
                  <div className="flex-shrink-0 bg-gray-350 w-full h-[80%] rounded-xl">
                    <span className="block "></span>
                  </div>

                  <div className="ms-4 mt-2 w-full">
                    <ul className="mt-5 space-y-3">
                      <li className="w-full h-4 bg-gray-200 rounded-full"></li>
                      <li className="w-full h-4 bg-gray-200 rounded-full"></li>
                    </ul>
                  </div>
                </div>
              ))
          : products?.map((plant, index) => (
              <div
                onClick={() => viewProduct(`${plant.id}`)}
                key={index}
                className="flex cursor-pointer flex-col w-[24%] md:w-[23%] mt-4 max-md:w-[48%] capitalize  bg-white rounded-xl border border-neutral-50"
              >
                <div className="relative">
                  <img
                    loading="lazy"
                    src={plant?.image || images.DefaultImg}
                    alt={plant?.name}
                    className="w-full object-cover rounded-xl aspect-square"
                  />
                  <ArrowIcon className={"absolute bottom-4 right-4"} />
                  {/* <Button
                  text={"View"}
                  // variant="secondary"
                  onClick={() => console.log("show more")}
                  className={"absolute bottom-4 right-4 text-xs"}
                  icon={<FaArrowRightLong />}
                /> */}
                </div>
                <div className="flex flex-col  min-w-0 p-4 max-md:p-2">
                  <h3 className="text-base font-bold text-primaryGreen">
                    {plant.name}
                  </h3>
                  <p className="mt-4 truncate text-base min-w-0 text-gray-800">
                    {plant.description}
                  </p>
                </div>
              </div>
            ))}
      </div>
      {loadingMore ? (
        <div className="flex items-center justify-center">
          <Button
            text={"Load More..."}
            variant={"secondary"}
            loading={isLoading}
            onClick={() => handleLoadMore()}
            className="!px-12 !py-3 mt-8 !max-md:px-5 w-[38%] max-md:!w-[80%] rounded-lg"
          />
        </div>
      ) : null}
    </Section>
  );
};

export default Products;

import React from "react";
type Props = {
  productsLength?: number;
  destinationInput: React.ReactNode;
  shippingInput: React.ReactNode;
};
const EditHeader = ({
  productsLength,
  destinationInput,
  shippingInput,
}: Props) => {
  return (
    <div className="flex max-lg:flex-col-reverse justify-between items-end max-lg:items-start my-8 border-b border-b-gray-350 pb-2">
      <h1 className="text-xl font-bold max-lg:mt-4">{`${
        productsLength ? `(${productsLength})` : ""
      } Products`}</h1>
      <div className="flex flex-wrap gap-4 items-end justify-end max-md:justify-start">
        <h1 className="text-lg font-bold max-md:w-full">Shipping Details</h1>
        <div className="flex flex-col">
          <span className="text-gray-400 pl-2 text-sm font-bold">
            {"Destination"}
            <span className="text-red-700 text-lg">{"*"}</span>
          </span>
          {destinationInput}
        </div>
        <div className="flex flex-col">
          <span className="text-gray-400 pl-2 text-sm font-bold">
            {"Shipping Date"}
            <span className="text-red-700 text-lg">{"*"}</span>
          </span>
          {shippingInput}
        </div>
      </div>
    </div>
  );
};

export default EditHeader;

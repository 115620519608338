import * as React from "react";
import NavItem from "../components/ui/Navbar/NavItem";
import CTA from "../components/ui/Navbar/CTA";
import Icon from "../components/ui/Buttons/Icon";
import images from "../data/images";
import { useMediaQuery } from "react-responsive";

import "../styles/Navbar.css";
import { LinkToTop } from "../components/Link";
import { PiUserBold } from "react-icons/pi";
import AuthSelectors from "../modules/auth/selectors";
const navItems = [
  { text: "Home", to: "/" },
  { text: "About Us", to: "/AboutUs" },
  // { text: "Products", to: "/Products" },
  { text: "Catalog", to: "/Catalog" },
  // { text: "Prices", to: "/Prices" },
  { text: "Contact", to: "/Contact" },
];

const Navbar: React.FC = () => {
  const { isLoggedIn } = AuthSelectors();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: "768px" });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMobileMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <div className="box-border flex flex-col shrink-0 w-full fixed top-0 self-center z-40 items-center justify-center">
      <header className="flex z-10 relative gap-5 justify-between items-center px-16 py-4 w-full bg-white max-md:flex-wrap max-md:px-5">
        <div
          className={`hamburger ${isMenuOpen ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <span className={"bar"}></span>
          <span className={"bar"}></span>
          <span className={"bar"}></span>
        </div>
        <LinkToTop to="/">
          <img
            src={images.logoPrimary}
            alt="Company Logo"
            className="w-[8rem]"
          />
        </LinkToTop>
        <nav
          className={`flex gap-5 justify-center my-auto text-l text-center text-gray-800 max-md:flex-wrap  ${
            isMenuOpen ? "navMenu" : "hidden"
          } ${isMobile ? "" : "!flex"} `}
        >
          {navItems.map((item) => (
            <NavItem
              key={item.text}
              text={item.text}
              to={item.to}
              onClick={closeMobileMenu}
            />
          ))}
          {/* <NavDropdown
            text="Catalog"
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/3a8ee0031ec7216e8c7218d2bec01214e09eda48cf5b53e34a2562f4715c6886?apiKey=74642dce5e844322a47c44a2b4216260&"
          /> */}
        </nav>
        {/* <div className="flex justify-center items-center p-3 my-auto rounded-lg bg-neutral-100"></div> */}
        {/* <Icon>
          <RiTranslate className="text-gray-800"/>
        </Icon> */}
        <LinkToTop
          to="/Profile"
          className={`flex gap-1.5 font-medium tracking-wide p-2 rounded-2xl text-body ${
            isMobile ? "" : "border-2 border-primaryGreen"
          }`}
        >
          <Icon dot={isLoggedIn ? true : false}>
            <PiUserBold />
          </Icon>
          {/* {!isMobile ? <div className="my-auto">New Order</div> : null} */}
        </LinkToTop>
        {/* <div className="border-b-1 border-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bottom-0 h-1"></div> */}
        <div className="absolute inset-x-0 bottom-0 h-1">
          <div className="h-full w-full bg-gradient-to-r from-white via-primaryGreen/50 to-white"></div>
        </div>
      </header>
      <CTA />
    </div>
  );
};

export default Navbar;

import * as React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaRegEnvelope,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FiPhone, FiYoutube } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { LinkToTop } from "../components/Link";
import images from "../data/images";
import { TbDeviceLandlinePhone } from "react-icons/tb";

const SocialMediaIcon = ({ src }: { src: React.ReactNode }) => (
  <LinkToTop
    to={"/"}
    className=" transition-all duration-300 ease-out flex px-2 justify-center items-center rounded-xl bg-neutral-100 hover:text-white border border-transparent hover:border hover:border-white hover:bg-transparent h-[36px] w-[36px]"
  >
    {src}
  </LinkToTop>
);

const socialMediaIcons: React.ReactNode[] = [
  <FaFacebookF />,
  <FaInstagram />,
  <FaLinkedinIn />,
  <BsTwitterX />,
  <FiYoutube />,
];

interface QuickLinkProps {
  label: string;
  to: string;
}

const QuickLink: React.FC<QuickLinkProps> = ({ label, to }) => (
  <LinkToTop
    to={to}
    className="mt-2 hover:font-bold transition-all duration-200 delay-100"
  >
    {label}
  </LinkToTop>
);

const quickLinks: QuickLinkProps[] = [
  { label: "Home", to: "/" },
  { label: "About Us", to: "/AboutUs" },
  { label: "Catalog", to: "/Catalog" },
  { label: "Contact", to: "/Contact" },
  { label: "Pricing", to: "/Prices" },
];

interface ContactInfoProps {
  icon: React.ReactNode;
  text: string;
  href?: string;
}
const contactInfo: ContactInfoProps[] = [
  {
    icon: <FaRegEnvelope />,
    text: "flowers@dolina-agro.co.il",
    href: "mailto:flowers@dolina-agro.co.il",
  },
  {
    icon: <GrLocation />,
    text: "10, Bareket, Industrial Park Caesarea, Israel, 3088900",
    href: "http://maps.google.com/?q=10, Bareket, Industrial Park Caesarea, Israel, 3088900",
  },
  {
    icon: <TbDeviceLandlinePhone />,
    text: "+972-4-6881241",
    href: "tel:+972-4-6881241",
  },
  {
    icon: <FiPhone />,
    text: "+972-54-4526049",
    href: "tel:+972-54-4526049",
  },
];
const ContactInfo: React.FC<ContactInfoProps> = ({ icon, text, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="flex gap-2 mt-4 items-center tracking-wide"
  >
    <div className="shrink-0 w-4 aspect-square ">{icon}</div>
    <div className="flex-1">{text}</div>
  </a>
);

function Footer() {
  return (
    <footer className="flex flex-col justify-center">
      <div className="flex flex-col justify-between items-center px-20 pt-20 pb-3.5 w-full bg-primaryBlack max-md:px-5">
        <div className="flex gap-5 justify-between items-start mt-4 w-full flex-wrap">
          <div className="flex flex-col grow-0 w-[14rem]">
            <img
              loading="lazy"
              src={images.logoWhite}
              alt="Company Logo"
              className="max-w-full aspect-[3.33] w-[177px]"
            />
            <p className="mt-4 text-base font-medium text-white">
              Working with us you will experience the aroma of success
            </p>
            <div className="flex gap-2.5 justify-between pr-20 mt-8 text-lg font-bold tracking-wide text-black max-md:pr-5">
              {socialMediaIcons.map((icon, index) => (
                <SocialMediaIcon key={index} src={icon} />
              ))}
            </div>
          </div>
          <nav className="flex flex-col  text-base font-light text-white w-[140px]">
            <h2 className="text-xl font-semibold">Quick Links</h2>
            {quickLinks.map((link, index) => (
              <QuickLink key={index} label={link.label} to={link.to} />
            ))}
          </nav>
          <div className="flex flex-col text-base font-medium text-white w-[290px]">
            <h2 className="text-xl font-semibold">Contact Us</h2>
            {contactInfo.map((info, index) => (
              <ContactInfo
                key={index}
                icon={info.icon}
                text={info.text}
                href={info.href}
              />
            ))}
          </div>
          <div className="flex flex-col flex-nowrap min-w-[290px]">
            {/* <img src={images.WhiteFlower} alt="white flower" className="h-52" /> */}
            {/* <h2 className="text-xl font-semibold text-white">Newsletter</h2>
            <form className="flex gap-0 mt-10 mb-0 grow-0 h-[60px] w-100">
              <label htmlFor="emailInput" className="sr-only">
                Enter your email
              </label>
              <input
                type="email"
                id="emailInput"
                placeholder="Enter your email"
                aria-label="Enter your email"
                className="w-[70%] flex-1 justify-center p-6 text-base font-medium bg-white text-gray-800 max-md:px-5 rounded-l-2xl"
              />
              <button
                type="submit"
                className="w-[30%] justify-center  text-l font-medium text-center text-white whitespace-nowrap rounded-r-2xl bg-primaryGreen"
              >
                Subscribe
              </button>
            </form> */}
          </div>
        </div>
        <div className="flex gap-5 justify-between px-2.5 mt-12 w-full border-t-[0.2px] pt-3 border-gray-400 max-md:flex-wrap max-md:mt-10">
          <div className="flex gap-2.5 items-center self-start text-white font-light">
            <LinkToTop to={"/Terms"} className="self-stretch my-auto">
              Terms And Condition
            </LinkToTop>
            <div className="w-[0.1px] bg-gray-400 border border-gray-400 border-solid h-[20px]" />
            <LinkToTop to={"/Policy"} className="self-stretch my-auto">
              Privacy Policy
            </LinkToTop>
          </div>
          <div className="font-regular text-white">
            Copyright © {new Date().getFullYear()} Dolina Agro. All rights
            reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

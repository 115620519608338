import { MouseEventHandler, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useNavigateToTop } from "../hooks/useNavigateToTop";

interface Props {
  children: ReactNode;
  className?: string;
  to: string;
  replace?: boolean;
  onClick?: any;
}

/** Link to the top of a page so that the scroll position isn't persisted between pages. Use this instead of React's build-in @see {@link Link}. */
export const LinkToTop = ({
  children,
  className,
  to,
  replace,
  onClick,
}: Props) => {
  const navigateToTop = useNavigateToTop();

  const navigateAndReset: MouseEventHandler<HTMLAnchorElement> = (event) => {
    // event.preventDefault();
    navigateToTop(to, replace);
    onClick && onClick();
  };

  return (
    <Link className={className} onClick={navigateAndReset} to={to}>
      {children}
    </Link>
  );
};

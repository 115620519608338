import React from "react";
import Section from "../layouts/Section";
import { HeroSection } from "./Home/HeroSection";
import Intro from "./Home/Intro";
import About from "./Home/About";
import ChooseUs from "./Home/ChooseUs";
import Catalog from "./Home/Catalog";
import SuccessMessage from "./Home/SuccessMessage";
import SEO from "../layouts/SEO";

const Home = () => {
  return (
    <Section id="home" className="w-full" isPage>
      <SEO
        title={"Dolina Agro | Цветы оптом"}
        description={
          "Группа Долина поставляет цветы оптом по всему миру, безупречный профессионализм, надежность и репутация"
        }
      />
      <HeroSection />
      <Intro />
      <About />
      <ChooseUs />
      <Catalog />
      <SuccessMessage />
      {/* <Contact /> */}
    </Section>
  );
};

export default Home;

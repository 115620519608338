import * as React from "react";
import Image from "../../components/ui/Image";
import Section from "../../layouts/Section";
import SectionBadge from "../../components/SectionBadge";
import { FaCheck } from "react-icons/fa6";
import SectionTitle from "../../components/SectionTitle";
import images from "../../data/images";
import ArrowIcon from "../../components/ui/Buttons/ArrowIcon";

interface FeatureProps {
  title: string;
  description: string;
}

const features: FeatureProps[] = [
  {
    title: "Quality Assurance",
    description:
      "At The Dolina Company, we pride ourselves on delivering only the highest quality floral products. With over three decades of experience and a team of skilled specialists overseeing every aspect, from cultivation to packaging, we guarantee that each bloom meets our stringent standards of excellence.",
  },
  // {
  //   title: "Global Access",
  //   description:
  //     "With branches in South America and Africa, we offer unparalleled access to premium floral sources from around the globe. Our extensive network enables us to deliver the freshest and most diverse selection of blooms, including roses from prestigious plantations in Ecuador, Colombia Kenya, and Ethiopia, ensuring that you have access to the finest flowers available.",
  // },
  {
    title: "Personalized Service",
    description:
      "We believe in an individualized approach to every customer, tailoring our services to meet your unique needs and preferences. Whether you're a small boutique florist or a large-scale event planner, our experienced team is dedicated to providing you with personalized attention, expert advice, and the most successful terms of partnership, ensuring a seamless and satisfying floral experience.",
  },
];

const Feature: React.FC<FeatureProps> = ({ title, description }) => (
  <div className="flex gap-4 mt-4 max-md:gap-2">
    {/* <div className="border border-primaryGreen text-primaryGreen w-[32px] h-[32px] rounded-lg flex items-center justify-center">
      <FaCheck />
    </div> */}
    <FaCheck className="text-primaryGreen" size={50} />
    <div className="px-4">
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="mt-2 text-base font-light text-gray-800">{description}</p>
    </div>
  </div>
);

const ChooseUs: React.FC = () => {
  return (
    <Section
      id="chooseUs"
      className="flex justify-center items-center flex-col w-full box"
    >
      <SectionBadge text="Why Choose Us?" highlightedText="Us?" />
      <SectionTitle
        title="Exporting "
        highlighted="Professionally"
        className="text-center"
      />
      <main className="mt-10 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full">
            <Image
              src={images.dolina_img21}
              alt="Farm product"
              className="w-full aspect-square object-cover grow max-md:mt-7 rounded-xl"
            />
          </div>
          <div className="flex flex-col justify-between p-2.5 max-md:w-full w-1/3 max-md:mt-7">
            <p className="text-lg font-light text-gray-800">
              Discover a wide range of high-quality farm products designed to
              enhance your agricultural endeavor. We take pride in offering
              innovative solutions that help you achieve optimal results in your
              farming operations. Weather you're a small-scale farmer or
              managing a large agricultural enterprise.
            </p>
            <div className="flex gap-2.5 justify-between mt-9 text-sm font-semibold text-center text-white">
              <div className="flex flex-col justify-center items-center p-2.5 bg-primaryGreen aspect-square rounded-3xl w-[50%]">
                <span>{"more than"}</span>
                <span className="text-lg font-bold">{"30 years"}</span>
                <span>{"of experience."}</span>
              </div>
              <div className="flex flex-col capitalize justify-center items-center p-2.5 bg-primaryGreen aspect-square rounded-3xl w-[50%]">
                <span className="text-lg font-bold">{"100%"}</span>
                <span>{"top quality growers"}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col ml-5 w-1/3 max-md:ml-0 max-md:w-full">
            <Image
              src={images.dolina_img16}
              alt="Farm product"
              className="w-full aspect-square object-cover grow max-md:mt-7 rounded-xl"
            />
          </div>
        </div>
      </main>
      <section className="mt-16 w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col justify-center grow mx-auto relative w-full bg-emerald-100 rounded-2xl max-md:mt-6">
              <ArrowIcon className="absolute right-4 top-4 " />
              <div className="px-5 py-2.5">
                <Image
                  src={images.blossomIcon}
                  alt="Farm icon"
                  className="max-w-full aspect-square w-28"
                />
                <h3 className="mt-4 text-2xl font-bold">Explore Our Farms</h3>
                <p className="mt-4 text-base font-light">
                  Discover a wide range of high-quality farms predesignated to
                  enhance your agricultural ended.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col ml-5 w-2/3 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow justify-center max-md:mt-6">
              {features.map((feature, index) => (
                <Feature key={index} {...feature} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Section>
  );
};

export default ChooseUs;

import * as React from "react";
import { useMediaQuery } from "react-responsive";

interface ImagesSliderProps {
  images: string[];
  className?: string;
}

const ImagesSlider = ({ images, className }: ImagesSliderProps) => {
  const isMobile = useMediaQuery({ maxWidth: "1024px" });
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [images]);
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(event.clientX);
  };
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const currentX = event.clientX;
    const diffX = currentX - startX;
    if (diffX > 50) {
      goToPrevious();
      setIsDragging(false);
    } else if (diffX < -50) {
      goToNext();
      setIsDragging(false);
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleMouseLeave = () => {
    setIsDragging(false);
  };
  const handleDragStart = (event: React.DragEvent<HTMLImageElement>) => {
    event.preventDefault();
  };
  return (
    <div
      className={`relative flex flex-1 ${className}`}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
    >
      {images.map((image, index) => (
        <img
          key={index}
          loading="lazy"
          src={image}
          alt={`Slide ${index + 1}`}
          className={`w-full h-full object-cover rounded-xl absolute transition-opacity duration-500 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
          onDragStart={handleDragStart}
        />
      ))}
      {!isMobile && images?.length > 1 ? (
        <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2">
          <div className="flex space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-1 h-1 rounded-full ${
                  index === currentIndex
                    ? "bg-white w-6"
                    : "bg-gray-300 bg-opacity-25 w-2"
                }`}
              ></button>
            ))}
          </div>
        </div>
      ) : null}

      {/* <button
        onClick={goToPrevious}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow"
      >
        &lt;
      </button>
      <button
        onClick={goToNext}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow"
      >
        &gt;
      </button> */}
    </div>
  );
};

export default ImagesSlider;

import { useNavigate } from "react-router-dom";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import * as Actions from "./types";
import { GetUser, LogIn, RefreshToken } from "./service";
import OrderActions from "../orders/actions";
import { clearToken, getToken, setToken } from "../../utils/get-token";

const AuthActions = () => {
  const { toastError } = useAlertsContext();
  const navigate = useNavigate();
  const { clearOrderArchive } = OrderActions();

  const login = (data) => {
    return async (dispatch) => {
      dispatch({ type: Actions.SIGN_IN_REQUEST });
      await LogIn(data)
        .then((response) => {
          setToken(response?.jwt, response?.refreshTokenExpiresAt);
          dispatch({ type: Actions.SIGN_IN_SUCCESS, payload: response });
          dispatch(clearOrderArchive());
          dispatch(handleUser(() => navigate("/Profile")));
        })
        .catch((e) => {
          console.error("LogIn", e);
          toastError(e?.data);
          dispatch({ type: Actions.SIGN_IN_ERROR });
        });
    };
  };

  const handleUser = (cb = () => {}) => {
    return async (dispatch) => {
      dispatch({ type: Actions.GET_USER_REQUEST });
      await GetUser()
        .then((response) => {
          // console.log("GetUser", response);
          dispatch({
            type: Actions.GET_USER_SUCCESS,
            payload: response,
          });
          cb && cb();
        })
        .catch((e) => {
          console.error("LogIn", e);
          toastError(e?.data);
          dispatch({ type: Actions.GET_USER_ERROR });
          // dispatch(handleRefreshToken());
        });
    };
  };
  const logout = () => {
    return async (dispatch) => {
      dispatch({ type: Actions.LOGOUT_SUCCESS, payload: {} });
      dispatch(clearOrderArchive());
      clearToken();
      navigate("/", { replace: true });
    };
  };
  const handleRefreshToken = () => {
    return async (dispatch) => {
      dispatch({ type: Actions.REFRESH_TOKEN_PENDING });
      const token = getToken() ?? "";
      await RefreshToken(token)
        .then((response) => {
          setToken(response?.token);
          dispatch({
            type: Actions.REFRESH_TOKEN_SUCCESS,
            payload: response,
          });
        })
        .catch((e) => {
          console.error("RefreshToken", e);
          toastError(e?.data || "Token Expired");
          dispatch(logout());
        });
    };
  };
  return {
    login,
    logout,
    handleUser,
    handleRefreshToken,
  };
};

export default AuthActions;

import React from "react";
import Button from "../../../../components/Button";
import { FiEdit } from "react-icons/fi";
type Props = {
  notes?: string;
  totalBoxAmount?: number;
  onCancel: Function;
  onSubmit: Function;
  loading: boolean;
  isValid?: boolean;
};
const EditFooter = ({
  notes,
  totalBoxAmount,
  onCancel,
  onSubmit,
  loading,
}: Props) => {
  return (
    <div className="flex gap-4 my-8 max-md:flex-col">
      <div className="w-2/3 max-md:w-full bg-neutral-50 rounded-lg p-4 ">
        <h1 className="">{notes}</h1>
      </div>
      <div className="w-1/3 max-md:w-full pt-2 border-t border-t-neutral-300">
        <div className="flex justify-between text-lg font-bold px-2">
          <span>Total</span>
          <span>{`${totalBoxAmount || "unassigned"} boxes`}</span>
        </div>
        <div className="flex flex-wrap max-md:gap-2 mt-4 justify-between text-lg font-bold px-2">
          <Button
            className="w-[60%] rounded-lg"
            isReversed
            onClick={() => onSubmit()}
            text={"Edit"}
            variant={loading ? "disabled" : "primary"}
            // variant={loading || !isValid ? "disabled" : "primary"}
            icon={<FiEdit />}
          />
          {/* TODO make dialog confirm */}
          <Button
            className="w-[35%] rounded-lg"
            isReversed
            onClick={() => onCancel()}
            variant={loading ? "disabled" : "secondary"}
            text={"Cancel"}
            // icon={<IoCloseOutline />}
          />
        </div>
      </div>
    </div>
  );
};

export default EditFooter;

import React from "react";
import ArrowIcon from "../../../../components/ui/Buttons/ArrowIcon";
import { CatalogType } from "../../../../utils/types";

const CatalogRow = ({ image, title, icon }: CatalogType) => {
  return (
    <div
      // to={`/Catalog/${id}`}
      className="transition-all duration-300 ease-out group flex gap-5 px-2.5 pb-4 mt-4 w-full border-b border-gray-100 border-solid max-md:flex-wrap max-md:max-w-full"
    >
      <div className="flex-1 max-md:max-w-full flex gap-5 items-end">
        <div className="flex lg:w-16 sm:w-4 rounded-full bg-gray-100 p-4">
          {icon ? icon : null}
          {image ? (
            <img
              loading="lazy"
              src={image}
              className="w-8 h-8 object-contain"
              alt={title}
            />
          ) : null}
        </div>
        <h3 className="text-3xl font-semibold">{title}</h3>
      </div>
      <div className="lg:opacity-0 group-hover:opacity-100 transition-opacity sm:opacity-100">
        <ArrowIcon />
      </div>
    </div>
  );
};

export default CatalogRow;

import { useAppSelector } from "../../hooks/useStore";

const OrderSelectors = (isNew = false) => {
  const isProductFilled = (product) => {
    const requiredFields = [
      "product",
      // "truck",
      "mark",
      "packing",
      "boxesCount",
      // "properties",
    ];
    return requiredFields.some(
      (field) =>
        product[field] !== undefined &&
        product[field] !== null &&
        product[field] !== ""
    );
  };
  const isOrderValid = (order) => {
    if (!order || !order?.products || !order?.products?.length) {
      return false;
    }
    return order?.products?.some(isProductFilled);
    // return true;
  };
  const excludeErrorProducts = (order) => {
    return {
      ...order,
      products: order?.products?.filter(
        (product) => !product?.errors || product?.errors === 0
      ),
    };
  };
  const orderValidSelector = useAppSelector((state) =>
    isOrderValid(isNew ? state.orders?.newOrder : state.orders?.order)
  );
  const orderSelector = useAppSelector((state) =>
    excludeErrorProducts(isNew ? state.orders?.newOrder : state.orders?.order)
  );
  const orderProducts = useAppSelector(
    (state) => state.orders?.newOrder?.products
  );
  const orderMappedProducts = useAppSelector((state) =>
    isNew
      ? state.orders?.newOrder?.mappedProducts
      : state.orders?.order?.mappedProducts
  );
  const orderSubmit = useAppSelector((state) =>
    isNew
      ? {
          shipmentId: state.orders?.newOrder?.shipmentId,
          destinationId: state.orders?.newOrder?.destination?.value,
          notes: state.orders?.newOrder?.notes,
          products: state.orders?.newOrder?.mappedProducts,
        }
      : {
          shipmentId: state.orders?.order?.shipmentId,
          destinationId: state.orders?.order?.destination?.value,
          notes: state.orders?.order?.notes,
          products: state.orders?.order?.mappedProducts,
        }
  );
  const errorsSelector = useAppSelector((state) => state.orders?.showErrors);
  return {
    orderSelector,
    orderProducts,
    orderMappedProducts,
    orderSubmit,
    orderValidSelector,
    errorsSelector,
  };
};

export default OrderSelectors;

import React from "react";
import ProfileTabs from "../Profile/components/ProfileTabs";
import PageTitle from "../../components/PageTitle";
import OrdersTable from "./components/Orders/OrdersTable";
import OrdersFilters from "./components/Orders/OrdersFilters";
import useOrders from "./hooks/useOrders";
import SEO from "../../layouts/SEO";

const Orders = () => {
  const {
    data,
    loading,
    hasSelected,
    tableParams,
    rowSelection,
    selectedRowKeys,
    searchTerm,
    setDateRange,
    handleSearch,
    handleTableChange,
    handleDownloadOrders,
  } = useOrders();
  return (
    <>
      <SEO title={"Orders | Dolina Agro"} />
      <ProfileTabs />
      <div className="px-4 mt-8 max-md:mt-10">
        <PageTitle title="Orders" />
        <OrdersFilters
          hasSelected={hasSelected}
          loading={loading}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          handleDownloadOrders={handleDownloadOrders}
          setDateRange={setDateRange}
        />
        <div className="gap-4 my-8">
          <OrdersTable
            data={data}
            loading={loading}
            hasSelected={hasSelected}
            tableParams={tableParams}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            handleTableChange={handleTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default Orders;

import React, { useContext, useEffect, useState } from "react";
import { GetProductById } from "../../../../modules/orders/service";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import { PropertyType, SelectionType } from "../../../../utils/types";
import { Form } from "antd";
import SelectSearch from "../../../../components/ui/Inputs/SelectSearch";
import { EditableContext } from "./EditProductRow";

type SelectedProperty = {
  name: string;
  id: string;
};
type Property = {
  id: string;
  name: string;
  values: SelectionType[];
};
const PropertiesInputs = ({
  onChange,
  disabled,
  isHasProperties,
}: {
  onChange: Function;
  disabled?: boolean;
  isHasProperties?: boolean;
}) => {
  const form = useContext(EditableContext)!;
  const product = Form.useWatch("product", form);
  const { toastError } = useAlertsContext();
  const [properties, setProperties] = useState<Property[]>([]);
  const propertiesWatch = Form.useWatch("properties", form);
  const propertiesMapped = propertiesWatch?.map(
    (property: PropertyType) => property.propertyValueId
  );
  const [selectedProperties, setSelectedProperties] = useState<
    SelectedProperty[]
  >([]);
  const GetProduct = async () => {
    setProperties([]);
    setSelectedProperties([]);
    if (product?.value && isHasProperties) {
      await GetProductById(product?.value)
        .then((product) => {
          // const isHasProperties = product?.properties?.length > 0;
          // form.setFieldsValue({
          //   hasProperties: isHasProperties,
          // });
          // onChange();
          form.setFieldValue("PropertiesLength", product?.properties?.length);
          setProperties(product?.properties);
          const options = product?.properties?.map((option: Property) => {
            return { name: option.name, id: option.id };
          });
          setSelectedProperties(options);
        })
        .catch((e) => {
          toastError(e?.data);
        });
    } else if (!isHasProperties) {
      form.setFieldValue("PropertiesLength", -1);
    }
  };
  useEffect(() => {
    GetProduct();
  }, [product]);
  const handleSelectedProperty = (name: string, propertyId: string) => {
    const index = selectedProperties?.findIndex((item) => item.name === name);
    const item = selectedProperties[index];
    selectedProperties?.splice(index, 1, {
      ...item,
      ...{ name, id: propertyId },
    });
    setSelectedProperties(selectedProperties);
    onChange();
  };

  const selectedObjects = properties?.reduce((acc: any, obj: Property) => {
    const selectedValues = obj?.values?.filter((value) =>
      propertiesMapped?.includes(value.id)
    );
    if (selectedValues.length > 0) {
      acc[obj.name] = selectedValues[0]?.id;
    }
    return acc;
  }, {});

  // console.log({ propertiesWatch, propertiesMapped, selectedObjects });
  try {
    return (
      <div>
        {properties?.length ? (
          <Form.List name="propertiesLs" initialValue={selectedObjects}>
            {() => (
              <div className="flex gap-2">
                {properties?.map((property) => {
                  const options =
                    property?.values?.map((options: SelectionType) => {
                      return { label: options?.name, value: options?.id };
                    }) || [];
                  const initialValue = selectedObjects[property.name];
                  // const initialValue = {
                  //   key: convertedData[property.name],
                  //   value: convertedData[property.name],
                  //   label: property?.name,
                  // };
                  return (
                    <Form.Item
                      // shouldUpdate
                      key={property?.id}
                      // label={property.name}
                      // name={["propertiesLs", property.name]}
                      initialValue={initialValue}
                      name={property?.name}
                      style={{ margin: 0, minWidth: 120 }}
                      rules={[
                        {
                          required: true,
                          message: `${property?.name} missing!`,
                        },
                      ]}
                    >
                      <SelectSearch
                        disabled={disabled}
                        className="min-w-[4rem] max-md:w-full rounded-lg border border-gray-300"
                        placeholder={property?.name}
                        //   placeholder={`Select a ${property.name}`}
                        onChange={(value: string) =>
                          handleSelectedProperty(property?.name, value)
                        }
                        // defaultValue={undefined}
                        options={options}
                      />
                    </Form.Item>
                  );
                })}
              </div>
            )}
          </Form.List>
        ) : (
          <span className="text-sm text-gray-400">{"has no properties"}</span>
        )}
      </div>
    );
  } catch (err) {
    console.log(err);
    return <></>;
  }
};

export default PropertiesInputs;

import React from "react";
import Input from "./ui/Inputs/Input";
import Button from "./Button";
import { ContactFormValues } from "../utils/types";

interface InputConfig {
  id: number;
  label: string;
  placeholder: string;
  name: string;
  required?: boolean;
  type?: string;
  value: string;
  error?: string;
  textArea?: boolean;
  component?: React.FC<any>;
}

interface FormProps {
  inputs: InputConfig[];
  onSubmit: (values: any) => void;
  onReset?: () => void;
  loading?: boolean;
  formValues: ContactFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ContactFormValues>>;
}

const Form: React.FC<FormProps> = ({
  inputs,
  onSubmit,
  onReset,
  loading,
  formValues,
  setFormValues,
}) => {
  const [errors, setErrors] = React.useState<Partial<ContactFormValues>>({});

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<ContactFormValues> = {};

    inputs.forEach((input) => {
      if (input.required && !formValues[input.name]) {
        newErrors[input.name] = `${input.label} is required`;
      }
      if (!formValues.email) {
        newErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
        newErrors.email = "Email is invalid";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      // Submit the form
      // console.log(formValues);
      onSubmit(formValues);
    }
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormValues(formValues);
    setErrors({});
    onReset && onReset();
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <div className="flex mt-4 flex-wrap">
        {inputs.map((input) => {
          const Component = input.component;
          return (
            <div
              key={input.id}
              //   className={`${input.textArea ? "w-full" : "w-1/2"}  pr-5 mb-5`}
              // className={`lg:${
              //   input.textArea ? "w-full" : "w-1/2"
              // } sm:w-full mb-5`}
              className={`w-full mb-5 max-md:mb-2 md:w-1/2 ${
                input.textArea && "!w-full"
              }`}
            >
              {Component ? (
                <Component
                  label={input.label}
                  name={input.name}
                  value={formValues[input.name]}
                  onChange={handleChange}
                />
              ) : (
                <Input
                  label={input.label}
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  required={input.required}
                  textArea={input.textArea}
                  value={formValues[input.name]}
                  onChange={handleChange}
                  error={errors[input.name]}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="flex gap-5 items-center self-center mt-4 text-xl text-center whitespace-nowrap">
        <Button
          text={"Send"}
          type={"submit"}
          loading={loading}
          className="justify-center items-center !px-12 !py-3 !font-black text-white bg-primaryGreen !max-md:px-5"
        />
        <Button
          text={"Reset"}
          type={"reset"}
          disabled={loading}
          className="justify-center items-center !px-12 !py-3 !font-bold !border-0 !bg-gray-100 !text-gray-400 !max-md:px-5"
        />
      </div>
    </form>
  );
};

export default Form;

import { useContext } from "react";
import { AlertsContext } from "../context/AlertContext";

export const useAlertsContext = () => {
  const context = useContext(AlertsContext);

  if (!context) {
    throw Error("useAlertsContext must be used inside an AlertsProvider");
  }

  return context;
};

import React from "react";
import Section from "../../layouts/Section";
import SectionBadge from "../../components/SectionBadge";
import SectionTitle from "../../components/SectionTitle";
import images from "../../data/images";
import ImagesSlider from "./Slider";
import CatalogItem from "../Catalog/Components/tabs";
import { RiFlowerLine, RiPlantLine } from "react-icons/ri";
import { IoFlowerOutline } from "react-icons/io5";
import { PiFlowerLotus, PiFlowerTulipBold } from "react-icons/pi";
import { BsFlower2 } from "react-icons/bs";

const Catalog: React.FC = () => {
  const plantData = [
    {
      icon: <PiFlowerLotus className="w-8 h-8" color="#46973B" />,
      // image: images.decorativeGreeneryIcon,
      title: "Eucalyptus",
      id: "Eucalyptus",
    },
    {
      icon: <PiFlowerTulipBold className="w-8 h-8" color="#46973B" />,
      // image: images.floweringPlantsIcon,
      title: "Gypsophila",
      id: "Gypsophila",
    },
    {
      icon: <IoFlowerOutline className="w-8 h-8" color="#46973B" />,
      // image: images.gerberaIcon,
      title: "Eustoma",
      id: "Eustoma",
    },
    {
      // image: images.fillersIcon,
      icon: <RiPlantLine className="w-8 h-8" color="#46973B" />,
      title: "Ruscuss",
      id: "Ruscuss",
    },
    {
      icon: <RiFlowerLine className="w-8 h-8" color="#46973B" />,
      // image: images.fillersIcon,
      title: "Peony",
      id: "Peony",
    },
    {
      icon: <BsFlower2 className="w-8 h-8" color="#46973B" />,
      // image: images.fillersIcon,
      title: "Wax Flowers",
      id: "Wax Flowers",
    },
  ];
  const imageKeys = Array.from(
    { length: 28 },
    (_, index) => `dolina_img${index + 1}` as keyof typeof images
  );

  const imageArray = imageKeys.map((key) => images[key]);
  return (
    <Section
      id="Catalog"
      className="flex justify-center items-center flex-col w-full max-md:max-w-full"
    >
      <main className="mt-11 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-4 max-md:flex-col">
          <section className="flex flex-col w-1/2 max-md:ml-0 max-md:w-full">
            <SectionBadge text="Discover more" highlightedText="more" />
            <SectionTitle
              title="Our Featured"
              highlighted="Products"
              // className="text-center"
            />
            <div className="flex flex-col mt-8 max-md:mt-6 max-md:max-w-full">
              {plantData.map((plant, index) => (
                <CatalogItem
                  key={index}
                  id={plant.id}
                  // image={plant?.image}
                  icon={plant?.icon}
                  title={plant.title}
                />
              ))}
            </div>
          </section>
          <div className="flex flex-col aspect-square mr-5 w-1/2 max-md:mr-0 max-md:w-full">
            <ImagesSlider images={imageArray} />
          </div>
        </div>
      </main>
    </Section>
  );
};

export default Catalog;

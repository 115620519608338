import React from "react";
import EditHeader from "./EditHeader";
import ViewHeader from "./ViewHeader";

type ViewHeaderProps = {
  shippingDate?: string;
  destination?: string;
};

type EditHeaderProps = {
  productsLength?: number;
  destinationInput: React.ReactNode;
  shippingInput: React.ReactNode;
};
type Props = {
  type: "edit" | "view";
} & (ViewHeaderProps | EditHeaderProps);

const Header = (props: Props) => {
  const { type, ...rest } = props;
  if (type === "edit") {
    const editProps = rest as EditHeaderProps;
    return <EditHeader {...editProps} />;
  } else if (type === "view") {
    const viewProps = rest as ViewHeaderProps;
    return <ViewHeader {...viewProps} />;
  } else {
    return <></>;
  }
};

export default Header;

import React from "react";
import { PropertyType, ProductType } from "../../../../utils/types";
import { Table, TableProps } from "antd";
import { useMediaQuery } from "react-responsive";

type ColumnsType<T> = TableProps<T>["columns"];
const columns: ColumnsType<ProductType> = [
  {
    title: "Mark",
    dataIndex: "mark",
    render: (mark) => <span className="font-bold">{mark?.name}</span>,
  },
  {
    title: "Truck",
    dataIndex: "truck",
    render: (truck) => `${truck?.name || "-"}`,
  },
  {
    title: "Product",
    dataIndex: "name",
    render: (name) => <span className="font-bold">{name}</span>,
  },
  {
    title: "Properties",
    dataIndex: "properties",
    render: (properties) =>
      properties?.length
        ? properties
            .map(
              (property: PropertyType) => `${property?.propertyValueName}`
              // `${property.name}: ${property.propertyValueName}`
            )
            .join(", ")
        : "not assigned",
  },
  {
    title: "Packings",
    dataIndex: "packing",
    render: (packing) => `${packing?.amount}`,
  },
  {
    title: "Boxes",
    dataIndex: "boxesCount",
  },
  {
    title: "Total Units",
    dataIndex: "",
    render: (_: any, record: ProductType) => (
      <span className="font-bold">
        {(record?.packing?.amount || 0) * (record?.boxesCount || 0)}
      </span>
    ),
  },
];
const ProductsTable = ({
  products,
  isPreview,
}: {
  products: ProductType[] | undefined;
  isPreview?: boolean;
}) => {
  const isMobile = useMediaQuery({ maxWidth: "1150px" });
  return (
    <div className="gap-4 my-8">
      <Table
        columns={columns}
        scroll={{ x: true }}
        sticky={isPreview || isMobile ? false : { offsetHeader: 165 }}
        // TODO
        rowKey={"key"}
        // rowKey={(record) => `[p]-${record.id}`}
        className="text-nowrap"
        dataSource={products}
        pagination={false}
      />
    </div>
  );
};

export default ProductsTable;

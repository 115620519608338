import BaseService from "../../services/BaseService";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { Contact, Product, RequestMethod } from "../../utils/types";

export const GetProducts = async (
  requestBody: any
): Promise<{ data: Product[] }> =>
  await BaseService(
    API_ENDPOINTS.GET_PRODUCTS,
    RequestMethod.POST,
    requestBody
  );
export const ContactUs = async (requestBody: Contact) =>
  await BaseService(API_ENDPOINTS.CONTACT_US, RequestMethod.POST, requestBody);

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loading from "./components/ui/Loading";
import { ConfigProvider, type ThemeConfig } from "antd";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const config: ThemeConfig = {
  token: {
    colorPrimary: "#46973B",
  },
};
const helmetContext = {};

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <ConfigProvider theme={config}>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

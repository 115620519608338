import React from "react";
import Form from "../../../components/Form";
import CountrySelect from "../../../components/ui/Inputs/CountrySelect";
import { Contact, ContactFormValues } from "../../../utils/types";
import { ContactUs } from "../../../modules/common/service";
import { useAlertsContext } from "../../../hooks/useAlertsContext";

const inputs = [
  {
    id: 1,
    label: "Full Name*",
    name: "fullName",
    placeholder: "Full Name",
    required: true,
    value: "",
  },
  {
    id: 2,
    label: "Company",
    name: "company",
    placeholder: "Company",
    value: "",
  },
  {
    id: 3,
    label: "Email*",
    name: "email",
    placeholder: "Email",
    type: "email",
    required: true,
    value: "",
  },
  {
    id: 4,
    label: "Telephone*",
    name: "telephone",
    placeholder: "Telephone",
    type: "tel",
    required: true,
    value: "",
  },
  {
    id: 5,
    label: "Country",
    name: "country",
    placeholder: "Country",
    component: CountrySelect,
    value: "",
  },
  {
    id: 6,
    label: "Subject",
    name: "subject",
    placeholder: "Subject",
    value: "",
  },
  {
    id: 7,
    label: "Message",
    name: "message",
    placeholder: "Message",
    textArea: true,
    value: "",
  },
];

const initialValues = {
  fullName: "",
  company: "",
  email: "",
  telephone: "",
  country: "",
  subject: "",
  message: "",
};
const PricesForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const { toastSuccess, toastError } = useAlertsContext();
  const [formValues, setFormValues] =
    React.useState<ContactFormValues>(initialValues);

  const handleSubmitContactUs = async (values: ContactFormValues) => {
    setLoading(true);
    const formValues: Contact = {
      name: values.fullName,
      email: values.email,
      phoneNumber: values.telephone,
      info: values.message,
      subject: values.subject,
      company: values.company,
      country: values.country,
    };
    // Handle submission for contact us form
    await ContactUs(formValues)
      .then((response) =>
        toastSuccess(response || "Form Submitted Successfully")
      )
      .catch((e) => toastError(e?.data))
      .finally(() => setLoading(false));
  };

  const handleResetContactUs = () => {
    // Handle reset for contact us form
    console.log("Contact Us Form Reset");
  };
  return (
    <Form
      inputs={inputs}
      onSubmit={handleSubmitContactUs}
      onReset={handleResetContactUs}
      formValues={formValues}
      setFormValues={setFormValues}
      loading={loading}
    />
  );
};
export default PricesForm;

import React from "react";
import { StatusEnum } from "../../../../utils/types";

const OrderStatus = ({
  status,
  small,
}: {
  status: StatusEnum;
  small?: boolean;
}) => {
  const defaultClass = `font-medium px-4 whitespace-nowrap py-1 rounded-${
    small ? "md !px-2" : "full"
  } border`;
  switch (status) {
    case StatusEnum.OrderSentByClient:
      return (
        <span
          className={`${defaultClass} bg-yellow-100 text-yellow-600 border-yellow-100`}
        >
          {"Sent"}
        </span>
      );
    case StatusEnum.InProgress:
    case StatusEnum.AllSentToFarms:
      return (
        <span
          className={`${defaultClass} bg-blue-100 text-blue-800 border-blue-300`}
        >
          {"In Progress"}
        </span>
      );
    case StatusEnum.Shipped:
      return (
        <span
          className={`${defaultClass} bg-green-100 text-primaryGreen border-green-400`}
        >
          {"Shipped"}
        </span>
      );
    case StatusEnum.Canceled:
      return (
        <span
          className={`${defaultClass} bg-red-100 text-red-600 border-red-300`}
        >
          {"Canceled"}
        </span>
      );
    default:
      return <></>;
  }
};

export default OrderStatus;

import React from "react";

interface TitleProps {
  title: string;
  highlighted: string;
  className?: string;
}
const SectionTitle: React.FC<TitleProps> = ({
  title,
  highlighted,
  className,
}) => (
  <h1
    className={`mt-2 text-5xl font-semibold capitalize  max-md:text-4xl ${className}`}
  >
    {`${title} `} <span className="text-primaryGreen">{highlighted}</span>
  </h1>
);

export default SectionTitle;

import React from "react";
import { Pagination, Table } from "antd";
import type { PaginationProps, TableProps } from "antd";
import OrdersActions from "./OrdersActions";
import OrderStatus from "./OrderStatus";
import {
  OrdersDataType,
  StatusEnum,
  TableParams,
} from "../../../../utils/types";
import { useNavigateToTop } from "../../../../hooks/useNavigateToTop";

type ColumnsType<T> = TableProps<T>["columns"];
const columns: ColumnsType<OrdersDataType> = [
  {
    title: "Order No",
    dataIndex: "number",
    sorter: true,
    // render: (_: any, record: OrdersDataType) => (
    //   <span onClick={() => navigate(`${record.id}`)}>{record.number}</span>
    // ),
  },
  {
    title: "Creation Date",
    dataIndex: "addedDate",
    sorter: true,
  },
  {
    title: "Shipping Date",
    dataIndex: "shippingDate",
    sorter: true,
  },
  {
    title: "Boxes",
    dataIndex: "boxesCount",
  },
  {
    title: "Status",
    dataIndex: "status",
    filterMultiple: false,
    showSorterTooltip: { target: "full-header" },
    filters: [
      {
        text: <OrderStatus status={StatusEnum.OrderSentByClient} small />,
        value: StatusEnum.OrderSentByClient,
      },
      {
        text: <OrderStatus status={StatusEnum.InProgress} small />,
        value: StatusEnum.InProgress,
      },
      {
        text: <OrderStatus status={StatusEnum.Shipped} small />,
        value: StatusEnum.Shipped,
      },
      {
        text: <OrderStatus status={StatusEnum.Canceled} small />,
        value: StatusEnum.Canceled,
      },
    ],
    // onFilter: (value, record) => record.status === value,
    render: (_: any, record: OrdersDataType) => (
      <OrderStatus status={record.status} />
    ),
    sorter: true,
  },
  {
    title: "Actions",
    dataIndex: "",
    key: "x",
    render: (_: any, record: OrdersDataType) => (
      <OrdersActions id={record.id} />
    ),
  },
];
const OrdersTable = ({
  data,
  loading,
  hasSelected,
  tableParams,
  rowSelection,
  selectedRowKeys,
  handleTableChange,
}: {
  data?: OrdersDataType[];
  loading: boolean;
  hasSelected: boolean;
  tableParams: TableParams;
  rowSelection: any;
  selectedRowKeys: React.Key[];
  handleTableChange: any;
}) => {
  const navigateToTop = useNavigateToTop();
  const { pagination } = tableParams;
  //   const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
  //     current,
  //     pageSize
  //   ) => {
  //     handleTableChange({ ...pagination, current, pageSize });
  //     console.log(tableParams, {
  //       ...tableParams,
  //       pagination: { ...pagination, current, pageSize },
  //     });
  //   };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    handleTableChange({ ...pagination, current: page, pageSize });
  };
  return (
    <>
      <span>
        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
      </span>
      {/* change style ant-table-column-title */}
      <Table
        scroll={{ x: true }}
        columns={columns}
        // TODO
        rowKey={(record) => record.id}
        dataSource={data}
        // pagination={false}
        pagination={pagination}
        sortDirections={["ascend", "descend", "ascend"]}
        loading={loading}
        rowSelection={rowSelection}
        rowClassName={"cursor-pointer"}
        className="text-nowrap"
        onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: () => navigate(`${record.id}`),
        //   };
        // }}

        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigateToTop(`${record?.id}`);
            },
          };
        }}
      />
      <div className="mt-8">
        <Pagination
          total={pagination?.total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // responsive
          showSizeChanger
          onChange={onChange}
          pageSize={pagination?.pageSize}
          current={pagination?.current}
        />
      </div>
    </>
  );
};

export default OrdersTable;

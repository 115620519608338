import React from "react";
import countries from "../../../data/countries";
type country = {
  name: string;
  capital: string;
  area: number;
  coordinates: number[];
  currencies: {
    name: string;
    symbol?: string;
  }[];
  languages: string[];
  maps: {
    googleMaps: string;
    openStreetMaps: string;
  };
  postalCode?: {
    format?: string;
    regex?: string;
  };
  flags: {
    png: string;
    svg: string;
  };
  population: number;
  emoji: string;
  countryCallingCode: string;
};
const countryOptions: country[] = countries;
interface CountrySelectProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const CountrySelect: React.FC<CountrySelectProps> = ({
  label,
  name,
  value,
  onChange,
}) => {
  return (
    <div className="flex flex-col flex-1 w-full lg:pr-4 sm:p-0">
      <label
        htmlFor={name}
        className={`font-black pl-2`}
        // className={`${isMobile ? "" : "text-lg mb-2"} font-black pl-3`}
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={`p-3 rounded-lg bg-neutral-100 text-zinc-500 max-md:px-5`}
        // className={`${
        //   isMobile ? "p-3" : "p-6"
        // }  mt-2 text-base rounded-lg bg-neutral-100 text-zinc-500 max-md:px-5`}
      >
        {countryOptions.map(({ name, flags, emoji }: country) => (
          <option key={name} value={name}>
            {emoji} {name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default CountrySelect;

import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Icon from "../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../components/ui/EmptyState";
import Button from "../../components/Button";
import useNewOrder from "./hooks/useNewOrder";
import Header from "./containers/header";
import SelectSearch from "../../components/ui/Inputs/SelectSearch";
import {
  DestinationType,
  OrderProductType,
  SelectionType,
} from "../../utils/types";
import Footer from "./containers/footer";
import SubmitModal from "./components/SubmitModal";
import EditOrderForm from "./components/Products/EditOrderForm";
import useDataset from "./hooks/useDataset";
import OrderSelectors from "../../modules/orders/selectors";
import SEO from "../../layouts/SEO";
import ConfirmNavigation from "../../hooks/useConfirmNavigation";

const EditOrder = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const {
    loading,
    Validation,
    viewOrder,
    modalVisible,
    fetchedOrder,
    setModalVisible,
    editOrder,
    orderView,
    DuplicateOrder,
  } = useNewOrder();
  const { dataset } = useDataset();
  const [showPrompt, setShowPrompt] = useState(true);
  useEffect(() => {
    if (orderId) {
      DuplicateOrder(orderId, true);
    }
    return () => {
      setModalVisible(false);
      setShowPrompt(true);
    };
  }, [orderId]);
  const { orderSelector } = OrderSelectors();
  if (loading) {
    return <div>Loading ...</div>;
  }

  const { products, destinations, shipments, marks, trucks } = dataset;
  const shipmentsOptions = shipments.map((options: SelectionType) => {
    return { label: options?.name, value: options?.id };
  });
  const destinationsOptions = destinations.map((options: DestinationType) => ({
    label: options?.name,
    value: options?.id,
    usingTruck: options?.usingTruck,
  }));
  const totalBoxAmount = orderSelector?.products?.reduce(
    (total: number, item: OrderProductType) => total + (item?.boxesCount || 0),
    0
  );
  const { isValid } = Validation();

  const productsRows: any[] = orderSelector?.products || [];
  return (
    <div className="px-4">
      <SEO title={"Edit Order | Dolina Agro"} />
      <div className="flex justify-between sticky top-[5.5rem] bg-white z-10">
        <PageTitle title={`Edit Order #${fetchedOrder?.number || orderId}`} />
        <Icon className="my-4" onClick={() => navigate(-1)}>
          <IoCloseOutline color={"#000"} />
        </Icon>
      </div>
      {orderId ? (
        <>
          <Header
            type={"edit"}
            shippingInput={
              <SelectSearch
                disabled
                defaultValue={orderSelector?.shipment}
                // value={orderSelector?.shipmentId}
                placeholder={"Select a Shipment"}
                options={shipmentsOptions}
              />
            }
            destinationInput={
              <SelectSearch
                disabled
                defaultValue={orderSelector?.destination}
                // value={orderSelector?.destinationId}
                placeholder={"Select a Destination"}
                options={destinationsOptions}
              />
            }
          />
          <EditOrderForm
            marks={marks}
            trucks={trucks}
            products={products}
            dataRows={productsRows}
          />
          {/* <div className="my-8">{JSON.stringify(order, null, 2)}</div> */}
          {/* Footer */}
          <Footer
            type={"edit"}
            notes={orderSelector?.notes}
            totalBoxAmount={totalBoxAmount}
            loading={loading}
            isValid={isValid}
            onCancel={() => navigate(-1)}
            onSubmit={() => viewOrder()}
          />
          {/* Modal */}
          <SubmitModal
            showModal={modalVisible}
            loading={loading}
            dismiss={() => {
              setShowPrompt(true);
              setModalVisible(false);
            }}
            submit={() => {
              setShowPrompt(false);
              editOrder(orderId);
            }}
            orderView={orderView}
          />
          {/* <Prompt
            when={showPrompt}
            message="Unsaved changes detected, continue?"
            beforeUnload={true}
          /> */}
          <ConfirmNavigation isBlocked={showPrompt} />
        </>
      ) : (
        <EmptyState
          title={
            <h2 className="text-center  text-xl font-semibold leading-loose pb-2">
              Error Duplicating Order!
            </h2>
          }
          description={
            <p className="text-center  text-base font-normal leading-relaxed pb-4">
              Try changing order view to <br />
              see order's information
            </p>
          }
          footer={
            <div className="flex gap-3">
              <Button
                text={"Go Back"}
                variant="secondary"
                onClick={() => navigate(-1)}
                className="!px-12 !py-3 mt-8 !max-md:px-5"
              />
              <Button
                text={"New Order"}
                onClick={() => navigate("/Profile/Orders/New")}
                className="!px-12 !py-3 mt-8 !max-md:px-5"
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default EditOrder;

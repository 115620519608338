import * as React from "react";

interface IconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  dot?: boolean;
  className?: string;
  onClick?: (event: any) => void;
}

const Icon = ({ children, dot, className, onClick }: IconProps) => {
  return (
    <div
      onClick={onClick}
      className={`flex relative justify-center items-center p-2 w-10 h-10 rounded-lg bg-gray-100 text-gray-800 ${className} ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      {dot ? (
        <div className="w-[0.4rem] h-[0.4rem] rounded-full absolute top-2 right-1 bg-secondaryGreen" />
      ) : null}
      {children}
    </div>
  );
};

export default Icon;

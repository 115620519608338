import { useState } from "react";
import {
  CreateOrder,
  GetOrderView,
  GetOrderById,
  EditOrder,
} from "../../../modules/orders/service";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import {
  NewOrderType,
  OrderProductType,
  OrderType,
  OrderValidation,
  ProductFormType,
  ProductType,
} from "../../../utils/types";
import { useNavigate } from "react-router-dom";
import { GenerateGuid } from "../../../utils/get-guid";
import { useAppDispatch } from "../../../hooks/useStore";
import AuthActions from "../../../modules/auth/actions";
import OrderActions from "../../../modules/orders/actions";
import OrderSelectors from "../../../modules/orders/selectors";

const useNewOrder = (isNew: boolean = false) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logout } = AuthActions();
  const { clearOrder, saveOrder, toggleErrors } = OrderActions(isNew);
  const { orderSubmit } = OrderSelectors(isNew);

  const { toastError, toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderView, setOrderView] = useState<OrderType | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [fetchedOrder, setFetchedOrder] = useState<OrderType>({});

  const [order, setOrder] = useState<NewOrderType>({
    products: undefined,
    shipmentId: undefined,
    destination: undefined,
    notes: undefined,
  });

  // const handleSessionExpired = (error: string | object) => {
  const handleSessionExpired = () => {
    // toastError(error || "Token Expired");
    setTimeout(() => {
      dispatch(logout());
    }, 1500);
  };
  const Validation = () => {
    const { products, shipmentId, destinationId } = orderSubmit;
    const isProductsHasErrors = products?.some(
      (product: ProductType) => product?.errors > 0
    );
    let errors: OrderValidation = {
      products: "",
      productsRows: "",
      shipmentId: "",
      destinationId: "",
    };
    if (!products?.length || isProductsHasErrors) {
      // message.error("Product name is required");
      errors = { ...errors, products: "Products is required" };
    } else {
      errors = { ...errors, products: "" };
    }
    if (!shipmentId?.length) {
      errors = { ...errors, shipmentId: "Shipping Date is required" };
    } else {
      errors = { ...errors, shipmentId: "" };
    }
    if (!destinationId?.length) {
      errors = { ...errors, destinationId: "Destination is required" };
    } else {
      errors = { ...errors, destinationId: "" };
    }
    // Add more validation logic as needed
    return {
      isValid: Object.values(errors).every((value) => value === ""),
      errors,
    };
  };

  const viewOrder = async () => {
    const { isValid, errors } = Validation();
    dispatch(toggleErrors(true));
    if (isValid) {
      setIsSubmitting(true);
      const thereIsNoChanges = orderSubmit?.products?.every(
        (product: OrderProductType) => product.disabled
      );
      if (thereIsNoChanges) {
        toastError("There is no changes");
      } else {
        await GetOrderView(orderSubmit)
          .then((response) => {
            toastSuccess("Order validated successfully");
            setOrderView(response);
            setModalVisible(true);
          })
          .catch((error) => {
            toastError(error?.data);
            setIsSubmitting(false);
            if (error?.status && error?.status === 401) {
              handleSessionExpired();
            }
          })
          .finally(() => setIsSubmitting(false));
      }
    } else {
      const errorMessage = Object.values(errors)
        .map((val: string) => {
          return val;
        })
        .filter(Boolean)
        .join("\n");
      toastError("Please complete all required fields:\n" + errorMessage);
    }
  };
  const submitOrder = async () => {
    setIsSubmitting(true);
    await CreateOrder(orderSubmit)
      .then((response) => {
        toastSuccess(response);
        setModalVisible(false);
        dispatch(clearOrder());
        navigate("/Profile/Orders", { replace: true });
      })
      .catch((error) => {
        toastError(error?.data);
        if (error?.status && error?.status === 401) {
          handleSessionExpired();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const editOrder = async (orderId: string) => {
    setIsSubmitting(true);
    const products = orderSubmit?.products?.filter(
      (product: OrderProductType) => !product.disabled
    );
    await EditOrder({ orderId, products })
      .then((response) => {
        toastSuccess(response);
        setModalVisible(false);
        dispatch(clearOrder());
        navigate("/Profile/Orders", { replace: true });
      })
      .catch((error) => {
        // const errorMsg = JSON.stringify(error?.data);
        // const errorMsg =
        //   typeof error?.data === "object"
        //     ? "Error creating new order!"
        //     : JSON.stringify(error?.data);
        toastError(error?.data);
        if (error?.status && error?.status === 401) {
          handleSessionExpired();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const FetchOrderById = async (orderId: string) => {
    setLoading(true);
    await GetOrderById(orderId)
      .then((response) => setFetchedOrder(response))
      .catch((error) => {
        toastError(error?.data);
        if (error?.status && error?.status === 401) {
          handleSessionExpired();
        }
      })
      .finally(() => setLoading(false));
  };
  const HandleDuplicatedOrder = (response: OrderType, isEdit?: boolean) => {
    setFetchedOrder(response);
    const products: ProductFormType[] =
      response?.products?.map((product) =>
        !isEdit
          ? {
              ...product,
              product: product?.isActive
                ? {
                    label: product?.name,
                    value: product?.id,
                    key: product?.id,
                  }
                : undefined,
              packing:
                product?.packing?.isActive && product?.isActive
                  ? {
                      ...product?.packing,
                      label: product?.packing?.amount,
                      value: product?.packing?.id,
                      key: product?.packing?.id,
                    }
                  : undefined,
              truck: product?.truck?.isActive
                ? {
                    label: product?.truck?.name,
                    value: product?.truck?.id,
                    key: product?.truck?.id,
                  }
                : undefined,
              mark: product?.mark?.isActive
                ? {
                    label: product?.mark?.name,
                    value: product?.mark?.id,
                    key: product?.mark?.id,
                  }
                : undefined,
              // properties: product?.properties?.map(
              //   (property) => property.propertyValueId
              // ),
              propertiesLs: product?.properties?.reduce(
                (
                  acc: any,
                  {
                    name,
                    propertyValueId,
                    isActive,
                  }: {
                    name: string;
                    propertyValueId: string;
                    isActive: boolean;
                  }
                ) => {
                  if (isActive) {
                    acc[name] = propertyValueId;
                  }
                  return acc;
                },
                {}
              ),
              key: GenerateGuid(),
            }
          : {
              ...product,
              product: {
                label: product?.name,
                value: product?.id,
                key: product?.id,
              },
              packing: {
                ...product?.packing,
                label: product?.packing?.amount,
                value: product?.packing?.id,
                key: product?.packing?.id,
              },
              truck: {
                label: product?.truck?.name,
                value: product?.truck?.id,
                key: product?.truck?.id,
              },
              mark: {
                label: product?.mark?.name,
                value: product?.mark?.id,
                key: product?.mark?.id,
              },
              // properties: product?.properties?.map(
              //   (property) => property.propertyValueId
              // ),
              propertiesLs: product?.properties?.reduce(
                (
                  acc: any,
                  {
                    name,
                    propertyValueId,
                    isActive,
                  }: {
                    name: string;
                    propertyValueId: string;
                    isActive: boolean;
                  }
                ) => {
                  if (isActive) {
                    acc[name] = propertyValueId;
                  }
                  return acc;
                },
                {}
              ),
              key: GenerateGuid(),
              disabled: isEdit,
            }
      ) || [];

    dispatch(
      saveOrder({
        shipmentId: !isEdit
          ? response?.shipment?.isActive
            ? response?.shipment?.id
            : undefined
          : response?.shipment?.id,
        shipment: !isEdit
          ? response?.shipment?.isActive
            ? {
                label: response?.shipment?.shippingDate,
                value: response?.shipment?.id,
              }
            : undefined
          : {
              label: response?.shipment?.shippingDate,
              value: response?.shipment?.id,
            },
        destination: !isEdit
          ? response?.destination?.isActive
            ? {
                label: response?.destination?.name,
                value: response?.destination?.id,
                usingTruck: response?.destination?.usingTruck,
              }
            : undefined
          : {
              label: response?.destination?.name,
              value: response?.destination?.id,
              usingTruck: response?.destination?.usingTruck,
            },
        destinationId: !isEdit
          ? response?.destination?.isActive
            ? response?.destination?.id
            : undefined
          : response?.destination?.id,
        notes: response?.notes,
        products: products,
      })
    );
  };
  const DuplicateOrder = async (orderId: string, isEdit = false) => {
    setLoading(true);
    await GetOrderById(orderId)
      .then((response) => {
        HandleDuplicatedOrder(response, isEdit);
      })
      .catch((error) => {
        toastError(error?.data);
        if (error?.status && error?.status === 401) {
          handleSessionExpired();
        }
      })
      .finally(() => setLoading(false));
  };
  return {
    isSubmitting,
    loading,
    order,
    setOrder,
    Validation,
    viewOrder,
    submitOrder,
    modalVisible,
    setModalVisible,
    orderView,
    FetchOrderById,
    fetchedOrder,
    DuplicateOrder,
    editOrder,
  };
};

export default useNewOrder;

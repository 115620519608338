import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import Splash from "./splash/Splash";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import WithTokenRefresh from "../hooks/withTokenRefresh";

// const PageContainer = ({ children }: { children: React.ReactNode }) => {
const PageContainer = () => {
  const [reveal, setReveal] = React.useState(true);
  const main = React.useRef(null);
  // const dispatch = useAppDispatch();
  // const { logout } = AuthActions();
  React.useLayoutEffect(() => {
    const handleDOMContentLoaded = () => {
      setTimeout(() => {
        setReveal(false);
      }, 5000);
    };
    document.addEventListener("readystatechange", () => {
      handleDOMContentLoaded();
    });
    return () => {
      document.removeEventListener("readystatechange", handleDOMContentLoaded);
    };
  }, []);
  // useEffect(() => {
  //   const token = getToken();
  //   if (token) {
  //     dispatch(logout());
  //   }
  // }, []);

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <WithTokenRefresh />
      <Navbar />
      {reveal ? <Splash /> : null}
      <div
        ref={main}
        className="p-4 pb-8 sm:p-[1rem] md:px-[4rem] lg:px-[6rem] xl:px-[8rem] lg:pt-4 lg:pb-0 xl:pb-[8rem] min-h-dvh"
      >
        <Outlet />
      </div>
      <Footer />
    </ErrorBoundary>
  );
};

export default PageContainer;

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Icon from "../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import ProductsTable from "./components/Products/ProductsTable";
import Button from "../../components/Button";
import StatusSteps from "./components/Orders/StatusSteps";
import EmptyState from "../../components/ui/EmptyState";
import useNewOrder from "./hooks/useNewOrder";
import Footer from "./containers/footer";
import Header from "./containers/header";
import { StatusEnum } from "../../utils/types";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import useOrders from "./hooks/useOrders";
import SEO from "../../layouts/SEO";

const ViewOrder = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { loading, FetchOrderById, fetchedOrder } = useNewOrder();
  const { exportExcel } = useOrders();

  const { showWarn } = useAlertsContext();

  useEffect(() => {
    if (orderId) {
      FetchOrderById(orderId);
    }
  }, [orderId]);
  if (loading) {
    return <div>Loading ...</div>;
  }
  const {
    id,
    number,
    addedDate,
    destination,
    shipment,
    notes,
    products,
    status,
  } = fetchedOrder;
  const totalBoxAmount = products?.reduce(
    (total, item) => total + (item?.boxesCount || 0),
    0
  );
  const EditOrder = () => {
    if (status === StatusEnum.OrderSentByClient) {
      navigate(`/Profile/Orders/Edit/${orderId}`);
    } else {
      let alert = "Can't edit order";
      switch (status) {
        case StatusEnum.InProgress:
        case StatusEnum.AllSentToFarms:
          alert = "Can't edit order, already in progress!";
          break;
        case StatusEnum.Shipped:
          alert = "Can't edit order, already Shipped!";
          break;
        case StatusEnum.Canceled:
          alert = "Can't edit order, already Canceled!";
          break;
      }
      showWarn(alert);
    }
  };
  return (
    <div className="px-4">
      <SEO title={"View Order | Dolina Agro"} />
      {!!fetchedOrder && id ? (
        <>
          <div className="flex justify-between sticky top-[5.5rem] bg-white z-10">
            <PageTitle
              title={`Order #${number}`}
              subTitle={`Placed On ${addedDate}`}
            />
            <Icon className="my-4" onClick={() => navigate(-1)}>
              <IoCloseOutline color={"#000"} />
            </Icon>
          </div>
          <StatusSteps status={status} />
          <Header
            type={"view"}
            shippingDate={shipment?.shippingDate}
            destination={destination?.name}
          />

          <ProductsTable products={products} />

          {/* Footer */}
          <Footer
            type={"view"}
            notes={notes}
            totalBoxAmount={totalBoxAmount}
            duplicate={() => navigate(`/Profile/Orders/Duplicate/${orderId}`)}
            editOrder={EditOrder}
            downloadExcel={() => exportExcel([orderId || ""])}
          />
        </>
      ) : (
        <EmptyState
          title={
            <h2 className="text-center  text-xl font-semibold leading-loose pb-2">
              Something Went Wrong!
            </h2>
          }
          description={
            <p className="text-center  text-base font-normal leading-relaxed pb-4">
              Try changing order view to <br />
              see order's information
            </p>
          }
          footer={
            <div className="flex gap-3">
              <Button
                text={"Go Back"}
                variant="secondary"
                onClick={() => navigate(-1)}
                className="!px-12 !py-3 mt-8 !max-md:px-5"
              />
              <Button
                text={"View Orders"}
                onClick={() => navigate("/Profile/Orders")}
                className="!px-12 !py-3 mt-8 !max-md:px-5"
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default ViewOrder;

import React from "react";
import { Pagination, PaginationProps, Table, TableProps } from "antd";
import { AvailsDataType, TableParams } from "../../../utils/types";
import ProductAvailable from "./ProductAvailable";
import { useMediaQuery } from "react-responsive";

type ColumnsType<T> = TableProps<T>["columns"];
const AvailsTable = ({
  destination,
  data,
  loading,
  tableParams,
  handleTableChange,
}: {
  destination?: { label: string; value: string };
  data?: AvailsDataType[];
  loading: boolean;
  tableParams: TableParams;
  handleTableChange: any;
}) => {
  const columns: ColumnsType<AvailsDataType> = [
    {
      title: "Product",
      dataIndex: "product",
      sorter: true,
      width: "40%",
      render: (_: any, record: AvailsDataType) => (
        <span className="font-bold">{record?.name}</span>
        // <div className="flex items-center gap-4">
        //   <ProductAvailable isAvailable={record?.isAvailable} />
        //   <span className="font-bold">{record?.name}</span>
        // </div>
      ),
    },
    {
      title: "Properties",
      dataIndex: "properties",
      width: "40%",
      render: (_: any, record: AvailsDataType) =>
        record?.pricings?.map((pricing, index) => {
          const isLastItem = index === record?.pricings.length - 1;
          return (
            <div
              className={`flex w-full mt-2 ${
                isLastItem ? "" : "border-b border-b-neutral-100"
              } pb-2`}
              key={index}
            >
              <div className="flex items-center gap-4">
                <ProductAvailable isAvailable={pricing?.isAvailable} />
                <span>{pricing?.properties?.join(", ") || "Any"}</span>
              </div>
            </div>
          );
        }),
    },
    // {
    //   title: "Packing",
    //   dataIndex: "packing",
    // },
    {
      title: `Price CIP for: ${destination?.label || "unassigned"}`,
      dataIndex: "price",
      width: "20%",
      render: (_: any, record: AvailsDataType) =>
        record.pricings.map((pricing, index) => {
          const isLastItem = index === record?.pricings.length - 1;
          return (
            <div
              className={`flex w-full mt-2 ${
                isLastItem ? "" : "border-b border-b-neutral-100"
              } pb-[0.71rem]`}
              key={index}
            >
              <span className="font-bold">
                {pricing?.price ? `$${pricing?.price.toFixed(2)}` : "-"}
              </span>
            </div>
          );
        }),
    },
  ];
  const { pagination } = tableParams;
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    handleTableChange({ ...pagination, current: page, pageSize });
  };
  const isMobile = useMediaQuery({ maxWidth: "1150px" });

  return (
    <div className="my-8">
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: true }}
        size="small"
        sticky={isMobile ? false : { offsetHeader: 165 }}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={pagination}
        sortDirections={["ascend", "descend", "ascend"]}
        className="table-top-align text-nowrap"
        onChange={handleTableChange}
      />
      <div className="mt-8">
        <Pagination
          total={pagination?.total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // responsive
          showSizeChanger
          onChange={onChange}
          pageSize={pagination?.pageSize}
          current={pagination?.current}
        />
      </div>
    </div>
  );
};

export default AvailsTable;

import Cookies from "js-cookie";

export const getToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("auth_token");
};
export const setToken = (
  token: string,
  expires: Date | undefined = undefined
) => {
  const expiryDate = expires ? new Date(expires) : undefined;
  Cookies.set("auth_token", token, { expires: expiryDate });
};
export const clearToken = (token?: string) => {
  Cookies.remove("auth_token");
};

import React, { useState } from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { InputFieldProps } from "../../../utils/types";

const Input: React.FC<InputFieldProps> = ({
  label,
  placeholder,
  name,
  required = false,
  textArea = false,
  type = "text",
  value = "",
  onChange,
  error,
  className,
  isReadOnly = false,
  removeInputStyle = false,
}) => {
  const InputComponent = textArea ? "textarea" : "input";
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={className || "flex flex-col flex-1 w-full md:pr-4 sm:p-0"}>
      {label ? (
        <label
          htmlFor={name}
          className={`font-black pl-2`}
          // className={`${isMobile ? "" : "text-lg mb-2"} font-black pl-2`}
        >
          {label}
        </label>
      ) : null}
      <div className="relative">
        <InputComponent
          id={name}
          name={name}
          type={showPassword ? "text" : type}
          required={required}
          className={
            removeInputStyle
              ? ""
              : `p-3 w-full h-full text-base rounded-lg bg-neutral-100 text-zinc-500 max-md:px-5 ${
                  textArea ? "max-sm:h-[200px]" : ""
                }`
          }
          // className={
          //   removeInputStyle
          //     ? ""
          //     : `${
          //         isMobile ? "p-3" : "p-6"
          //       }  w-full h-full text-base rounded-lg bg-neutral-100 text-zinc-500 max-md:px-5 ${
          //         textArea ? "max-sm:h-[200px]" : ""
          //       }`
          // }
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={isReadOnly}
        />
        {type === "password" ? (
          <div
            className="cursor-pointer absolute right-4 top-3 h-6 w-6 flex items-center justify-center text-gray-400"
            onClick={handleTogglePasswordVisibility}
          >
            {showPassword ? (
              <RiEyeOffLine className="h-5 w-5" aria-hidden="true" />
            ) : (
              <RiEyeLine className="h-5 w-5" aria-hidden="true" />
            )}
          </div>
        ) : null}
      </div>
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default Input;

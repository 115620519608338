import { Form, GetRef } from "antd";
import React from "react";
import { GenerateGuid } from "../../../../utils/get-guid";

type FormInstance<T> = GetRef<typeof Form<T>>;
export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);
interface EditableRowProps {
  index?: number | string;
}
const EditProductRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  const key = `product_form-${GenerateGuid()}`;
  return (
    <Form autoComplete="off" name={key} form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditProductRow;

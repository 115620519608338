import React, { useContext, useEffect, useState } from "react";
import { EditableContext } from "./EditProductRow";
import { IsProductAvailable } from "../../../../modules/orders/service";
import { Form } from "antd";
import SelectSearch from "../../../../components/ui/Inputs/SelectSearch";
import { CgUnavailable } from "react-icons/cg";

const ProductInput = ({
  isHasProperties,
  disabled,
  options,
  onChange,
}: {
  isHasProperties?: boolean;
  disabled?: boolean;
  options: {
    label: string;
    value: string;
    key: string;
  }[];
  onChange: Function;
}) => {
  const form = useContext(EditableContext)!;
  const product = Form.useWatch("product", form);
  const properties = Form.useWatch("propertiesLs", form);
  const PropertiesLength = form.getFieldValue("PropertiesLength");
  const lengthOfProperties =
    (properties && Object.keys(properties)?.length) || -1;

  const propertyValuesArray: string[] = properties
    ? (Object.values(properties)?.filter(
        (value) => value !== undefined
      ) as string[])
    : [];
  const [isAvailable, setIsAvailable] = useState(true);
  const CheckIsAvailable = async () => {
    setIsAvailable(true);
    const isProductAvailable =
      (product?.value &&
        lengthOfProperties === PropertiesLength &&
        isHasProperties &&
        properties &&
        propertyValuesArray?.length === lengthOfProperties) ||
      (product?.value && !isHasProperties);
    if (isProductAvailable) {
      await IsProductAvailable(product?.value, propertyValuesArray)
        .then((data) => {
          setIsAvailable(data);
        })
        .catch((e) => console.error(e?.data));
    }
  };
  useEffect(() => {
    CheckIsAvailable();
  }, [product, properties]);
  return (
    <>
      <SelectSearch
        placeholder={"Select a product"}
        disabled={disabled}
        onChange={(value: any) => {
          onChange(value);
        }}
        labelInValue={true}
        value={product?.value}
        // defaultValue={record?.id}
        options={options}
      />
      {propertyValuesArray?.length === lengthOfProperties &&
      !isAvailable &&
      !disabled ? (
        <div className="flex items-center gap-2 absolute -bottom-5 text-red-600 font-bold">
          <CgUnavailable /> <small>{"Not Available"}</small>
        </div>
      ) : null}
    </>
  );
};

export default ProductInput;

import React from "react";
import PageTitle from "../../components/PageTitle";
import ProfileTabs from "../Profile/components/ProfileTabs";
import dayjs from "dayjs";
import SelectSearch from "../../components/ui/Inputs/SelectSearch";
import useDataset from "../orders/hooks/useDataset";
import { DestinationType } from "../../utils/types";
import AvailsTable from "./components/AvailsTable";
import useAvails from "./hooks/useAvails";
import { CiSearch } from "react-icons/ci";
import SEO from "../../layouts/SEO";
import Button from "../../components/Button";
import { BsDownload } from "react-icons/bs";

const Avails = () => {
  const { dataset } = useDataset();
  const { destinations } = dataset;
  const destinationsOptions = destinations.map((options: DestinationType) => ({
    label: options?.name,
    value: options?.id,
    usingTruck: options?.usingTruck,
  }));

  const {
    data,
    loading,
    tableParams,
    searchTerm,
    handleSearch,
    handleTableChange,
    destination,
    handleDestination,
    exportExcel,
  } = useAvails();
  return (
    <>
      <SEO title={"Avails | Dolina Agro"} />
      <ProfileTabs />
      <div className="px-4 mt-8 max-md:mt-10">
        <PageTitle title="Avails" />
        <div className="flex max-md:flex-col-reverse sticky top-[5.5rem] bg-white z-10 justify-between items-end max-md:items-start my-8 border-b border-b-gray-350 pb-2">
          <h1 className="text-xl font-bold max-md:mt-4">{`Avails for: ${dayjs().format(
            "DD/MM/YYYY"
          )}`}</h1>
          <div className="flex flex-wrap gap-4 items-end justify-end max-md:justify-start">
            <h1 className="text-lg font-bold max-md:w-full">
              Choose Destination
            </h1>
            <div className="flex flex-col">
              <span className="text-gray-400 pl-2 text-sm font-bold">
                {"Destination"}
                <span className="text-red-700 text-lg">{"*"}</span>
              </span>
              <SelectSearch
                labelInValue
                value={destination?.value}
                placeholder="Select a Destination"
                onChange={(data: any) =>
                  handleDestination({
                    label: data?.label,
                    value: data?.value,
                  })
                }
                options={destinationsOptions}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 justify-between text-base font-medium tracking-wide leading-6 max-md:flex-wrap my-8">
          <div className="flex w-1/3 max-md:w-full items-center gap-2 px-4 py-2 max-md:p-4 rounded-xl bg-neutral-50 text-slate-400">
            <span>
              <CiSearch />
            </span>
            <input
              type="text"
              placeholder={"Search by Product"}
              className={`flex w-full bg-transparent outline-none ${
                searchTerm ? "!text-body" : "text-slate-400"
              }`}
              name={"searchTerm"}
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Button
            text="Download"
            onClick={() => exportExcel()}
            variant={destination?.value ? "primary" : "disabled"}
            loading={loading}
            isReversed
            className={"rounded-xl shadow-sm max-md:h-auto"}
            icon={<BsDownload />}
          />
        </div>
        <AvailsTable
          destination={destination}
          data={data}
          loading={loading}
          tableParams={tableParams}
          handleTableChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default Avails;

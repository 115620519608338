import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type SectionProps = {
  children: React.ReactNode;
  id?: string;
  isPage?: boolean;
  className?: string;
};

const Section: React.FC<SectionProps> = ({
  children,
  className,
  id,
  isPage,
  ...props
}) => {
  const { pathname } = useLocation();
  const excludedRoutes = ["/profile", "profile"];
  const lowerCasePathname = pathname.toLowerCase();
  const isExcluded = excludedRoutes.some((route) =>
    lowerCasePathname.includes(route.toLowerCase())
  );
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const boxes = gsap.utils.toArray(".box");
    boxes.forEach((box: any) => {
      gsap.to(box, {
        y: -120,
        // opacity: 0,
        duration: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: box,
          start: "bottom bottom",
          end: "bottom",
          scrub: true,
        },
      });
    });
  }, [children]);
  return (
    <section
      className={`mt-20 ${className} ${
        isPage && !isExcluded ? "pt-[8rem] max-md:pt-[6rem]" : ""
      }`}
      {...props}
      id={id ? id : ""}
    >
      {children}
    </section>
  );
};

export default Section;

import React from "react";
type Props = {
  shippingDate?: string;
  destination?: string;
};
const ViewHeader = ({ shippingDate, destination }: Props) => {
  return (
    <div className="flex max-lg:flex-col-reverse justify-between items-end max-lg:items-start my-8 border-b border-b-gray-350 pb-2">
      <h1 className="text-xl font-bold max-lg:mt-4">Products</h1>
      <div className="flex flex-wrap gap-4 items-end justify-end max-lg:justify-start">
        <h1 className="text-lg font-bold max-md:w-full">Shipping Details</h1>
        <div className="flex flex-col">
          <span className="text-gray-400 pl-2 text-sm font-bold">
            {"Destination"}
          </span>
          <span className="text-lg p-2 min-w-[12rem] max-md:w-full rounded-lg border border-gray-300">
            {destination}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-400 pl-2 text-sm font-bold">
            {"Shipping Date"}
          </span>
          <span className="text-lg p-2 min-w-[12rem] max-md:w-full rounded-lg border border-gray-300">
            {shippingDate}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ViewHeader;

import { useState, useEffect, Key } from "react";
import { OrdersDataType, TableParams } from "../../../utils/types";
import { GetOrders } from "../../../modules/orders/service";
import { TableProps } from "antd";
import { SortOrder, SorterResult } from "antd/es/table/interface";
import { useAppDispatch } from "../../../hooks/useStore";
import AuthActions from "../../../modules/auth/actions";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import { DownloadExcel } from "../../../modules/auth/service";
function extractOrderAndField<RecordType>(
  sorter?: SorterResult<RecordType> | SorterResult<RecordType>[]
): { order?: SortOrder; field?: Key | readonly Key[] }[] {
  if (Array.isArray(sorter)) {
    // If sorter is an array, map over each SorterResult object and extract order and field properties
    return sorter.map((sorterResult) => {
      return {
        order: sorterResult.order,
        field: sorterResult.field,
      };
    });
  } else {
    // If sorter is a single SorterResult object, extract order and field properties directly
    return [
      {
        order: sorter?.order,
        field: sorter?.field,
      },
    ];
  }
}
const useOrders = () => {
  const { toastError, toastPromise } = useAlertsContext();
  const dispatch = useAppDispatch();
  const { logout } = AuthActions();
  const [data, setData] = useState<OrdersDataType[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);
  const FetchOrders = async () => {
    setLoading(true);
    const { pagination, sorter } = tableParams;
    const sorterData = extractOrderAndField(sorter);
    // Initialize columns array and order array
    const columns: any = [];
    const orderArr: any = [];

    // Fill in columns array with name and orderable properties
    sorterData.forEach(({ field }, index) => {
      if (field) {
        columns.push({ name: field, orderable: true });
      }
    });

    // Fill in order array with column and dir properties
    sorterData.forEach(({ order }, index) => {
      if (order) {
        orderArr.push({ column: index, dir: order || undefined });
      }
    });
    const filters = {
      start: pagination?.current
        ? (pagination?.current - 1) * (pagination?.pageSize || 10)
        : 0,
      length: pagination?.pageSize || 10,
      customSearch: {
        searchTerm,
        statuses: tableParams?.filters?.status
          ? Object.values(tableParams?.filters?.status)
          : null,
        ...dateRange,
      },
      columns,
      order: orderArr,
    };
    await GetOrders(filters)
      .then((response) => {
        setData(response.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.recordsTotal,
          },
        });
      })
      .catch((e) => {
        if (e?.status && e?.status === 401) {
          toastError(e?.data || "Token Expired");
          setTimeout(() => {
            dispatch(logout());
          }, 1500);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    FetchOrders();
  }, [JSON.stringify(tableParams), searchTerm, dateRange]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    // console.log("handleTableChange", {
    //   pagination,
    //   filters,
    //   sorter,
    // });
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  // Fetch orders data from the API

  const exportExcel = async (ids: string[] | React.Key[]) => {
    setLoading(true);
    // ajax request after empty completing
    const promise = DownloadExcel(ids)
      .then((response) => {
        let fileName = "";
        const disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
          }
        }
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
            // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setSelectedRowKeys([]);
      })
      .catch((e) => {
        toastError(e?.data);
      })
      .finally(() => setLoading(false));
    toastPromise(
      promise,
      `Files exported successfully.`,
      `Error occurred during exported files.`
    );
  };
  //   Handle action to download orders
  const handleDownloadOrders = async () => {
    exportExcel(selectedRowKeys);
  };
  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };
  return {
    data,
    loading,
    hasSelected,
    tableParams,
    selectedRowKeys,
    rowSelection,
    searchTerm,
    dateRange,
    setDateRange,
    handleSearch,
    handleTableChange,
    handleDownloadOrders,
    exportExcel,
  };
};

export default useOrders;

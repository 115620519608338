export const API_ENDPOINTS = {
  LOGIN: "/User/Login",
  REGISTER: "/register",
  LOGOUT: "/logout",
  FORGET_PASSWORD: "/forget-password",
  GET_CATALOG: "/api/catalogs.json",
  GET_PROFILE: "/User/GetProfile",
  EDIT_ORDER: "/Orders/CreateOrderProduct",
  CREATE_ORDER_PDF: "/Orders/CreateOrderPDF",
  GET_ORDER_VIEW: "/Orders/GetOrderPDFData",
  CREATE_ORDER: "/Orders/Create",
  VALIDATE_ORDER: "/Orders/ValidateCustomerOrder",
  GET_ORDERS: "/Orders/GetAllDT",
  GET_ORDER_BY_ID: "/Orders/GetById",
  REFRESH_TOKEN: "/User/RefreshToken",
  GET_SHIPMENTS: "/Orders/GetShipmentsSelectionList",
  GET_TRUCKS: "/Orders/GetTrucksSelectionList",
  GET_AVAILS: "/Products/GetAllAvailsDT",
  GET_PRODUCTS: "/Products/GetAllDT",
  GET_PRODUCT_BY_ID: "/Products/GetById",
  GET_PRODUCTS_SELECTION: "/Products/GetProductSelectionList",
  GET_PACKINGS: "/Products/GetPackingSelectionList",
  IS_PRODUCT_AVAILABLE: "/Products/IsProductAvailable",
  GET_DESTINATIONS: "/User/GetDestinationsSelectionList",
  GET_MARKS: "/User/GetMarksSelectionList",
  GET_PRICE_LIST: "/User/GetPriceListExcel",
  CONTACT_US: "/User/ContactUs",
  ORDER_EXPORT_EXCEL: "/Orders/ExportOrderExcel",
  AVAILS_EXPORT_EXCEL: "/Products/ExportAvailsExcel",
};
